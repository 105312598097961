import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['target']
  static values = {
    class: String
  }

  toggleClass() {
    this.targetTarget.classList.toggle(this.classValue)
  }
}
