import RemoveButton from './remove-button'
import { getTargetHookSelector } from '../../common/utils/utils'
import { toArray } from 'lodash'

const ROW_CLS = 'duplicate-row'

export default function Row({ target, onInit, onRemove }) {
  const removeButton = RemoveButton(target.querySelector(getTargetHookSelector('remove')))
  const index = onInit(removeButton)

  target.classList.add(ROW_CLS)
  target.dataset.index = index
  removeButton.onRemove(_handleRemove)

  function _storeForDestroy() {
    toArray(target.querySelectorAll('[type="hidden"]')).forEach(hidden => {
      if (/\[_destroy]$/.test(hidden.name)) {
        hidden.value = 1
        target.parentNode.appendChild(hidden)
      }
      if (/\[id]$/.test(hidden.name)) {
        target.parentNode.appendChild(hidden)
      }
    })
  }

  function _handleRemove() {
    _storeForDestroy()
    target.parentNode.removeChild(target)
    onRemove(index)
  }
}
