import { Controller } from '@hotwired/stimulus'
import type { SelectChangeDetail, Detail } from '../../../../components/select/types'
import { includes } from 'lodash'
import { onSelectChange } from '../../../../components/select/select-utils'

export default class extends Controller {
  static targets = ['select']

  declare readonly selectTarget: HTMLSelectElement

  static values = {
    whitelist: Array,
    key: String,
    current: String
  }

  declare whitelistValue: string[]
  declare keyValue: string
  declare currentValue: string

  offSelectChange = (): void => {}

  connect(): void {
    this.offSelectChange = onSelectChange({
      scope: 0,
      keys: ['ground_handler_slug'],
      handle: this.handleSelectChange.bind(this)
    })
  }

  disconnect(): void {
    this.offSelectChange()
  }

  handleSelectChange({ detail: { params, keepSelection } }: Detail<SelectChangeDetail>): void {
    this.currentValue = params !== undefined ? params[this.keyValue] : ''

    if (includes(this.whitelistValue, this.currentValue)) {
      if (!keepSelection) {
        this.selectTarget.value = ''
      }
      this.element.classList.remove('hidden')
    } else {
      this.element.classList.add('hidden')
    }
  }
}
