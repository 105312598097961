export default function Next(wait) {
  let timeoutId

  function run(handle) {
    clear()
    timeoutId = setTimeout(handle, wait)
  }

  function clear() {
    clearTimeout(timeoutId)
  }

  return {
    run,
    clear
  }
}
