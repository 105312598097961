import { Controller } from '@hotwired/stimulus'
import type { CheckedDetail, Detail } from '../types'
import { emitEvent } from '../../../frontend/src/common/dispatch-event/dispatch-event'
import toggleClass from '../../../frontend/src/common/toggle-class/toggle-class'

const CSS_PREFIX = 'select__item-checkbox'

export default class extends Controller {
  static values = {
    checked: Boolean
  }

  declare checkedValue: boolean

  checkedValueChanged(checked: boolean): void {
    toggleClass({
      element: this.element,
      className: `${CSS_PREFIX}--checked`,
      predicate: checked
    })
  }

  handleChange(): void {
    this.checkedValue = !this.checkedValue
    emitEvent<CheckedDetail>(this.element, 'preToggleAll', { checked: this.checkedValue })
  }

  handleExternalChange({ detail: { checked } }: Detail<CheckedDetail>): void {
    this.checkedValue = checked
  }
}
