// TODO pending refactor
import ShipmentCourierCost from '../shipment-courier-cost/shipment-courier-cost'
import ParcelCounter from '../parcel-counter/parcel-counter'
import { getTargetHookSelector } from '../../../common/utils/hooks'
import { toArray, debounce } from 'lodash'
import dayjs from 'dayjs'
import { EVENTS, dispatchEvent } from '../shared/shared'

const EHUB_SEARCH_ID = 'ehub_search'
const SHORTCODE_SEARCH_ID = 'shortcode_search'
const PARCELS_TABLE_SELECTOR = '#available_parcel_list tbody'
const CHECKBOXES_SELECTOR = `${PARCELS_TABLE_SELECTOR} input`
const PARCELS_SELECTOR = `${PARCELS_TABLE_SELECTOR} tr`
const TRANSPORTS_SELECTOR = '.transports-box'
const CLASSES = {
  result: 'parcel-search__result',
  group: 'parcel-search__result-group',
  link: 'parcel-search__result-link',
  title: 'parcel-search__result-title',
  info: 'parcel-search__result-info'
}

const DATE_FORMAT = 'DD MMM YYYY, HH:mm'
const DATE_VALUE_FORMAT = 'YYYY-MM-DD HH:mm:ss ZZ'
let _parcelCount = {}

class LineHaulParcelSearch {
  constructor(elementIds, event = 'keyup', refreshParcels = false) {
    const delayedTriggerSearch = debounce(this._triggerSearch.bind(this), 200)
    this._searchboxes = Array(elementIds)
      .flat()
      .map(elementId => document.getElementById(elementId))
      .filter(searchbox => searchbox !== null)
    this._searchboxes.forEach(searchbox => {
      searchbox.addEventListener(event, delayedTriggerSearch)
    })
    this._registerShipmentFields()
    this._preselectParcelsSearch({ initialLoad: true })

    if (refreshParcels) {
      document.addEventListener(
        EVENTS.refreshParcels,
        e => {
          const { parcels, shortcode } = e.detail.result
          this._optionClick(parcels, { shortcode })()
        },
        false
      )
    }

    return this
  }

  _optionClick(parcels, { processingEhubKey, carrierRef, firstArrivedAt, toParam, shortcode }) {
    return () => {
      parcels.forEach(function (parcel) {
        const list = document.querySelector('#available_parcel_list tbody')
        const tr = document.createElement('tr')
        const waybill = parcel.waybill || {}
        const formattedArrived = dayjs(firstArrivedAt || waybill.first_arrived_at).format(
          DATE_FORMAT
        )
        const formattedArrivedValue = dayjs(firstArrivedAt || waybill.first_arrived_at).format(
          DATE_VALUE_FORMAT
        )
        const formattedMostRecentScan = dayjs(parcel.most_recent_ehub_scan_at).format(DATE_FORMAT)
        const formattedMostRecentScanValue = dayjs(parcel.most_recent_ehub_scan_at).format(
          DATE_VALUE_FORMAT
        )
        const processingEhubValue = processingEhubKey || waybill.processing_ehub_key
        const mostRecentDelivery = parcel.most_recent_delivery
        const shortcodeValue = shortcode || mostRecentDelivery.shortcode
        const existingRow = document.getElementById(
          `shipment_delivery_ids_${mostRecentDelivery.id}`
        )
        const waybillLink =
          toParam || waybill.to_param
            ? `<a target="_blank" href="/waybills/${toParam || waybill.to_param}">${
                carrierRef || waybill.carrier_ref
              }</a>`
            : ''
        const senderInstructions =
          mostRecentDelivery.sender_instructions || parcel.client.sender_instructions

        tr.style.background = '#c2f5c2'
        tr.dataset.fromSearch = true
        tr.dataset.clientId = parcel.client.id
        tr.dataset.senderInstructions = senderInstructions
        tr.dataset.processingEhub = processingEhubValue
        tr.dataset.shortcode = shortcodeValue

        tr.innerHTML = `
          <td>
            <input type="checkbox" value="${mostRecentDelivery.id}" checked="checked" name="shipment[delivery_ids][]" id="shipment_delivery_ids_${mostRecentDelivery.id}">
          </td>
          <td>${parcel.ref}</td>
          <td>${parcel.type}</td>
          <td>${waybillLink}</td>
          <td data-value="${formattedArrivedValue}">${formattedArrived}</td>
          <td>${parcel.client_ref}</td>
          <td>${processingEhubValue}</td>
          <td>${shortcodeValue}</td>
          <td data-value="${formattedMostRecentScanValue}">${formattedMostRecentScan}</td>
        `
        if (!existingRow) {
          list.prepend(tr)
        } else {
          existingRow.checked = true
        }
      })
      this._resetResultDiv()
      _parcelCount.update()
      ShipmentCourierCost.reloadData()

      document.getElementById('parcel_search').value = ''
    }
  }

  _buildWaybillGroup(waybill) {
    const group = document.createElement('div')
    const link = document.createElement('a')
    group.classList.add(CLASSES.group)
    link.classList.add(CLASSES.link)

    link.innerHTML = `<span class="${CLASSES.title}">${waybill.carrier_ref}</span><span class="${CLASSES.info}">Add all Parcels: ${waybill.regular_and_bulk_parcels.length}</span>`

    link.onclick = this._optionClick(waybill.regular_and_bulk_parcels, {
      processingEhubKey: waybill.processing_ehub_key,
      carrierRef: waybill.carrier_ref,
      firstArrivedAt: waybill.first_arrived_at,
      toParam: waybill.to_param
    })

    group.textContent = 'Waybill'
    return { group, links: [link] }
  }

  _buildShortcodeEhubGroup(result = {}) {
    const group = document.createElement('div')
    const link = document.createElement('a')
    const parcels = result.parcels
    group.classList.add(CLASSES.group)
    link.classList.add(CLASSES.link)

    link.innerHTML = `<span class="${CLASSES.title}">Shortcode: ${result.shortcode} / EHub: ${
      result.processing_ehub || 'ANY'
    }</span><span class="${CLASSES.info}">Add all Parcels: ${parcels.length}</span>`

    link.onclick = this._optionClick(parcels, {
      shortcode: result.shortcode
    })

    group.textContent = 'Shortcode and EHub'
    return { group, links: [link] }
  }

  _buildParcelsGroup(parcels) {
    const group = document.createElement('div')
    const links = []
    group.classList.add(CLASSES.group)
    group.textContent = 'Parcels'

    parcels.forEach(parcel => {
      const link = document.createElement('a')
      link.classList.add(CLASSES.link)
      link.innerHTML = `<span class="${CLASSES.title}">${parcel.ref}</span><span class="${CLASSES.info}"></span>`
      link.onclick = this._optionClick(parcels, parcel)
      links.push(link)
    })

    return { group, links }
  }

  _appendToResult(resultDiv, { group, links }) {
    resultDiv.append(group)
    links.forEach(link => {
      resultDiv.append(link)
    })
  }

  _buildResultList(response) {
    const jsonResponse = JSON.parse(response)
    const waybill = jsonResponse.waybill
    const shortcodeEhub = jsonResponse.shortcode_ehub
    const parcels = jsonResponse.parcels || []
    const resultDiv = document.querySelector(`.${CLASSES.result}`)

    if (waybill) {
      this._appendToResult(resultDiv, this._buildWaybillGroup(waybill))
    }
    if (shortcodeEhub) {
      this._appendToResult(resultDiv, this._buildShortcodeEhubGroup(shortcodeEhub))
    }
    if (parcels.length > 0) {
      this._appendToResult(resultDiv, this._buildParcelsGroup(parcels))
    }

    if (waybill === null && parcels.length === 0 && shortcodeEhub === null) {
      return
    }

    resultDiv.style.display = 'block'
  }

  _resetResultDiv() {
    const resultDiv = document.querySelector(`.${CLASSES.result}`)
    resultDiv.style.display = 'none'
    resultDiv.innerHTML = ''
  }

  _sendRequest() {
    const xhr = new XMLHttpRequest()
    this._resetResultDiv()

    xhr.onload = () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        this._buildResultList(xhr.response)
      } else {
        alert('Something went wrong; please try again')
      }
    }

    const url = new URL(this._searchboxes[0].dataset.url)

    this._searchboxes.forEach(searchbox =>
      url.searchParams.set(searchbox.dataset.queryKey, searchbox.value)
    )

    const serviceValue = document.querySelector(
      getTargetHookSelector('shipment-deliveries-service') || {}
    ).value
    url.searchParams.set('service', serviceValue)

    xhr.open('GET', url)
    xhr.send()
  }

  _triggerSearch(event) {
    if (event.key === 'Escape') {
      this._resetResultDiv()
      return
    }

    const currentValue = event.target.value

    if (event.ctrlKey || event.altKey || event.shiftKey) {
      return
    }

    if (event.keyCode === 8 && currentValue.length === 0) {
      this._resetResultDiv()
      return
    }

    if (currentValue.length >= 3) {
      this._resetResultDiv()
      this._sendRequest()
    }
  }

  _noSelectParcelsFilters() {
    return (
      this._searchboxes.filter(
        select => select.id === EHUB_SEARCH_ID || select.id === SHORTCODE_SEARCH_ID
      ).length < 2
    )
  }

  _registerShipmentFields() {
    if (this._noSelectParcelsFilters()) {
      return
    }

    this._shipmentEHubSelect = document.querySelector('[data-target=shipmentEhub]')
    this._shipmentShortCodeSelect = document.querySelector('[data-target=shipmentShortcode]')
    this._shipmentEHubSelect.addEventListener(
      'change',
      this._preselectParcelsSearch.bind(this),
      false
    )
    this._shipmentShortCodeSelect.addEventListener(
      'change',
      this._preselectParcelsSearch.bind(this),
      false
    )
  }

  _preselectParcelsSearch({ initialLoad = false }) {
    const parcelsList = document.querySelectorAll(PARCELS_SELECTOR)
    const parcelsListPreloaded = initialLoad && parcelsList.length

    if (
      parcelsListPreloaded ||
      this._noSelectParcelsFilters() ||
      !this._shipmentEHubSelect ||
      !this._shipmentShortCodeSelect
    ) {
      return
    }

    const eHubSearchSelect = this._searchboxes.filter(select => select.id === EHUB_SEARCH_ID)[0]
    const shortCodeSearchSelect = this._searchboxes.filter(
      select => select.id === SHORTCODE_SEARCH_ID
    )[0]
    eHubSearchSelect.value = this._shipmentEHubSelect.value
    shortCodeSearchSelect.value = this._shipmentShortCodeSelect.value

    if (!shortCodeSearchSelect.value) {
      shortCodeSearchSelect.value = ''
    }

    this._unselect({
      parcelsList,
      processingEhub: eHubSearchSelect.value,
      shortcode: shortCodeSearchSelect.value
    })
    this._triggerSearch({
      target: shortCodeSearchSelect.value ? shortCodeSearchSelect : eHubSearchSelect
    })
  }

  _unselect({ parcelsList, processingEhub, shortcode }) {
    parcelsList.forEach(tr => {
      const rowProcessingEhub = tr.dataset.processingEhub
      const rowShortCode = tr.dataset.shortcode
      if (processingEhub !== rowProcessingEhub || shortcode !== rowShortCode) {
        tr.querySelector('td input[type=checkbox]').checked = false
      }
    })
  }
}

function toggleSelected(e) {
  toArray(document.querySelectorAll(CHECKBOXES_SELECTOR)).forEach(checkbox => {
    checkbox.checked = e.target.checked
  })

  _parcelCount.update()
  dispatchEvent(EVENTS.selectTopClear)
}

function init() {
  const parcelList = document.querySelector('#available_parcel_list tbody')
  const checkAll = document.getElementById('check_all')

  if (!parcelList) {
    return
  }

  checkAll.addEventListener('change', toggleSelected, false)
  document.addEventListener(
    'change',
    function (event) {
      if (event.target.matches(CHECKBOXES_SELECTOR)) {
        _parcelCount.update()
        dispatchEvent(EVENTS.selectTopClear)
        checkAll.checked = false
      }
    },
    false
  )

  /* eslint-disable no-new */
  new LineHaulParcelSearch('parcel_search')
  new LineHaulParcelSearch('waybill_search')
  new LineHaulParcelSearch([SHORTCODE_SEARCH_ID, EHUB_SEARCH_ID], 'change', true)
  /* eslint-enable no-new */

  _parcelCount = ParcelCounter({
    targets: {
      transports: document.querySelector(TRANSPORTS_SELECTOR),
      totalCount: document.querySelector('h4.total_parcels .count'),
      selectedCount: document.querySelector('h4.selected_parcels .count')
    }
  })

  document.addEventListener(
    EVENTS.selectTop,
    () => {
      _parcelCount.update()
      checkAll.checked = false
    },
    false
  )

  ShipmentCourierCost.init()
}

let timeoutId
document.addEventListener('turbo:load', function () {
  clearTimeout(timeoutId)
  timeoutId = setTimeout(() => {
    init()
  }, 500)
})
