import { on, off, replaceValueAndKeepCursor, Next } from '../utils/utils'
import InputFilter from '../input-filter/input-filter'
import { UP, DOWN } from './input-case.constants'

function _inputHandle({ target, mode, next }) {
  const value =
    mode === UP
      ? target.value.toUpperCase()
      : mode === DOWN
        ? target.value.toLowerCase()
        : target.value

  return next(() => {
    replaceValueAndKeepCursor(target, value)
  })
}

export default function InputCase({ targets, props }) {
  const { base } = targets
  const { mode, match } = props
  const next = Next()
  let filterInstance = null

  function applyInputHandle() {
    _inputHandle({ target: base, mode, next: next.run })
  }

  function init() {
    on(base, 'input', applyInputHandle)
    if (match) {
      filterInstance = InputFilter({ targets: { base }, props: { match } })
      filterInstance.init()
    }
  }

  function destroy() {
    next.clear()
    off(base, 'input', applyInputHandle)
    if (filterInstance) {
      filterInstance.destroy()
    }
  }

  return {
    init,
    destroy
  }
}
