interface ToggleClassArgs {
  element: Element
  className: string
  predicate: boolean
}

export default function toggleClass({ element, className, predicate }: ToggleClassArgs): void {
  if (predicate) {
    element.classList.add(className)
  } else {
    element.classList.remove(className)
  }
}
