import { Controller } from '@hotwired/stimulus'

type Target = 'eori' | 'vat'

export default class extends Controller {
  static targets = [
    'addressName1',
    'addressName2',
    'addressStreet',
    'addressZip',
    'addressCity',
    'addressCountry',

    'eoriAddressName1',
    'eoriAddressName2',
    'eoriAddressStreet',
    'eoriAddressZip',
    'eoriAddressCity',
    'eoriAddressCountry',

    'vatAddressName1',
    'vatAddressName2',
    'vatAddressStreet',
    'vatAddressZip',
    'vatAddressCity',
    'vatAddressCountry'
  ]

  declare readonly addressName1Target: HTMLInputElement
  declare readonly addressName2Target: HTMLInputElement
  declare readonly addressStreetTarget: HTMLInputElement
  declare readonly addressZipTarget: HTMLInputElement
  declare readonly addressCityTarget: HTMLInputElement
  declare readonly addressCountryTarget: HTMLInputElement

  declare readonly eoriAddressName1Target: HTMLInputElement
  declare readonly eoriAddressName2Target: HTMLInputElement
  declare readonly eoriAddressStreetTarget: HTMLInputElement
  declare readonly eoriAddressZipTarget: HTMLInputElement
  declare readonly eoriAddressCityTarget: HTMLInputElement
  declare readonly eoriAddressCountryTarget: HTMLInputElement

  declare readonly vatAddressName1Target: HTMLInputElement
  declare readonly vatAddressName2Target: HTMLInputElement
  declare readonly vatAddressStreetTarget: HTMLInputElement
  declare readonly vatAddressZipTarget: HTMLInputElement
  declare readonly vatAddressCityTarget: HTMLInputElement
  declare readonly vatAddressCountryTarget: HTMLInputElement

  handleCopy({ params }: { params: { to: Target } }): void {
    if (params.to === 'eori') {
      this.eoriAddressName1Target.value = this.addressName1Target.value
      this.eoriAddressName2Target.value = this.addressName2Target.value
      this.eoriAddressStreetTarget.value = this.addressStreetTarget.value
      this.eoriAddressZipTarget.value = this.addressZipTarget.value
      this.eoriAddressCityTarget.value = this.addressCityTarget.value
      this.eoriAddressCountryTarget.value = this.addressCountryTarget.value
    } else if (params.to === 'vat') {
      this.vatAddressName1Target.value = this.addressName1Target.value
      this.vatAddressName2Target.value = this.addressName2Target.value
      this.vatAddressStreetTarget.value = this.addressStreetTarget.value
      this.vatAddressZipTarget.value = this.addressZipTarget.value
      this.vatAddressCityTarget.value = this.addressCityTarget.value
      this.vatAddressCountryTarget.value = this.addressCountryTarget.value
    }
  }
}
