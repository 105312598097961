import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['root', 'filterContent']
  static values = {
    cookiePrefix: String
  }

  localStorageItemName() {
    return `${this.cookiePrefixValue}_filters_pinned`
  }

  connect() {
    if (localStorage.getItem(this.localStorageItemName()) === 'true') {
      this.rootTarget.classList.add('filtersExpanded')
      this.filterContentTarget.classList.add('pinned')
    }
  }

  toggleFilterContent() {
    this.rootTarget.classList.toggle('filtersExpanded')
  }

  togglePin() {
    if (this.filterContentTarget.classList.contains('pinned')) {
      localStorage.removeItem(this.localStorageItemName())
    } else {
      localStorage.setItem(this.localStorageItemName(), 'true')
    }

    this.filterContentTarget.classList.toggle('pinned')
  }
}
