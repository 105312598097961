import { Controller } from '@hotwired/stimulus'
import type { SelectChangeDetail, Detail } from '../../../../components/select/types'
import { onSelectChange } from '../../../../components/select/select-utils'

export default class extends Controller {
  declare readonly selectTarget: HTMLSelectElement

  static values = {
    dependsOn: { type: Array, default: [] },
    src: String,
    params: { type: Object, default: {} },
    scope: { type: Number, default: 0 }
  }

  declare dependsOnValue: string[]
  declare srcValue: string
  declare paramsValue: Record<string, string>
  declare scopeValue: number

  offSelectChange = (): void => {}

  connect(): void {
    this.offSelectChange = onSelectChange({
      scope: this.scopeValue,
      keys: this.dependsOnValue,
      handle: this.handleSelectChange.bind(this)
    })
  }

  disconnect(): void {
    this.offSelectChange()
  }

  handleSelectChange({ detail: { params } }: Detail<SelectChangeDetail>): void {
    this.paramsValue = Object.assign({}, this.paramsValue, params)
    const searchParams = new URLSearchParams(this.paramsValue)
    const url = `${this.srcValue}?${searchParams.toString()}`
    this.element.setAttribute('src', url)
  }
}
