import { Controller } from '@hotwired/stimulus'
import dayjs from 'dayjs'
import { broadcastEvent } from '../../common/dispatch-event/dispatch-event'

const FORMAT = 'YYYY-MM-DD'

export default class extends Controller {
  static targets = ['week', 'startDate', 'endDate']

  declare readonly weekTarget: HTMLSelectElement
  declare readonly startDateTarget: HTMLInputElement
  declare readonly endDateTarget: HTMLInputElement

  handleChange({ target }: { target: HTMLInputElement }): void {
    const startDate = dayjs(target.value).format(FORMAT)
    const endDate = dayjs(startDate).add(7, 'day').format(FORMAT)

    broadcastEvent<{ value: string }>(this.startDateTarget, 'update', { value: startDate })
    broadcastEvent<{ value: string }>(this.endDateTarget, 'update', { value: endDate })
  }

  handleDateChange(): void {
    this.weekTarget.selectedIndex = 0
  }
}
