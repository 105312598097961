import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    this.selectTarget = this.element.querySelector('select')
    this.selectTarget.addEventListener('change', this.change.bind(this))
    this.selectTarget.addEventListener('onFocus', this.storeValue.bind(this))
    this.selectedValue = this.selectTarget.value
  }

  disconnect() {
    this.selectTarget.removeEventListener('change', this.change.bind(this))
    this.selectTarget.removeEventListener('onFocus', this.storeValue.bind(this))
  }

  change() {
    if (this.needsConfirmation()) {
      if (
        !confirm(
          'This loading dock contains parcels for a different handover party. Are you sure you want to move the current parcels to this loading dock?'
        )
      ) {
        this.selectTarget.value = this.selectedValue
        return
      }
    }
    this.selectedValue = this.selectTarget.value
  }

  storeValue() {
    this.selectedValue = this.selectTarget.value
  }

  needsConfirmation() {
    const selectedOption = this.selectTarget.options.selectedIndex
    return this.selectTarget.options[selectedOption].dataset.confirm === 'true'
  }
}
