export const KEY_ENTER = 13
export const KEY_ESC = 27
export const KEY_UP = 38
export const KEY_DOWN = 40
export const KEY_J = 74
export const KEY_F = 70
export const KEY_V = 86
export function getKey(e: KeyboardEvent): number {
  return e.keyCode ?? 0
}
