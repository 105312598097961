import { on, off } from '../../common/utils/utils'
import { toArray } from 'lodash'

export const HIDDEN_CLS = 'hidden'

function _getInputs(target) {
  return toArray(target.querySelectorAll('input'))
}

function _resetBox(target) {
  return _getInputs(target).forEach(input => {
    input.value = null
  })
}

function _hideBox(box) {
  box.classList.add(HIDDEN_CLS)
}

function _showBox(box) {
  box.classList.remove(HIDDEN_CLS)
  box.scrollIntoView({ behavior: 'smooth' })
}

export default function PlusMinus({ targets }) {
  const products = targets.products || []
  const addButton = targets.add
  const removeLastButton = targets.removeLast
  const removeItemButtons = toArray(document.querySelectorAll('.remove-item-button'))

  function hideBoxes() {
    const elementsToHide = products.filter((element, index) => {
      const allInputsEmpty = _getInputs(element).every(input => !input.value)
      return index !== 0 && allInputsEmpty
    })

    elementsToHide.forEach(element => _hideBox(element))

    updateButtonsDisabledState()
  }

  function addHandle(e) {
    const hiddenProductToShow = nextHiddenProductToShow()

    if (!hiddenProductToShow) {
      return
    }

    _showBox(hiddenProductToShow)
    updateButtonsDisabledState()
  }

  function removeLastHandle(e) {
    const visible = getVisibleProducts()
    const lastProduct = visible[visible.length - 1]
    removeHandle(lastProduct, visible)
  }

  function removeItemHandle(e) {
    const visible = getVisibleProducts()
    const productId = e.target.id.replace('remove-', '')
    const product = products.find(element => element.id === productId)
    removeHandle(product, visible)
  }

  function removeHandle(productToRemove, visible) {
    if (visible.length === 1) {
      return
    }

    _hideBox(productToRemove)
    _resetBox(productToRemove)

    updateButtonsDisabledState()
  }

  function updateButtonsDisabledState() {
    if (nextHiddenProductToShow()) {
      addButton.disabled = false
    } else {
      addButton.disabled = true
    }

    if (getVisibleProducts().length <= 1) {
      removeItemButtons.forEach(button => (button.disabled = true))
      removeLastButton.disabled = true
    } else {
      removeItemButtons.forEach(button => (button.disabled = false))
      removeLastButton.disabled = false
    }

    document.activeElement.blur()
  }

  function getVisibleProducts() {
    return products.filter(element => !element.classList.contains(HIDDEN_CLS))
  }

  function getHiddenProducts() {
    return products.filter(element => element.classList.contains(HIDDEN_CLS))
  }

  function nextHiddenProductToShow() {
    const hidden = getHiddenProducts()

    if (!hidden.length) {
      return
    }

    const visible = getVisibleProducts()
    let lastVisibleProductId = 0

    if (visible.length > 0) {
      lastVisibleProductId = getProductId(visible[visible.length - 1])
    }

    return hidden.find(hiddenProduct => getProductId(hiddenProduct) > lastVisibleProductId)
  }

  function getProductId(product) {
    const productId = product.id.split('-')[1]
    return parseInt(productId)
  }

  function init() {
    on(addButton, 'click', addHandle)
    on(removeLastButton, 'click', removeLastHandle)
    removeItemButtons.forEach(button => on(button, 'click', removeItemHandle))
    hideBoxes()
  }

  function destroy() {
    off(addButton, 'click', addHandle)
    off(removeLastButton, 'click', removeLastHandle)
    removeItemButtons.forEach(button => off(button, 'click', removeItemHandle))
  }

  return {
    init,
    destroy
  }
}
