import { delegate } from '../../common/utils/utils'
import Sorter, { ASC } from './sorter'
import { removeIcon, toggleIcon } from './sort-icon'
import {
  COLUMNS_SELECTOR,
  extractText,
  getHeader,
  getBody,
  getRawCollection,
  getNormalizedCollection
} from './table-utils'
import { find } from 'lodash'

const TABLE_CLS = 'table-sorter'
const COLUMNS_ACTIVE_CLS = `${TABLE_CLS}-active`

export default function TableSorter({ targets, props = {} }) {
  const { base } = targets
  const { defaultSort, numOfSorters } = props
  const _header = getHeader(base)
  const _sorter = Sorter({ numOfSorters: parseInt(numOfSorters, 10) })
  let _off = () => {}

  function _updateHeaders() {
    const params = _sorter.params()
    _header.forEach(({ column, key }) => {
      const param = find(params, p => p.key === key)
      column.className = ''

      if (param) {
        const dir = param.dir === ASC ? 'up' : 'down'
        column.classList.add(COLUMNS_ACTIVE_CLS)
        column.classList.add(`${COLUMNS_ACTIVE_CLS}-${param.idx}`)
        toggleIcon(column, dir)
      } else {
        removeIcon(column)
      }
    })
  }

  function _triggerSorting(key) {
    const shouldNormalize = _header.some(({ normalize }) => normalize)
    const newBody = document.createElement('tbody')
    const oldBody = getBody(base)
    const collection = shouldNormalize
      ? getNormalizedCollection(_header, oldBody)
      : getRawCollection(_header, oldBody)

    newBody.dataset.sortBody = 'true'
    _sorter.sort(
      key,
      _header.map(({ key }) => key),
      collection
    )
    collection.forEach(({ element }) => newBody.appendChild(element))
    base.replaceChild(newBody, oldBody)
    _updateHeaders()
  }

  function _clickHandle(e) {
    const key = extractText(e.target)
    if (key) {
      _triggerSorting(key)
    }
  }

  function init() {
    base.classList.add(TABLE_CLS)
    _off = delegate(base, COLUMNS_SELECTOR, 'click', _clickHandle)

    if (defaultSort) {
      _triggerSorting(defaultSort)
    }
  }

  function cancel() {
    _sorter.cancel()
    _header.forEach(({ column }) => {
      column.className = ''
      removeIcon(column)
    })
  }

  function destroy() {
    _off()
  }

  return {
    init,
    cancel,
    destroy
  }
}
