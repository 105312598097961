import { off, on } from '../../frontend/src/common/utils/utils'

export function createModal({
  className,
  url,
  handleClose
}: {
  className: string
  url: string
  handleClose: () => void
}): HTMLElement {
  const element = document.createElement('div')
  element.classList.add(className)
  element.style.backgroundImage = `url(${url})`
  on(element, 'click', handleClose)
  return element
}

export function destroyModal({
  element,
  handleClose
}: {
  element: HTMLElement
  handleClose: () => void
}): void {
  off(element, 'click', handleClose)
  element.remove()
}
