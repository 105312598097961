export default function Rows() {
  let instances = []
  let onRemoveCb = () => {}

  function _checkFirst() {
    const removeButton = instances[0].removeButton

    if (instances.length === 1) {
      removeButton.hide()
    } else {
      removeButton.show()
    }
  }

  function _nextIndex() {
    return Date.now() + (instances.length + 1) + ''
  }

  function push(removeButton) {
    const index = _nextIndex()
    instances.push({ removeButton, index })
    _checkFirst()
    return index
  }

  function remove(indexToRemove) {
    if (instances.length === 1) {
      return
    }

    instances = instances.filter(({ index }) => indexToRemove !== index)
    _checkFirst()
    onRemoveCb(indexToRemove)
  }

  function onRemove(cb) {
    onRemoveCb = cb
  }

  function destroy() {
    instances.forEach(({ removeButton }) => removeButton.destroy())
    instances = []
  }

  return {
    push,
    remove,
    onRemove,
    destroy
  }
}
