import isAnchor from './is-anchor'
import isSomethingSelected from './is-something-selected'
import isInput from './is-input'
import shouldSkip from './should-skip'
import { Turbo } from '@hotwired/turbo-rails'

export default function handleRedirect(e: MouseEvent, href: string): void {
  const target = e.target as HTMLElement
  const shouldOpenInNewTab = e.metaKey

  if (
    href === '' ||
    isAnchor(target) ||
    isSomethingSelected() ||
    isInput(target) ||
    shouldSkip(target)
  ) {
    return
  }

  if (shouldOpenInNewTab) {
    window.open(href, '_blank')
  } else {
    Turbo.visit(href)
  }
}
