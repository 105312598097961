import { Controller } from '@hotwired/stimulus'
import { exit } from '../../frontend/src/common/exit/exit'
import { parentBox } from '../../frontend/src/common/parent-box/parent-box'
import { get } from '@rails/request.js'
import { getSelectChangeEventNames, onSelectChange } from '../select/select-utils'
import { broadcastEvent } from '../../frontend/src/common/dispatch-event/dispatch-event'
import { Next } from '../../frontend/src/common/utils/utils'
import { Direction } from '../select/types'
import type {
  AnyObject,
  Detail,
  ToggleDetail,
  LoadingDetail,
  SelectChangeDetail,
  ItemParamsDetail,
  ItemConfirmParamsDetail,
  SearchDetail,
  DirectionDetail,
  FilterDetail,
  CloseDetail,
  OverflowUtils,
  ItemSelectedParamsDetail,
  CheckedDetail
} from '../select/types'
import type { ActionWithInitFactory, ActionFactory } from '../../frontend/src/types'
import toggleClass from '../../frontend/src/common/toggle-class/toggle-class'

const INCLUDE_BLANK_NULL = 'NULL'

export default class extends Controller {
  static targets = [
    'input',
    'inputSingle',
    'inputMulti',
    'inputSearch',
    'container',
    'list',
    'tagsRest',
    'toggleAll'
  ]

  declare readonly inputTarget: HTMLElement
  declare readonly inputSingleTarget: HTMLElement
  declare readonly inputMultiTarget: HTMLElement
  declare readonly inputSearchTarget: HTMLElement
  declare readonly hasInputSearchTarget: boolean
  declare readonly containerTarget: HTMLElement
  declare readonly listTarget: HTMLElement
  declare readonly tagsRestTarget: HTMLElement
  declare readonly hasTagsRestTarget: boolean
  declare readonly toggleAllTarget: HTMLElement

  static values = {
    scope: { type: Number, default: 0 },
    name: String,
    selected: { type: Array, default: [] },
    includeBlank: { type: String, default: INCLUDE_BLANK_NULL },
    multi: Boolean,
    disabled: Boolean,
    forceEnabledOnClone: Boolean,
    forceEnabled: Boolean,
    withSearch: Boolean,
    withSearchRemote: String,
    expanded: Boolean,
    url: String,
    dependsOn: { type: Array, default: [] },
    dependsOnExternal: String,
    changeKey: String,
    turboParams: { type: Object, default: {} },
    autoPreload: Boolean,
    keepSelection: Boolean,
    isCached: Boolean,
    formName: String,
    frameId: String
  }

  declare scopeValue: number
  declare nameValue: string
  declare selectedValue: string[]
  declare includeBlankValue: string
  declare multiValue: boolean
  declare disabledValue: boolean
  declare forceEnabledOnCloneValue: boolean // used by duplicate utility
  declare forceEnabledValue: boolean
  declare withSearchValue: boolean
  declare withSearchRemoteValue: string
  declare expandedValue: boolean
  declare urlValue: string
  declare dependsOnValue: string[]
  declare dependsOnExternalValue: string
  declare changeKeyValue: string
  declare autoPreloadValue: boolean
  declare keepSelectionValue: boolean
  declare turboParamsValue: AnyObject
  declare isCachedValue: boolean
  declare formNameValue: string // used by list rendered with turbo stream
  declare frameIdValue: string

  exitInstance: ActionWithInitFactory = { init: () => {}, destroy: () => {} }
  parentBoxInstance: ActionFactory = { destroy: () => {} }
  setOverflow = (): void => {}
  removeOverflow = (): void => {}
  loading: boolean = false
  next = Next()
  offSelectChange = (): void => {}

  connect(): void {
    this.exitInstance = exit(this.element, { manual: true, inner: null })
    this.parentBoxInstance = parentBox(this.element, { namespace: 'select' })
    this.preload()
    this.autoPreload()
    this.offSelectChange = onSelectChange({
      scope: this.scopeValue,
      keys: this.dependsOnValue.length > 0 ? this.dependsOnValue : [this.dependsOnExternalValue],
      handle: this.handleTurboUpdate.bind(this)
    })
  }

  disconnect(): void {
    this.exitInstance.destroy()
    this.parentBoxInstance.destroy()
    this.next.clear()
    this.offSelectChange()
  }

  disabledValueChanged(disabled: boolean): void {
    toggleClass({
      element: this.element,
      className: 'select--disabled',
      predicate: disabled
    })
  }

  preload(): void {
    if (
      !this.isCachedValue &&
      this.urlValue !== '' &&
      this.dependsOnValue.length === 0 &&
      this.withSearchRemoteValue === ''
    ) {
      this.next.run(() => {
        this.fetch({ params: {}, keepSelection: true })
      })
    }
  }

  autoPreload(): void {
    if (
      !this.isCachedValue &&
      this.autoPreloadValue &&
      this.selectedValue.length > 0 &&
      this.changeKeyValue.length > 0
    ) {
      this.next.run(() => {
        this.keepSelectionValue = true
        this.pingNext({ [this.nameValue]: this.selectedValue })
      })
    }
  }

  forceEnabledValueChanged(forceEnabled: boolean): void {
    if (forceEnabled && this.disabledValue) {
      this.disabledValue = false
      this.next.run(() => {
        broadcastEvent(this.inputTarget, 'enable', {})
        broadcastEvent(this.listTarget, 'enable', {})
        if (this.multiValue) {
          broadcastEvent<{ listSize: number }>(this.inputMultiTarget, 'enable', {
            listSize: this.selectedValue.length
          })
        } else {
          broadcastEvent(this.inputSingleTarget, 'enable', {})
        }
      })
    }
  }

  broadcastToggle(detail: ToggleDetail): void {
    broadcastEvent<ToggleDetail>(this.containerTarget, 'toggle', detail)
    broadcastEvent<ToggleDetail>(this.inputTarget, 'toggle', detail)
    if (this.hasInputSearchTarget) {
      broadcastEvent<ToggleDetail>(this.inputSearchTarget, 'toggle', detail)
    }
    if (this.hasTagsRestTarget) {
      broadcastEvent<ToggleDetail>(this.tagsRestTarget, 'toggle', detail)
    }
  }

  handleBeforeCache(): void {
    this.isCachedValue = true
  }

  handleToggle(): void {
    if (this.expandedValue) {
      this.collapse()
    } else {
      this.expand()
    }

    this.broadcastToggle({ expanded: this.expandedValue })
  }

  handleParentBoxInit({ detail: { setOverflow, removeOverflow } }: Detail<OverflowUtils>): void {
    this.setOverflow = setOverflow
    this.removeOverflow = removeOverflow
    if (this.multiValue && this.hasTagsRestTarget) {
      broadcastEvent<OverflowUtils>(this.tagsRestTarget, 'overflowInit', {
        setOverflow,
        removeOverflow
      })
    }
  }

  pingNext(params: AnyObject): void {
    if (this.changeKeyValue.length === 0) {
      return
    }
    const eventNames = getSelectChangeEventNames({
      scope: this.scopeValue,
      keys: [this.changeKeyValue]
    })

    eventNames.forEach(eventName => {
      broadcastEvent<SelectChangeDetail>(window, eventName, {
        params,
        keepSelection: this.keepSelectionValue
      })
    })
    this.keepSelectionValue = false
  }

  arrowPress(dir: Direction): void {
    if (this.disabledValue) {
      return
    }
    if (!this.expandedValue) {
      this.handleToggle()
      return
    }

    broadcastEvent<DirectionDetail>(this.listTarget, 'focus', { dir })
  }

  handleUp(): void {
    this.arrowPress(Direction.up)
  }

  handleDown(): void {
    this.arrowPress(Direction.down)
  }

  handlePreConfirmSelect(): void {
    if (!this.expandedValue && this.disabledValue) {
      return
    }

    broadcastEvent(this.listTarget, 'confirmSelect', {})
  }

  handleQuickFilter({ detail: { q } }: Detail<FilterDetail>): void {
    if (this.expandedValue || this.disabledValue) {
      return
    }

    const regex = new RegExp(`^${q}`, 'i')
    broadcastEvent<SearchDetail>(this.listTarget, 'confirmSelect', { regex })
  }

  handleConfirmSelect({
    detail: { params, noToggle = false, checked, allChecked = false }
  }: Detail<ItemConfirmParamsDetail>): void {
    if (this.multiValue) {
      broadcastEvent<ItemConfirmParamsDetail>(this.inputMultiTarget, 'itemSelect', {
        params,
        checked
      })
      broadcastEvent<CheckedDetail>(this.toggleAllTarget, 'selectChange', { checked: allChecked })
    } else {
      broadcastEvent<ItemParamsDetail>(this.inputSingleTarget, 'itemSelect', { params })
    }

    if (!noToggle) {
      this.handleToggle()
    }
  }

  handleExternalUnselect({ detail: { params } }: Detail<ItemParamsDetail>): void {
    broadcastEvent<ItemParamsDetail>(this.listTarget, 'unselect', { params })
    broadcastEvent<CheckedDetail>(this.toggleAllTarget, 'selectChange', { checked: false })
  }

  handleToggleAllUpdate({ detail: { checked } }: Detail<CheckedDetail>): void {
    if (this.multiValue) {
      broadcastEvent<CheckedDetail>(this.toggleAllTarget, 'selectChange', { checked })
    }
  }

  handlePreToggleAll({ detail: { checked } }: Detail<CheckedDetail>): void {
    broadcastEvent<CheckedDetail>(this.listTarget, 'toggleAll', { checked })
  }

  handleConfirmToggleAll({ detail: { selected } }: Detail<ItemSelectedParamsDetail>): void {
    broadcastEvent<ItemSelectedParamsDetail>(this.inputMultiTarget, 'toggleMulti', { selected })
  }

  handleSearch({ detail: { q } }: Detail<FilterDetail>): void {
    if (this.withSearchRemoteValue !== '') {
      this.fetch({ params: { [this.withSearchRemoteValue]: q }, keepSelection: false })
    } else {
      const regex = q.length > 0 ? new RegExp(q, 'i') : null
      broadcastEvent<SearchDetail>(this.listTarget, 'search', { regex })
    }
  }

  handleClose({ detail: { scope, name } }: Detail<CloseDetail>): void {
    if (scope === this.scopeValue && name === this.nameValue) {
      return
    }
    this.collapse()
    this.broadcastToggle({ expanded: false })
  }

  handleTurboUpdate({
    detail: { params, keepSelection, selected }
  }: Detail<SelectChangeDetail>): void {
    this.turboParamsValue = {
      ...this.turboParamsValue,
      ...params
    }

    this.fetch({ params: this.turboParamsValue, keepSelection, selected })
  }

  fetch({ params = {}, keepSelection = false, selected }: SelectChangeDetail): void {
    if (this.loading) {
      return
    }

    broadcastEvent<LoadingDetail>(this.inputTarget, 'loading', { loading: true })

    this.keepSelectionValue = keepSelection

    const query: AnyObject = {
      ...params,
      'select_component[name]': this.formNameValue,
      'select_component[frame_id]': this.frameIdValue,
      'select_component[scope]': this.scopeValue
    }

    if (this.multiValue) {
      query['select_component[multi]'] = this.multiValue
    }

    if (this.includeBlankValue !== INCLUDE_BLANK_NULL) {
      query['select_component[include_blank]'] = this.includeBlankValue
    }

    if (this.keepSelectionValue) {
      query['select_component[selected]'] =
        this.selectedValue.length > 0 ? this.selectedValue : selected
    }

    return get(this.urlValue, {
      query,
      responseKind: 'turbo-stream'
    }).finally(() => {
      this.loading = false
      broadcastEvent<LoadingDetail>(this.inputTarget, 'loading', { loading: false })
    })
  }

  handleListUpdate({ detail: { selected } }: Detail<ItemSelectedParamsDetail>): void {
    if (this.multiValue) {
      broadcastEvent<ItemSelectedParamsDetail>(this.inputMultiTarget, 'toggleMulti', { selected })
    } else {
      broadcastEvent<ItemParamsDetail>(this.inputSingleTarget, 'itemSelect', {
        params: selected[0]
      })
    }

    this.pingNext({
      ...this.turboParamsValue,
      [this.nameValue]: selected.map(v => v.value)
    })
  }

  handleFormName({ detail: { formName } }: Detail<{ formName: string }>): void {
    this.formNameValue = formName
  }

  expand(): void {
    this.expandedValue = true
    this.exitInstance.init()
    this.setOverflow()
    broadcastEvent<CloseDetail>(window, 'close', { scope: this.scopeValue, name: this.nameValue })
  }

  collapse(): void {
    this.expandedValue = false
    this.exitInstance.destroy()
    this.removeOverflow()
  }
}
