const HOOK_NAME = 'component'
const TARGET_HOOK_NAME = 'target'

export function getHookSelector(id) {
  return `[data-${HOOK_NAME}="${id}"]`
}

export function getTargetHookSelector(id) {
  return `[data-${TARGET_HOOK_NAME}="${id}"]`
}
