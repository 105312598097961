import { Controller } from '@hotwired/stimulus'
import toggleClass from '../../frontend/src/common/toggle-class/toggle-class'

const CSS_PREFIX = 'direct-upload'

export default class extends Controller {
  static targets = ['progress', 'filename', 'percent']

  declare readonly progressTarget: HTMLElement
  declare readonly filenameTarget: HTMLElement
  declare readonly percentTarget: HTMLElement

  static values = {
    show: { type: Boolean, default: false },
    progress: { type: Number, default: 0 },
    filename: String,
    showError: Boolean
  }

  declare showValue: boolean
  declare progressValue: number
  declare filenameValue: string
  declare showErrorValue: boolean

  showValueChanged(showValue: boolean): void {
    toggleClass({
      element: this.element,
      className: `${CSS_PREFIX}--show`,
      predicate: showValue
    })
  }

  progressValueChanged(progressValue: number): void {
    this.progressTarget.style.setProperty('--width', `${progressValue}%`)
    this.percentTarget.innerText = `${progressValue}%`
  }

  filenameValueChanged(filenameValue: string): void {
    this.filenameTarget.innerText = filenameValue
  }

  showErrorValueChanged(showErrorValue: boolean): void {
    toggleClass({
      element: this.element,
      className: `${CSS_PREFIX}--error`,
      predicate: showErrorValue
    })
  }

  handleStart(e: CustomEvent): void {
    const { file } = e.detail
    this.showValue = true
    this.filenameValue = file.name
  }

  handleProgress(e: CustomEvent): void {
    const { progress } = e.detail
    this.progressValue = Math.floor(parseInt(String(progress), 10))
  }

  handleError(e: CustomEvent): void {
    e.preventDefault()
    this.showErrorValue = true
  }
}
