import { Controller } from '@hotwired/stimulus'
import type { Detail } from '../select/types'
import type { ParamsToAddDetail, ParamsToRemoveDetail, Params, ParamsDetail } from '../tags/types'
import { emitEvent, broadcastEvent } from '../../frontend/src/common/dispatch-event/dispatch-event'

const CSS_PLACEHOLDER_PREFIX = 'tags__placeholder'

export default class extends Controller {
  static targets = ['tagsRest', 'placeholder', 'tag', 'tagTemplate']

  declare readonly tagsRestTarget: HTMLElement
  declare readonly placeholderTarget: HTMLElement
  declare readonly tagTargets: HTMLElement[]
  declare readonly tagTemplateTarget: HTMLElement

  static values = {
    disabled: Boolean,
    listSize: Number
  }

  declare disabledValue: boolean
  declare listSizeValue: number

  listSizeValueChanged(listSize: number): void {
    if (listSize > 0) {
      this.placeholderTarget.classList.remove(`${CSS_PLACEHOLDER_PREFIX}--show`)
    } else {
      this.placeholderTarget.classList.add(`${CSS_PLACEHOLDER_PREFIX}--show`)
    }
  }

  emitListSize(): void {
    emitEvent<{ listSize: number }>(this.element, 'selectUpdate', { listSize: this.listSizeValue })
  }

  handleStartRemove({
    detail: { params, target, isRest, internal }
  }: Detail<ParamsToRemoveDetail>): void {
    if (this.disabledValue) {
      return
    }

    target.remove()
    broadcastEvent<{ isRest: boolean }>(this.tagsRestTarget, 'remove', { isRest })
    this.listSizeValue = this.tagTargets.length

    if (internal === true) {
      emitEvent<ParamsDetail>(this.element, 'unselect', { params })
    }

    this.emitListSize()
  }

  handleChange({
    detail: { params, checked }
  }: Detail<{ params: Params; checked: boolean }>): void {
    if (checked) {
      this.add(params)
    } else {
      this.remove(params)
    }
  }

  handleToggleMulti({ detail: { selected } }: Detail<{ selected: Params[] }>): void {
    this.listSizeValue = selected.length

    this.tagTargets.forEach(target => {
      target.remove()
    })

    if (selected.length === 0) {
      broadcastEvent(this.tagsRestTarget, 'removeAll', {})
    } else {
      broadcastEvent<{ selected: Params[]; templateTarget: HTMLElement }>(
        this.tagsRestTarget,
        'addAll',
        { selected, templateTarget: this.tagTemplateTarget }
      )
    }
  }

  handleEnable(): void {
    this.disabledValue = true
    broadcastEvent(this.tagsRestTarget, 'enable', {})
    this.tagTargets.forEach(target => {
      broadcastEvent(target, 'enable', {})
    })
  }

  add(params: Params): void {
    this.listSizeValue = this.tagTargets.length + 1
    broadcastEvent<ParamsToAddDetail>(this.tagsRestTarget, 'add', {
      params,
      totalSize: this.listSizeValue,
      templateTarget: this.tagTemplateTarget
    })
    this.emitListSize()
  }

  remove(params: Params): void {
    this.tagTargets.forEach(target => {
      broadcastEvent<ParamsDetail>(target, 'remove', { params })
    })
  }
}
