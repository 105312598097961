export default function formatMask(value: string, mask: string): string {
  if (value === mask || !/[0-9]/g.test(value)) {
    return ''
  }

  return mask
    .split('')
    .map((char, i) => {
      const charAt = value.charAt(i)
      return charAt !== '' ? charAt : char
    })
    .join('')
}
