import { Controller } from '@hotwired/stimulus'
import { portal } from '../../frontend/src/common/portal/portal'
import { exit } from '../../frontend/src/common/exit/exit'
import type { ActionWithInitFactory } from '../../frontend/src/types'
import { createModal, destroyModal } from './image_viewer_utils'

const CSS_PREFIX = 'image-viewer'

export default class extends Controller {
  declare modalTarget: HTMLElement | undefined

  static values = {
    url: String
  }

  declare urlValue: string

  exitInstance: ActionWithInitFactory = { init: () => {}, destroy: () => {} }
  portalInstance: ActionWithInitFactory = { init: () => {}, destroy: () => {} }

  disconnect(): void {
    this.portalInstance.destroy()
    this.exitInstance.destroy()
  }

  handleOpen(e: MouseEvent): void {
    if (e.metaKey || e.ctrlKey) {
      return
    }

    this.modalTarget = createModal({
      className: `${CSS_PREFIX}--modal`,
      url: this.urlValue,
      handleClose: this.handleClose.bind(this)
    })
    this.portalInstance = portal(this.modalTarget)
    this.exitInstance = exit(this.element)
  }

  handleClose(): void {
    if (this.modalTarget === undefined) {
      return
    }

    destroyModal({ element: this.modalTarget, handleClose: this.handleClose.bind(this) })
    this.modalTarget = undefined
    this.portalInstance.destroy()
    this.exitInstance.destroy()
  }
}
