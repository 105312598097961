import { Controller } from '@hotwired/stimulus'
import { broadcastEvent } from '../../frontend/src/common/dispatch-event/dispatch-event'
import type { State } from './types'
import toggleClass from '../../frontend/src/common/toggle-class/toggle-class'
import { get } from '@rails/request.js'
import { remount, unmount } from '../../frontend/src/v3/mount/mount'

export default class extends Controller<HTMLFormElement> {
  static targets = [
    'state',
    'document',
    'documentError',
    'line',
    'lineError',
    'issuedAt',
    'issuedAtError',
    'omitInDeclarations'
  ]

  declare readonly stateTarget: HTMLElement
  declare readonly documentTarget: HTMLInputElement
  declare readonly documentErrorTarget: HTMLElement
  declare readonly lineTarget: HTMLInputElement
  declare readonly lineErrorTarget: HTMLElement
  declare readonly issuedAtTarget: HTMLInputElement
  declare readonly issuedAtErrorTarget: HTMLElement
  declare readonly omitInDeclarationsTarget: HTMLInputElement

  static values = {
    index: String,
    standalone: Boolean,
    confirmUrl: String,
    disabled: Boolean
  }

  declare indexValue: string
  declare standaloneValue: boolean
  declare confirmUrlValue: string
  declare disabledValue: boolean

  turboFrameTag: HTMLElement | null = null

  connect(): void {
    this.turboFrameTag = document.getElementById(`transit_${this.indexValue}`)

    if (this.standaloneValue) {
      remount(this.turboFrameTag)
    }
  }

  disconnect(): void {
    unmount(this.turboFrameTag)
  }

  isInvalid(): boolean {
    const invalidDocument = this.documentTarget.value === ''
    const invalidLine = this.lineTarget.value === ''
    const invalidIssuedAtTarget = this.issuedAtTarget.value === ''

    toggleClass({
      element: this.documentErrorTarget,
      className: 'hidden',
      predicate: !invalidDocument
    })

    toggleClass({
      element: this.lineErrorTarget,
      className: 'hidden',
      predicate: !invalidLine
    })

    toggleClass({
      element: this.issuedAtErrorTarget,
      className: 'hidden',
      predicate: !invalidIssuedAtTarget
    })

    return invalidDocument || invalidLine || invalidIssuedAtTarget
  }

  handleSubmit(e: FormDataEvent): void {
    if (this.disabledValue || this.isInvalid()) {
      e.preventDefault()
      return
    }

    this.documentTarget.value = this.documentTarget.value.trim()

    this.disabledValue = true
    broadcastEvent<{ state: State }>(this.stateTarget, 'stateChange', { state: 'loading' })
  }

  handleChange(): void {
    broadcastEvent<{ state: State }>(this.stateTarget, 'stateChange', { state: 'changed' })
  }

  handleOmitInDeclarationsChange(e: Event): void {
    const { target } = e
    const checked = (target as HTMLInputElement).checked

    if (checked) {
      broadcastEvent<{ state: State }>(this.stateTarget, 'stateChange', { state: 'loading' })
      get(this.confirmUrlValue, {
        query: {
          index: this.indexValue
        },
        responseKind: 'turbo-stream'
      }).finally(() => {
        broadcastEvent<{ state: State }>(this.stateTarget, 'stateChange', { state: 'changed' })
      })
    }
  }

  handleRevert(): void {
    this.omitInDeclarationsTarget.checked = false
  }
}
