import { Controller } from '@hotwired/stimulus'
import toggleClass from '../../frontend/src/common/toggle-class/toggle-class'

export default class extends Controller {
  static values = {
    show: Boolean
  }

  declare showValue: boolean

  showValueChanged(showValue: boolean): void {
    toggleClass({
      element: this.element,
      className: 'loading--show',
      predicate: showValue
    })
  }

  handleToggle(): void {
    this.showValue = !this.showValue
  }
}
