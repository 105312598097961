import type { Detail, ItemParams, SelectChangeDetail } from './types'
import { Direction } from './types'
import { find, includes, upperFirst } from 'lodash'
import { on, off } from '../../frontend/src/common/utils/utils'

type Selected = string[]

export function scrollToView({
  container,
  height,
  index
}: {
  container: HTMLElement
  height: number
  index: number
}): void {
  const currentOffset = height * (index + 1)

  if (currentOffset >= container.offsetHeight) {
    container.scrollTop = currentOffset - height * 2
  } else if (currentOffset <= container.scrollTop) {
    container.scrollTop = 0
  }
}

export function resetScroll(container: HTMLElement | null): void {
  if (container === null) {
    return
  }
  container.scrollTop = 0
}

function _goUp(listSize: number, index: number): number {
  if (listSize === 0) {
    return -1
  }

  return index <= 0 ? listSize - 1 : index - 1
}

function _goDown(listSize: number, index: number): number {
  if (listSize === 0) {
    return -1
  }

  return index + 1 === listSize ? 0 : index + 1
}

export function getFocusIndex({
  dir,
  listSize,
  index
}: {
  dir: Direction
  listSize: number
  index: number
}): number {
  if (dir === Direction.up) {
    return _goUp(listSize, index)
  } else {
    return _goDown(listSize, index)
  }
}

export function updateSelected(source: Selected, value: string, checked: boolean): Selected {
  return checked ? [...source, value] : source.filter(v => v !== value)
}

export function getDataParamName(key: string): string {
  return `select-Container-List-ItemComponent-Item${upperFirst(key)}Value`
}

export function exportBooleanDataParam(element: HTMLElement, key: string): boolean {
  const param = element.dataset[getDataParamName(key)]
  return param === 'true' || param === ''
}

export function extractDataParam(element: HTMLElement, key: string): string {
  return element.dataset[getDataParamName(key)] as string
}

export function getParams(target: HTMLElement): ItemParams {
  return {
    label: extractDataParam(target, 'label'),
    value: extractDataParam(target, 'value'),
    index: parseInt(extractDataParam(target, 'index'), 10),
    hidden: exportBooleanDataParam(target, 'hidden')
  }
}
export function byValue(target: HTMLElement, selected: Selected): boolean {
  const value = extractDataParam(target, 'value')
  return includes(selected, value)
}

export function byIndex(target: HTMLElement, focusIndex: number): boolean {
  return focusIndex === parseInt(extractDataParam(target, 'index'), 10)
}

export function byRegex(target: HTMLElement, regex: RegExp): boolean {
  return regex.test(extractDataParam(target, 'label'))
}

export function getSingleSelection(targets: HTMLElement[], selected: Selected): ItemParams {
  const selectedItem = find(targets, target => byValue(target, selected))

  return selectedItem === null || selectedItem === undefined
    ? { label: '', value: '', index: -1 }
    : getParams(selectedItem)
}

export function getMultiSelection(targets: HTMLElement[], selected: Selected): ItemParams[] {
  return targets.reduce((acc: ItemParams[], target: HTMLElement) => {
    const value = extractDataParam(target, 'value')
    if (includes(selected, value)) {
      return [...acc, getParams(target)]
    } else {
      return acc
    }
  }, [])
}

export function getSelection(
  targets: HTMLElement[],
  selected: Selected,
  isMulti: boolean
): ItemParams[] {
  return isMulti ? getMultiSelection(targets, selected) : [getSingleSelection(targets, selected)]
}

export function getAllSelection(targets: HTMLElement[], checked: boolean): ItemParams[] {
  return checked
    ? targets.map(target => getParams(target)).filter(params => params.hidden !== true)
    : []
}

export function isAllSelected(targets: HTMLElement[], selected: Selected): boolean {
  const visibleList = getAllSelection(targets, true)
  const checkedList = visibleList.filter(params => includes(selected, params.value))

  return checkedList.length === visibleList.length
}

export function getSelectChangeEventNames({
  scope,
  keys
}: {
  scope: number
  keys: string[]
}): string[] {
  return keys.map(key => `selectChange${scope}${key}`)
}

export function onSelectChange({
  scope = 0,
  keys,
  handle
}: {
  scope?: number
  keys: string[]
  handle: (e: Detail<SelectChangeDetail>) => void
}): () => void {
  const eventNames = getSelectChangeEventNames({ scope, keys })
  eventNames.forEach(name => {
    on(window, name, handle)
  })
  return () => {
    eventNames.forEach(name => {
      off(window, name, handle)
    })
  }
}
