import { Format, type Options } from '../types'

function maskPrice(value: string, sign: string = '', options: Options): string {
  const { centDelimiter = '.', currencySymbol = 'EUR', decimals = 2 } = options

  const intValue = parseInt(value, 10)
  const whole = Math.floor(intValue / 10 ** decimals)
  const centsValue = `${'0'.repeat(decimals)}${intValue % 10 ** decimals}`.substr(
    -decimals,
    decimals
  )
  const cents = `${decimals === 0 ? '' : centDelimiter}${centsValue}`
  const separator = currencySymbol === '' ? '' : ' '

  return `${currencySymbol}${separator}${sign}${whole}${cents}`
}

function maskLength(value: string): string {
  return `${parseInt(value, 10)}cm`
}

function maskInt(value: string): number {
  return parseInt(value, 10)
}

function maskWeight(value: string): string {
  const tons = Math.floor(parseInt(value, 10) / 1000000)
  const kgWithGrams = (parseInt(value, 10) % 1000000) / 1000
  const kg = Math.floor(kgWithGrams)
  const g = parseInt(value, 10) % 1000
  const gString = ` ${`000${g}`.substr(-3, 3)}g`
  return (tons !== 0 ? `${tons}t ${`000${kg}`.substr(-3, 3)}kg` : `${kg}kg`) + gString
}

function maskTaric(value: string): string {
  return `__________${value}`.substr(-10, 10)
}

export default function maskValue(sourceValue: string | number, options: Options): string | number {
  const stringValue = String(sourceValue)
  const value = stringValue.replace(/[^\d]/g, '')
  const hasMinus = stringValue.match(/-/g)
  const sign = hasMinus !== null && hasMinus.length % 2 !== 0 ? '-' : ''

  if (value.length === 0) {
    return ''
  }

  switch (options.format) {
    case Format.Price:
      return maskPrice(value, sign, options)
    case Format.Length:
      return maskLength(value)
    case Format.Int:
      return maskInt(value)
    case Format.Weight:
      return maskWeight(value)
    case Format.Taric:
      return maskTaric(value)
  }
}
