import { on, off, isValidJson } from '../../common/utils/utils'
import Rails from '@rails/ujs'

/*
  Conditionally skip the Rails UJS confirm dialog.

  usage:

  = f.input :confirm_if_has_value, data: { confirm_if: 'present' }
  = f.input :confirm_if_has_no_value, data: { confirm_if: 'absent' }
  = f.input :confirm_if_ruby_evaluates_to_true, data: { confirm_if: @model.value.positive? }
  = f.input :confirm_if_value_in_array, data: { confirm_if: '["VALUE-1", "VALUE-2"]' }

  = f.submit "save", data: { confirm: "You sure?", component: "conditional_confirm.js", confirm_possible: !@model.already_confirmed?, mode: "AND or OR"}
                                 ^ Rails UJS

  Currently the confirmation message is skipped if not all input conditions are met (is shown if any condition is met).
  If `mode` is set to `AND` (default is `OR`) then the confirmation will be skipped if any input condition is met (is shown only if all conditions are met).
  Additionally the confirmation message can be globally skipped on the form level
  by adding the optional `confirm_possible` prop to the submit button, and have it
  evaluate to false.
*/
export default function ConditionalConfirm({ targets, props }) {
  const { base } = targets
  const { confirmPossible, mode } = props

  function _handleSubmit(e) {
    const formElement = e.target.closest('form')
    const conditions = [...formElement.querySelectorAll('[data-confirm-if]')]
    const initial = mode === 'AND' ? !!conditions.length : false
    const requireConfirmationConditions = conditions
      .map(element => {
        const condition = element.dataset.confirmIf
        return (
          condition === 'true' ||
          (condition === 'present' && element.value.length !== 0) ||
          (condition === 'absent' && element.value.length === 0) ||
          (isValidJson(condition) && JSON.parse(condition).indexOf(element.value) !== -1)
        )
      })
      .reduce((accumulator, value) => {
        return mode === 'AND' ? accumulator && value : accumulator || value
      }, initial)

    // default behavior is that we do require confirmation
    if (requireConfirmationConditions && confirmPossible !== false) {
      // NO-OP the event is handled by Rails UJS and the confirm dialog is shown
    } else {
      // we can skip the confirm dialog and submit directly
      e.preventDefault()
      if (formElement.dataset.remote === 'true') {
        Rails.fire(formElement, 'submit')
      } else {
        formElement.submit()
      }
    }
  }

  function init() {
    on(base, 'confirm', _handleSubmit)
  }

  function destroy() {
    off(base, 'confirm', _handleSubmit)
  }

  return {
    init,
    destroy
  }
}
