import { Controller } from '@hotwired/stimulus'
import { selectAction } from '../../select_action'
import type { ItemParamsDetail, Detail, FilterDetail } from '../../types'
import type { ActionFactory } from '../../../../frontend/src/types'
import { emitEvent } from '../../../../frontend/src/common/dispatch-event/dispatch-event'

export default class extends Controller {
  static targets = ['inputHidden', 'input']

  declare readonly inputHiddenTarget: HTMLInputElement
  declare readonly inputTarget: HTMLInputElement

  actionInstance: ActionFactory = { destroy: () => {} }

  connect(): void {
    this.actionInstance = selectAction(this.element)
  }

  disconnect(): void {
    this.actionInstance.destroy()
  }

  handleSelect({ detail: { params } }: Detail<ItemParamsDetail>): void {
    this.inputTarget.value = params.label
    this.inputHiddenTarget.value = params.value
  }

  handleUp(): void {
    emitEvent(this.element, 'goUp', {})
  }

  handleDown(): void {
    emitEvent(this.element, 'goDown', {})
  }

  handleEnter(): void {
    emitEvent(this.element, 'preConfirmSelect', {})
  }

  handleFilter({ detail }: { detail: FilterDetail }): void {
    emitEvent<FilterDetail>(this.element, 'quickFilter', detail)
  }

  handleEnable(): void {
    this.inputHiddenTarget.disabled = false
    this.inputTarget.disabled = false
  }
}
