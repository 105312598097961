import { Controller } from '@hotwired/stimulus'
import extendToFullscreen from '../../frontend/src/common/utils/extend-to-fullscreen'

export default class extends Controller {
  static values = {
    selector: String
  }

  declare selectorValue: string

  handleClick(): void {
    extendToFullscreen(this.selectorValue)
  }
}
