import { type Options } from '../types'
import maskValue from './mask-value'
import onlyDigits from './only-digits'

export default function handleMaskInput(
  target: HTMLInputElement | undefined,
  input: HTMLInputElement | undefined,
  value: string,
  options: Options
): void {
  if (target === undefined || input === undefined) return

  target.value = String(maskValue(value, options))
  input.value = onlyDigits(target.value)
}
