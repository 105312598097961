export default function replaceValueAndKeepCursor(target, value) {
  if (target.value === value) {
    return
  }

  const goBack = target.value.length - value.length
  const selectionStart = target.selectionStart
  const selectionEnd = target.selectionEnd

  target.value = value
  return target.setSelectionRange(selectionStart - goBack, selectionEnd - goBack)
}
