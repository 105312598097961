import main from '../../src/v3/main'
// common
import '../../src/common/closest-polyfill/closest-polyfill'
// waybills
import '../../src/v3/prealerts_table'
import '../../src/v3/waybill_shipment'
// parcels
import '../../src/v3/line-haul/parcel-search/parcel-search'

main()
