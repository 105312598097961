import { Controller } from '@hotwired/stimulus'
import { Elements } from './shared/shared'
import { getTargetHookSelector, off, on } from '../../common/utils/utils'
import { get } from '@rails/request.js'

export default class extends Controller {
  static values = {
    url: String
  }

  declare urlValue: string

  shortcodeElement: HTMLInputElement | null = null
  loading: boolean = false
  handleFetch: () => void = () => {}

  connect(): void {
    this.shortcodeElement = document.querySelector(getTargetHookSelector('shipmentShortcode'))
    this.handleFetch = this.fetch.bind(this)
    this.fetch()
    on(this.shortcodeElement, 'change', this.handleFetch)
  }

  disconnect(): void {
    off(this.shortcodeElement, 'change', this.handleFetch)
  }

  fetch(): void {
    const shortcodeValue = Elements.shortcode()

    if (shortcodeValue === null || shortcodeValue === undefined || this.loading) {
      return
    }

    this.loading = true

    get(this.urlValue, {
      query: {
        shortcode: shortcodeValue
      },
      responseKind: 'turbo-stream'
    }).finally(() => {
      this.loading = false
    })
  }
}
