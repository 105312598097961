import { on, off } from '../../../../common/utils/utils'
import { toArray } from 'lodash'
import { SHIPMENT_CHANGE_EVENT } from '../../shipment/shipment'

export default function EnableEligibleTransportSelect({ targets }) {
  const { base, forwardersLink, parent } = targets

  function _isOptionAvailable({ ehub, shortcode, text }) {
    const regexp = new RegExp(`${ehub}:${shortcode}$`)
    return regexp.test(text)
  }

  function _hideLink() {
    forwardersLink.style.display = 'none'
  }

  function _showLink() {
    forwardersLink.style.display = 'block'
  }

  function _toggleLinkVisibility() {
    const visibleOptions = toArray(base.options).filter(
      option => !!option.value && option.style.display !== 'none'
    )

    if (visibleOptions.length === 0) {
      _showLink()
      base.disable = true
    } else {
      _hideLink()
      base.disable = false
    }
  }

  function handleChange() {
    if (base.value === '') {
      return
    }

    toArray(parent.getElementsByTagName('input')).forEach(input => {
      input.disabled = false
    })
  }

  function handleShipmentChange(e) {
    const detail = e.detail || {}

    if (!detail.ehub || !detail.shortcode) {
      base.disabled = true
      return
    }

    base.disabled = false

    toArray(base.options).forEach(option => {
      option.style.display = 'block'
      if (option.value !== '' && !_isOptionAvailable({ ...detail, text: option.text })) {
        option.style.display = 'none'
        if (option.value === base.value) {
          base.value = ''
        }
      }
    })

    _toggleLinkVisibility()
  }

  function init() {
    _hideLink()
    on(base, 'change', handleChange)
    on(base, SHIPMENT_CHANGE_EVENT, handleShipmentChange)
  }

  function destroy() {
    off(base, 'change', handleChange)
    off(base, SHIPMENT_CHANGE_EVENT, handleShipmentChange)
  }

  return {
    init,
    destroy
  }
}
