import { mountAll, unmountAll } from './components'
import COMPONENTS_MAP from '../../components-map'

function _init(path, { targets, props }) {
  const component = COMPONENTS_MAP[`${props.namespace}/${path}`]
  const instance = component({ targets, props })
  instance.init()
  return instance
}

function _destroy(instance) {
  if (typeof instance.destroy !== 'function') {
    return
  }
  instance.destroy()
}

export function mountComponents({ parent = null, namespace, aliases } = {}) {
  return mountAll({
    ext: 'js',
    parent,
    namespace,
    aliases,
    init: _init
  })
}

export function unmountComponents(parent = null) {
  unmountAll({
    ext: 'js',
    parent,
    destroy: _destroy
  })
}
