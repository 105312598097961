import { Controller } from '@hotwired/stimulus'
import type { TruckingJob } from '../form/types'
import serializeParams from '../form/utils/serialize-params'
import updateUrl from '../form/utils/update-url'
import { remount } from '../../../../frontend/src/v3/mount/mount'
import { onSelectChange } from '../../../select/select-utils'
import type { Detail, SelectChangeDetail } from '../../../select/types'

export default class extends Controller<HTMLAnchorElement> {
  static targets = [
    'searchButton',
    'selectedJobsTurboFrame',
    'routesTurboFrame',
    'eligibleTransportTurboFrame',
    'forwarderReference'
  ]

  declare readonly searchButtonTarget: HTMLAnchorElement
  declare readonly selectedJobsTurboFrameTarget: HTMLFrameElement
  declare readonly routesTurboFrameTarget: HTMLFrameElement
  declare readonly eligibleTransportTurboFrameTarget: HTMLFrameElement
  declare readonly forwarderReferenceTarget: HTMLInputElement
  declare readonly hasForwarderReferenceTarget: boolean

  static values = {
    mode: String,
    id: Number,
    pickupShortcode: String,
    deliveryShortcode: String,
    truckingJobs: Array,
    generateRoutesUrl: String,
    generateEligibleTransportUrl: String,
    truckingJobsUrl: String
  }

  declare modeValue: string
  declare idValue: number
  declare pickupShortcodeValue: string
  declare deliveryShortcodeValue: string
  declare truckingJobsValue: TruckingJob[]
  declare generateRoutesUrlValue: string
  declare generateEligibleTransportUrlValue: string
  declare truckingJobsUrlValue: string

  shortcodeChangeCount = 0

  offPickupChange = (): void => {}
  offDeliveryChange = (): void => {}

  connect(): void {
    // remount rate input instances in case of form submit with error
    remount(this.routesTurboFrameTarget)

    this.offPickupChange = onSelectChange({
      scope: 0,
      keys: ['pickup_shortcode'],
      handle: this.handleShortcodeChange.bind(this)
    })
    this.offDeliveryChange = onSelectChange({
      scope: 0,
      keys: ['delivery_shortcode'],
      handle: this.handleShortcodeChange.bind(this)
    })
  }

  disconnect(): void {
    this.offPickupChange()
    this.offDeliveryChange()
  }

  updateUrls(): void {
    let refsQueryString = serializeParams(this.truckingJobsValue, 'ref')
    const pickupsQueryString = serializeParams(this.truckingJobsValue, 'pickupShortcode')
    const deliveriesQueryString = serializeParams(this.truckingJobsValue, 'deliveryShortcode')

    if (this.idValue !== 0) {
      refsQueryString += `&id=${this.idValue}`
    }

    updateUrl(this.searchButtonTarget, [refsQueryString])
    updateUrl(this.selectedJobsTurboFrameTarget, [refsQueryString], this.truckingJobsUrlValue)
    updateUrl(
      this.routesTurboFrameTarget,
      [pickupsQueryString, deliveriesQueryString],
      this.generateRoutesUrlValue
    )
    this.resetEligibleTransports()
  }

  resetEligibleTransports(): void {
    this.pickupShortcodeValue = ''
    this.deliveryShortcodeValue = ''
    updateUrl(this.eligibleTransportTurboFrameTarget, [], this.generateEligibleTransportUrlValue)
  }

  updateEligibleTransports(): void {
    const query = [
      `pickup_shortcode=${this.pickupShortcodeValue}`,
      `delivery_shortcode=${this.deliveryShortcodeValue}`
    ]

    updateUrl(
      this.eligibleTransportTurboFrameTarget,
      this.hasForwarderReferenceTarget
        ? [...query, `forwarder_reference=${this.forwarderReferenceTarget.value}`]
        : query,
      this.generateEligibleTransportUrlValue
    )
  }

  handleAddTruckingJob({
    detail: { ref, pickupShortcode, deliveryShortcode }
  }: {
    detail: TruckingJob
  }): void {
    this.truckingJobsValue = [
      ...this.truckingJobsValue,
      { ref, pickupShortcode, deliveryShortcode }
    ]
    this.updateUrls()
  }

  handleRemoveTruckingJob({ detail: { ref } }: { detail: { ref: string } }): void {
    this.truckingJobsValue = this.truckingJobsValue.filter(truckingJob => truckingJob.ref !== ref)
    this.updateUrls()
  }

  handleShortcodeChange({ detail: { params } }: Detail<SelectChangeDetail>): void {
    const pickupShortcode = params['transport[transport_routes_attributes][][origin_shortcode]']
    const deliveryShortcode =
      params['transport[transport_routes_attributes][][destination_shortcode]']

    if (pickupShortcode !== undefined) {
      this.pickupShortcodeValue = pickupShortcode[0]
    }
    if (deliveryShortcode !== undefined) {
      this.deliveryShortcodeValue = deliveryShortcode[0]
    }

    if (this.shortcodeChangeCount < 2 && this.modeValue === 'edit') {
      this.shortcodeChangeCount += 1
      return
    }

    if (this.pickupShortcodeValue !== '' && this.deliveryShortcodeValue !== '') {
      this.updateEligibleTransports()
    }
  }
}
