export default function setCaretPosition(element, position) {
  if (element.setSelectionRange) {
    // modern browsers
    element.focus()
    element.setSelectionRange(position, position)
    return
  }

  if (element.createTextRange) {
    // <= IE8
    const range = element.createTextRange()
    range.collapse(true)
    range.moveEnd('character', position)
    range.moveStart('character', position)
    range.select()
  }
}
