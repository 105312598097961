import { setCaretPosition } from '../../../frontend/src/common/utils/utils'
import { getKey } from '../../../frontend/src/common/keys/keys'

export default function moveCaretPosition(
  e: KeyboardEvent,
  element: HTMLInputElement,
  separatorPositions: number[]
): void {
  const key = getKey(e)
  const caretPosition = element.selectionStart as number

  // number row and numpad 0-9
  if ((key >= 48 && key <= 57) || (key >= 96 && key <= 105)) {
    // advance by one if user wants to override a separator
    if (separatorPositions.includes(caretPosition)) {
      setCaretPosition(element, caretPosition + 1)
    }
  } else if (key < 48) {
    // no-op, because these are arrow keys and delete chars etc
  } else {
    // do not allow anything else (i.e. letters)
    e.preventDefault()
  }
}
