import { Controller } from '@hotwired/stimulus'
import type { Detail, ItemConfirmParamsDetail, ItemSelectedParamsDetail } from '../../types'
import {
  emitEvent,
  broadcastEvent
} from '../../../../frontend/src/common/dispatch-event/dispatch-event'

export default class extends Controller {
  static targets = ['inputHidden', 'tags']

  declare readonly inputHiddenTarget: HTMLInputElement
  declare readonly tagsTarget: HTMLInputElement

  connect(): void {
    emitEvent(this.element, 'formName', { formName: this.inputHiddenTarget.name })
  }

  updateHidden(listSize: number): void {
    this.inputHiddenTarget.disabled = listSize !== 0
  }

  handleSelect({ detail: { params, checked } }: Detail<ItemConfirmParamsDetail>): void {
    broadcastEvent<ItemConfirmParamsDetail>(this.tagsTarget, 'selectChange', { params, checked })
  }

  handleEnable({ detail: { listSize } }: Detail<{ listSize: number }>): void {
    this.updateHidden(listSize)
    broadcastEvent(this.tagsTarget, 'enable', {})
  }

  handleSelectUpdate({ detail: { listSize } }: Detail<{ listSize: number }>): void {
    this.updateHidden(listSize)
  }

  handleToggleMulti({ detail: { selected } }: Detail<ItemSelectedParamsDetail>): void {
    const visible = selected.filter(params => params.hidden !== true)
    this.updateHidden(selected.length)
    broadcastEvent<ItemSelectedParamsDetail>(this.tagsTarget, 'toggleMulti', { selected: visible })
  }
}
