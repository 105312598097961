import flatpickr from 'flatpickr'
import formatMask from './format-mask'
import { setCaretPosition } from '../../../frontend/src/common/utils/utils'
import type { Instance } from 'flatpickr/dist/types/instance'
import type { Pattern } from '../types'

export default function transformWithMasking(
  e: InputEvent,
  element: HTMLInputElement,
  calendar: null | Instance,
  pattern: Pattern
): void {
  if (calendar === null) return

  const value = element.value
  const caretPosition = element.selectionStart as number
  const isInsert = e.inputType === 'insertText'
  const isDelete = ['deleteContentBackward', 'deleteContentForward'].includes(e.inputType)
  let currentValue = value

  if (isInsert) {
    currentValue = [
      value.slice(0, caretPosition),
      value.slice(caretPosition + 1, value.length)
    ].join('')
  } else if (isDelete) {
    currentValue = [
      value.slice(0, caretPosition),
      pattern.mask.charAt(caretPosition),
      value.slice(caretPosition, value.length)
    ].join('')
  }
  const parsedDate = flatpickr.parseDate(currentValue, 'Y-m-d H:i')

  if (parsedDate !== undefined) {
    calendar.setDate(parsedDate, false)
  }

  element.value = formatMask(currentValue, pattern.mask)

  if (isInsert && pattern.separatorPositions.includes(caretPosition)) {
    setCaretPosition(element, caretPosition + 1)
  } else {
    setCaretPosition(element, caretPosition)
  }
}
