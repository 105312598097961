import { Controller } from '@hotwired/stimulus'
import type { Detail, CheckedDetail } from '../../../types'
import toggleClass from '../../../../../frontend/src/common/toggle-class/toggle-class'

const CSS_PREFIX = 'select__item-checkbox'

export default class extends Controller {
  static targets = ['checkbox']

  declare readonly checkboxTarget: HTMLInputElement

  static values = {
    disabled: Boolean,
    checked: Boolean,
    hasGhost: Boolean
  }

  declare disabledValue: boolean
  declare checkedValue: boolean
  declare hasGhostValue: boolean

  hasGhostValueChanged(hasGhost: boolean): void {
    if (!hasGhost) {
      return
    }

    const ghostElement = this.checkboxTarget.previousSibling as HTMLElement
    const isHiddenInput =
      ghostElement !== null &&
      ghostElement.tagName === 'INPUT' &&
      ghostElement.getAttribute('type') === 'hidden'

    if (isHiddenInput) {
      ghostElement.remove()
    }
  }

  disabledValueChanged(disabled: boolean): void {
    toggleClass({
      element: this.element,
      className: `${CSS_PREFIX}--disabled`,
      predicate: disabled
    })
  }

  checkedValueChanged(checked: boolean): void {
    toggleClass({
      element: this.element,
      className: `${CSS_PREFIX}--checked`,
      predicate: checked
    })
  }

  handleChange({ detail: { checked } }: Detail<CheckedDetail>): void {
    this.checkedValue = checked
    this.checkboxTarget.checked = checked
  }

  handleExternalUnselect(): void {
    this.checkedValue = false
    this.checkboxTarget.checked = false
  }

  handleEnable(): void {
    this.disabledValue = false
  }
}
