import { entries } from 'lodash'

export function getUrl(urlTemplate: string, params: Record<string, string[]>): string | null {
  const keys = Object.keys(params)
  const url = entries(params).reduce((acc, [key, value]) => {
    if (value[0] === '') {
      return acc
    } else {
      return acc.replace(`:${key}`, value[0])
    }
  }, urlTemplate)

  return keys.some(key => url.includes(`:${key}`)) ? null : url
}
