import { Controller } from '@hotwired/stimulus'
import { find } from 'lodash'
import { broadcastEvent } from '../../common/dispatch-event/dispatch-event'

type TargetKey = 'plannedPickupAt' | 'actualPickupAt' | 'plannedDeliveryAt' | 'actualDeliveryAt'
type TargetMap = {
  [key in TargetKey]?: HTMLInputElement
}
interface TargetParams {
  from: TargetKey
  to: TargetKey
}

export default class extends Controller {
  static targets = [
    'plannedPickupAt',
    'actualPickupAt',
    'plannedDeliveryAt',
    'actualDeliveryAt',
    'rate'
  ]

  declare readonly plannedPickupAtTarget: HTMLInputElement
  declare readonly actualPickupAtTarget: HTMLInputElement
  declare readonly plannedDeliveryAtTarget: HTMLInputElement
  declare readonly actualDeliveryAtTarget: HTMLInputElement
  declare readonly rateTarget: HTMLInputElement

  static values = {
    rates: Array
  }

  declare ratesValue: Array<[number, number]>

  targetsMap: TargetMap = {}

  connect(): void {
    this.targetsMap.plannedPickupAt = this.plannedPickupAtTarget
    this.targetsMap.actualPickupAt = this.actualPickupAtTarget
    this.targetsMap.plannedDeliveryAt = this.plannedDeliveryAtTarget
    this.targetsMap.actualDeliveryAt = this.actualDeliveryAtTarget
  }

  handleClick({ params }: { params: TargetParams }): void {
    const toTarget = this.targetsMap[params.to]
    const fromTarget = this.targetsMap[params.from]

    if (toTarget === undefined || fromTarget === undefined) {
      return
    }

    toTarget.value = fromTarget.value
  }

  handleEquipmentChange({ target }: { target: HTMLSelectElement }): void {
    const rate = find(this.ratesValue, ([id]) => id === parseInt(target.value, 10))
    const rateValue = rate !== undefined ? rate[1] : null

    if (rateValue !== null) {
      broadcastEvent<{ value: number }>(this.rateTarget, 'update', { value: rateValue })
    }
  }
}
