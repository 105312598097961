import {
  mountComponents as mountNativeComponents,
  unmountComponents as unmountNativeComponents
} from '../../common/boot/native-adapter'

const aliases = {
  case: {
    component: 'input-case.js',
    namespace: 'common',
    propKey: 'mode'
  },
  filter: {
    component: 'input-filter.js',
    namespace: 'common',
    propKey: 'match'
  },
  confirmLeave: {
    component: 'confirm-leave/confirm-leave-button.js'
  }
}

export function unmount(parent) {
  unmountNativeComponents(parent)
}

export function remount(parent) {
  mountNativeComponents({ aliases, parent })
}

export default function mount() {
  unmount()
  remount()
}
