import { assign } from 'lodash'

export default function copyDataset({ source, target, rest = {}, componentName }) {
  const dataset = assign(rest, source.dataset)
  const name = source.getAttribute('name')

  Object.keys(dataset).forEach(key => {
    if (dataset[key]) {
      target.dataset[key] = dataset[key]
    }
  })
  target.dataset.component = componentName
  if (name) {
    target.dataset.name = name
  }

  return target
}
