import { capitalize } from 'lodash'

const PREFIXES = ['webkit', 'moz', 'MS', 'o', '']
const TYPES = {
  transition: /(transition)(start|end|run|cancel)$/gi,
  animation: /(animation)(start|end|iteration|cancel)/gi
}

function _toPascalCase(name, regex) {
  return name.replace(regex, (match, p, s) => capitalize(p) + capitalize(s))
}

function _withPrefix(name) {
  if (TYPES.transition.test(name)) {
    return _toPascalCase(name, TYPES.transition)
  } else if (TYPES.animation.test(name)) {
    return _toPascalCase(name, TYPES.animation)
  }
  return null
}

export default function prefix(name) {
  const pascalCaseName = _withPrefix(name)
  return pascalCaseName
    ? PREFIXES.map(prefix => {
        return prefix + (prefix ? pascalCaseName : name.toLowerCase())
      })
    : [name]
}
