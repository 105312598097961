/* eslint-disable */
window.WaybillShipment = (() => {
  var _any = fields => {
    return Array.from(fields).some(element => {
      return element.value !== ''
    })
  }

  var _allBlank = fields => {
    return Array.from(fields).every(element => {
      return element.value === ''
    })
  }

  var _validateTransferT1 = transferT1Value => {
    var errors = ''
    var regexp = new RegExp('^\\w{18}$')
    if (!transferT1Value.match(regexp))
      errors = 'Transfer T1 does not match criteria, (18 alphanumeric chrs long)'
    return errors
  }

  const _pldaEmergencyProcedureActive = function (form) {
    return form.dataset.pldaEmergencyProcedure === 'true'
  }

  var _checkForm = form => {
    if (_any(form.getElementsByClassName('actual_delivery_at'))) {
      var message = 'Are you sure you want to continue?'

      if (_allBlank(form.getElementsByClassName('transfer_t1_line_number')))
        message += ' Transfer line number should be filled'

      message += ` ${_validateTransferT1(form.getElementsByClassName('transfer_t1')[0].value)}`

      if (_pldaEmergencyProcedureActive(form)) {
        message += '\n\n\n⚠⚠⚠ PLDA Emergency Procedure is active ⚠⚠⚠'
      }

      var confirmed = confirm(message)

      if (confirmed) return true

      setTimeout(function () {
        document.querySelector('input[type="submit"]').disabled = false
      }, 1000)
      return false
    }

    return true
  }

  return {
    check: _checkForm
  }
})()
