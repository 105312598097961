import { getKey, KEY_DOWN, KEY_ENTER, KEY_UP } from '../../frontend/src/common/keys/keys'
import { on, off } from '../../frontend/src/common/utils/utils'
import { includes } from 'lodash'
import Next from '../../frontend/src/common/utils/next'
import type { ActionFactory } from '../../frontend/src/types'

const UP = 'up'
const DOWN = 'down'
const ENTER = 'enter'
const FILTER = 'filter'
const ALPHANUM_REGEX = /[a-z0-9-_ ]/i

export function selectAction(node: Element): ActionFactory {
  const next = Next(300)
  let queryString = ''

  function dispatchFilterEvent(input: string): void {
    queryString += input

    next.run(() => {
      dispatchEvent(FILTER, { q: queryString })
      queryString = ''
    })
  }

  function dispatchEvent(action: string, detail?: { q: string }): void {
    node.dispatchEvent(new CustomEvent(action, { detail }))
  }

  function handleKeyDown(e: KeyboardEvent): void {
    const key = getKey(e)
    const input = String.fromCharCode(key)
    const isAlphaNum = ALPHANUM_REGEX.test(input)

    if (includes([KEY_UP, KEY_DOWN, KEY_ENTER], key)) {
      e.preventDefault()
    }

    if (isAlphaNum) {
      dispatchFilterEvent(input)
      return
    }

    switch (key) {
      case KEY_UP:
        dispatchEvent(UP)
        break
      case KEY_DOWN:
        dispatchEvent(DOWN)
        break
      case KEY_ENTER:
        dispatchEvent(ENTER)
        break
    }
  }

  on(node, 'keydown', handleKeyDown)

  return {
    destroy() {
      off(node, 'keydown', handleKeyDown)
      next.clear()
    }
  }
}
