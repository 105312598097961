import { Controller } from '@hotwired/stimulus'
import flatpickr from 'flatpickr'
import moveCaretPosition from './utils/move-caret-position'
import separatorPositions from './utils/separator-positions'
import transformWithMasking from './utils/transform-with-masking'
import type { Instance } from 'flatpickr/dist/types/instance'
import type { Format, Pattern } from './types'
import formatMask from './utils/format-mask'

const DEFAULT_FORMAT: Format = 'date'
const PATTERN_MASKS = {
  date: {
    mask: 'YYYY-MM-DD',
    timeRequired: false,
    get separatorPositions() {
      return separatorPositions(this.mask)
    }
  },
  datetime: {
    mask: 'YYYY-MM-DD hh:mm',
    timeRequired: true,
    get separatorPositions() {
      return separatorPositions(this.mask)
    }
  }
}

export default class extends Controller<HTMLInputElement> {
  static values = {
    format: String
  }

  declare formatValue: Format

  pattern: Pattern = PATTERN_MASKS[DEFAULT_FORMAT]
  calendar: null | Instance = null

  connect(): void {
    this.pattern = PATTERN_MASKS[this.formatValue]
    this.calendar = flatpickr(this.element, {
      time_24hr: true,
      allowInput: true,
      defaultHour: 0,
      enableTime: this.pattern.timeRequired
    })
  }

  handleKeydown(e: KeyboardEvent): void {
    moveCaretPosition(e, this.element, this.pattern.separatorPositions)
  }

  handleInput(e: InputEvent): void {
    transformWithMasking(e, this.element, this.calendar, this.pattern)
  }

  handleUpdate({ detail: { value } }: { detail: { value: string } }): void {
    if (this.calendar === null) {
      return
    }

    const parsedDate = flatpickr.parseDate(value, 'Y-m-d H:i')

    if (parsedDate !== undefined) {
      this.calendar.setDate(value, false, 'YYYY-MM-DD')
    }
    this.element.value = formatMask(value, this.pattern.mask)
  }
}
