import { off, on } from '../../../common/utils/utils'
import Next from '../../../common/utils/next'
import { toArray } from 'lodash'

export const SHIPMENT_CHANGE_EVENT = 'shipment-change'

export default function Shipment({ targets }) {
  const { shipmentEhub, shipmentShortcode } = targets
  const next = Next()

  function _getChildren() {
    const equipment = toArray(document.querySelectorAll('[data-target="equipment"]'))
    const shortcodeNotes = document.querySelector('[data-target="shortcodeNotes"]')
    return shortcodeNotes ? [...equipment, shortcodeNotes] : equipment
  }

  function _dispatchEvent(element) {
    element.dispatchEvent(
      new CustomEvent(SHIPMENT_CHANGE_EVENT, {
        detail: {
          ehub: shipmentEhub.value,
          shortcode: shipmentShortcode.value
        }
      })
    )
  }

  function trigger() {
    _getChildren().forEach(_dispatchEvent)
  }

  function init() {
    next.run(trigger)
    on(shipmentEhub, 'change', trigger)
    on(shipmentShortcode, 'change', trigger)
  }

  function destroy() {
    next.clear()
    off(shipmentEhub, 'change', trigger)
    off(shipmentShortcode, 'change', trigger)
  }

  return {
    init,
    destroy
  }
}
