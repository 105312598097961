import { Controller } from '@hotwired/stimulus'
import { toggleQueryString } from './auto_archive_utils'

export default class extends Controller {
  static values = {
    name: String,
    checked: Boolean
  }

  declare nameValue: string
  declare checkedValue: boolean

  handleChange(): void {
    this.checkedValue = !this.checkedValue
    toggleQueryString(this.nameValue, this.checkedValue)
  }
}
