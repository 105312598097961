import { on, off } from '../../../common/utils/utils'
import { onSelectChange } from '../../../../../components/select/select-utils'
import { times, keys, values } from 'lodash'

export default function ToggleActions({ targets, props = {} }) {
  const base = targets.base
  const acceptButton = targets.accept
  const rejectButton = targets.reject
  const spotcheckButton = targets.spotcheck
  const { productsSize } = props
  let offSelectChange = []

  function toggle() {
    if (base.value.trim()) {
      acceptButton.disabled = true
      rejectButton.disabled = false
      spotcheckButton.disabled = false
    } else {
      acceptButton.disabled = false
      rejectButton.disabled = true
      spotcheckButton.disabled = true
    }
  }

  function init() {
    on(base, 'input', toggle)
    toggle()
    offSelectChange = listenToRejectionReasons()
  }

  function destroy() {
    off(base, 'input', toggle)
    offSelectChange.forEach(off => off())
  }

  function listenToRejectionReasons() {
    return times(productsSize, scope => {
      return onSelectChange({
        scope,
        keys: ['rejection_reason'],
        handle: handleRejectionReasonChange
      })
    })
  }

  function handleRejectionReasonChange({ detail: { params } }) {
    const scope = keys(params)[0].replace('rejection_reason_', '')
    const rejectionReason = values(params)[0][0]

    if (!rejectionReason) {
      return
    }

    const prepend = base.value ? `${base.value}\n` : ''
    base.value = `${prepend}${parseInt(scope) + 1}. ${rejectionReason}`
    base.dispatchEvent(new Event('input'))
  }

  return {
    init,
    destroy
  }
}
