import { debounce } from 'lodash'
import { inKg, inEur, delegate, getTargetHookSelector } from '../../../common/utils/utils'
import http from '../../../common/http/http'

const URL = '/shipments/labels/courier_costs'
const PARCELS_CONTAINER_SELECTOR = '#available_parcel_list'
const FROM_EHUB_SELECTOR = getTargetHookSelector('shipmentEhub')
const SHORTCODE_SELECTOR = getTargetHookSelector('shipmentShortcode')
const COST_TABLE_SELECTOR = '.shipment-courier-cost'
const CHECKBOX_SELECTOR = 'input[type=checkbox]'

const _delayedChangeHandle = debounce(reloadData, 200)
let _parcelsContainer = null
let _shortcode = null
let _fromEhub = null
let _offs = []
let _shipmentCostsTable = null

function reloadData() {
  if (!_shipmentCostsTable) {
    return
  }

  const csrfParam = document.querySelector('meta[name="csrf-param"]').content
  const csrfToken = document.querySelector('meta[name="csrf-token"]').content

  const parcelsList = [].slice.call(_parcelsContainer.querySelectorAll(CHECKBOX_SELECTOR))

  const deliveryIds = parcelsList
    .filter(node => {
      return node.checked && node.name === 'shipment[delivery_ids][]'
    })
    .map(node => node.value)

  const params = {
    from_ehub: _fromEhub.value,
    shortcode: _shortcode.value,
    delivery_ids: deliveryIds,
    [csrfParam]: csrfToken
  }
  http({ url: URL, method: 'POST', params }).then(result => {
    Object.keys(result).forEach(key => {
      const entry = result[key]
      if (entry.total_cost !== null) {
        _shipmentCostsTable.querySelector(`.${key}-cost`).innerText = inEur(entry.total_cost)
        _shipmentCostsTable.querySelector(`.${key}-total-weight`).innerText = inKg(
          entry.total_weight
        )
        _shipmentCostsTable.querySelector(`.${key}-button`).disabled = false
      } else {
        _shipmentCostsTable.querySelector(`.${key}-cost`).innerText = 'N/A'
        _shipmentCostsTable.querySelector(`.${key}-total-weight`).innerText = 'N/A'
        _shipmentCostsTable.querySelector(`.${key}-button`).disabled = true
      }
    })
  })
}

function _registerChangeEvent(elements) {
  elements.forEach(([element, childSelector]) => {
    _offs.push(delegate(element, childSelector, 'change', _delayedChangeHandle))
  })
}

function _clearChangeEvent() {
  _offs.forEach(off => {
    if (typeof off === 'function') {
      off()
    }
  })
}

function init() {
  if (_shipmentCostsTable) {
    destroy()
  }

  _shipmentCostsTable = document.querySelector(COST_TABLE_SELECTOR)

  if (!_shipmentCostsTable) {
    return
  }

  _parcelsContainer = document.querySelector(PARCELS_CONTAINER_SELECTOR)
  _fromEhub = document.querySelector(FROM_EHUB_SELECTOR)
  _shortcode = document.querySelector(SHORTCODE_SELECTOR)

  const elements = [[_parcelsContainer, CHECKBOX_SELECTOR], [_fromEhub], [_shortcode]]

  _registerChangeEvent(elements)
  reloadData()
}

function destroy() {
  if (!_shipmentCostsTable) {
    return
  }

  _clearChangeEvent()

  _parcelsContainer = null
  _fromEhub = null
  _shortcode = null
  _offs = []
  _shipmentCostsTable = null
}

const ShipmentCourierCost = {
  init,
  destroy,
  reloadData
}

export default ShipmentCourierCost
