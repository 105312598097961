import { remount, unmount } from '../mount/mount'
import { on, off } from '../../common/utils/utils'
import Row from './row'
import Rows from './rows'
import clone from './clone'

export default function Duplicate({ targets }) {
  const rowsInstance = Rows()
  const addButton = targets.add
  let rows = [...targets.rows]

  rowsInstance.onRemove(_handleRemove)

  function _initRow(row) {
    Row({
      target: row,
      onInit(removeButton) {
        return rowsInstance.push(removeButton)
      },
      onRemove(index) {
        rowsInstance.remove(index)
      }
    })
  }

  function _handleAdd() {
    const sourceRow = rows[rows.length - 1]
    const cloneRow = clone(sourceRow)

    rows.push(cloneRow)
    _initRow(cloneRow)

    sourceRow.parentNode.insertBefore(cloneRow, sourceRow.nextSibling)
    remount(cloneRow)
  }

  function _handleRemove(index) {
    rows = rows.filter(row => {
      if (row.dataset.index === index) {
        unmount(row)
        return false
      } else {
        return true
      }
    })
  }

  function init() {
    rows.forEach(row => _initRow(row))
    on(addButton, 'click', _handleAdd)
  }

  function destroy() {
    rowsInstance.destroy()
    off(addButton, 'click', _handleAdd)
  }

  return {
    init,
    destroy
  }
}
