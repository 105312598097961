import { Controller } from '@hotwired/stimulus'
import type { Detail } from '../../select/types'
import type { ParamsToRemoveDetail, ParamsDetail } from '../types'
import { emitEvent } from '../../../frontend/src/common/dispatch-event/dispatch-event'
import toggleClass from '../../../frontend/src/common/toggle-class/toggle-class'

const CSS_PREFIX = 'tags__container'

export default class extends Controller {
  static targets = ['label', 'button']

  declare readonly labelTarget: HTMLElement
  declare readonly buttonTarget: HTMLButtonElement

  static values = {
    disabled: Boolean,
    isRest: Boolean,
    isTemplate: Boolean,
    label: String,
    value: String
  }

  declare disabledValue: boolean
  declare isRestValue: boolean
  declare isTemplateValue: boolean
  declare labelValue: string
  declare valueValue: string

  connect(): void {
    if (this.isTemplateValue && this.labelValue !== '') {
      this.labelTarget.innerText = this.labelValue
      this.isTemplateValue = false
    }
  }

  isRestValueChanged(isRest: boolean): void {
    toggleClass({
      element: this.element,
      className: `${CSS_PREFIX}--rest`,
      predicate: isRest
    })
  }

  isTemplateValueChanged(isTemplate: boolean): void {
    toggleClass({
      element: this.element,
      className: `${CSS_PREFIX}--template`,
      predicate: isTemplate
    })
  }

  disabledValueChanged(disabled: boolean): void {
    this.buttonTarget.disabled = disabled
  }

  emitRemove(internal: boolean = false): void {
    emitEvent<ParamsToRemoveDetail>(this.element, 'startRemove', {
      params: {
        label: this.labelValue,
        value: this.valueValue
      },
      target: this.element,
      isRest: this.isRestValue,
      internal
    })
  }

  handleRemove(): void {
    if (this.disabledValue || this.isTemplateValue) {
      return
    }
    this.emitRemove(true)
  }

  handleRemoveCheck({ detail: { params } }: Detail<ParamsDetail>): void {
    if (params.value !== this.valueValue || this.disabledValue || this.isTemplateValue) {
      return
    }
    this.emitRemove()
  }

  handleEnable(): void {
    this.disabledValue = false
  }
}
