import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['link', 'checkbox']

  declare linkTarget: HTMLButtonElement
  declare targetHref: string
  declare checkboxTargets: HTMLInputElement[]

  connect(): void {
    const href = this.linkTarget.getAttribute('href')
    if (href == null) {
      throw new Error('CheckboxLinkController: link target must have an href attribute')
    }

    this.targetHref = href

    this.checkboxTargets.forEach(checkbox => {
      checkbox.addEventListener('change', this.updateTargetState.bind(this))
    })
    this.updateTargetState()
  }

  disconnect(): void {
    this.checkboxTargets.forEach(checkbox => {
      checkbox.removeEventListener('change', this.updateTargetState.bind(this), false)
    })
  }

  toggleAll(event: Event): void {
    if (!(event.target instanceof HTMLInputElement)) return

    for (const checkBox of this.checkboxTargets.filter(checkbox => !checkbox.disabled)) {
      checkBox.checked = event.target.checked
    }
    this.updateTargetState()
  }

  updateTargetState(): void {
    const selectedCheckboxIds = this.checkboxTargets
      .filter(checkbox => checkbox.checked)
      .map(checkbox => checkbox.value)

    const isAnyCheckboxSelected = selectedCheckboxIds.length > 0

    if (isAnyCheckboxSelected) {
      const newHref = `${this.targetHref}?ids[]=${selectedCheckboxIds.join('&ids[]=')}`
      this.linkTarget.setAttribute('href', newHref)
      this.linkTarget.classList.remove('disabled')
    } else {
      this.linkTarget.classList.add('disabled')
      this.linkTarget.removeAttribute('href')
    }
  }
}
