import { Controller } from '@hotwired/stimulus'
import { broadcastEvent } from '../../common/dispatch-event/dispatch-event'

export default class extends Controller {
  static targets = ['cost', 'type']

  declare readonly costTarget: HTMLInputElement
  declare readonly typeTarget: HTMLSelectElement

  handleChange(): void {
    const value = this.typeTarget.options[this.typeTarget.selectedIndex].dataset.price
    if (value === null || value === undefined) {
      return
    }
    broadcastEvent<{ value: number }>(this.costTarget, 'update', { value: parseFloat(value) })
  }
}
