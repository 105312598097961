import { isEmpty } from 'lodash'

const DEFAULT_DATA_TYPE = 'json'
const CONTENT_TYPES = {
  json: 'application/json',
  html: 'text/html'
}

function _parseJson(response) {
  try {
    return JSON.parse(response)
  } catch (e) {
    return response
  }
}

function _encodeParams(params) {
  return Object.keys(params || {})
    .map(key => `${key}=${encodeURIComponent(params[key])}`)
    .join('&')
}

function _parseResponse(xhr, dataType) {
  return xhr.responseText
    ? dataType === 'json'
      ? _parseJson(xhr.responseText)
      : xhr.responseText
    : ''
}

function _handleReadyStateChange(resolve, reject, xhr, dataType) {
  if (xhr.readyState !== 4) {
    return
  }

  const response = _parseResponse(xhr, dataType)

  if (xhr.status >= 200 && xhr.status < 300) {
    resolve(response)
  } else {
    reject(response)
  }
}

export default function http({ url, method = 'GET', dataType = 'json', params }) {
  return new Promise((resolve, reject) => {
    const csrfToken = document.querySelector('meta[name="csrf-token"]')
    const xhr = new XMLHttpRequest()

    const finalUrl = method === 'GET' && !isEmpty(params) ? `${url}?${_encodeParams(params)}` : url

    xhr.open(method, finalUrl, true)
    xhr.setRequestHeader(
      'Content-Type',
      CONTENT_TYPES[dataType] || CONTENT_TYPES[DEFAULT_DATA_TYPE]
    )
    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest')
    if (csrfToken) {
      xhr.setRequestHeader('X-CSRF-Token', csrfToken.content)
    }
    if (dataType === 'json') {
      xhr.setRequestHeader('Accept', 'application/json')
    }

    xhr.onreadystatechange = () => _handleReadyStateChange(resolve, reject, xhr, dataType)
    xhr.onerror = () => reject(_parseResponse(xhr, dataType))

    xhr.send(method !== 'GET' && params ? JSON.stringify(params) : undefined)
  })
}
