import { Controller } from '@hotwired/stimulus'
import Next from '../../../frontend/src/common/utils/next'
import { debounce } from 'lodash'
import type { Detail, ToggleDetail, FilterDetail } from '../types'
import { emitEvent } from '../../../frontend/src/common/dispatch-event/dispatch-event'

export default class extends Controller {
  static targets = ['input']

  declare readonly inputTarget: HTMLInputElement

  static values = {
    withRemote: Boolean
  }

  declare withRemoteValue: boolean

  next = Next()
  lazySearch = debounce(this.handleSearch.bind(this), 500)

  disconnect(): void {
    this.next.clear()
  }

  emitSearch(): void {
    emitEvent<FilterDetail>(this.element, 'initSearch', { q: this.inputTarget.value })
  }

  handleSearch(): void {
    this.emitSearch()
  }

  handleToggle({ detail: { expanded } }: Detail<ToggleDetail>): void {
    if (expanded) {
      this.next.run(() => {
        this.inputTarget.focus()
      })
    } else {
      this.next.clear()
      this.inputTarget.value = ''
      if (!this.withRemoteValue) {
        this.emitSearch()
      }
    }
  }

  handleKeyPress(e: KeyboardEvent): void {
    if (e.code === 'Enter') {
      e.preventDefault()
    }
  }
}
