import maskValue from './mask-value'
import onlyDigits from './only-digits'
import { Format, type Options } from '../types'

let _timeoutId: ReturnType<typeof setTimeout> | undefined

export default function handleMaskDelete(
  e: KeyboardEvent,
  options: Options,
  input: HTMLInputElement | undefined
): void {
  const target = e.target as HTMLInputElement
  const value = onlyDigits(target.value)
  const deleteKeyCode = 8

  if (deleteKeyCode !== e.keyCode) {
    return
  }

  if (parseInt(value, 10) === 0) {
    clearTimeout(_timeoutId)
    _timeoutId = setTimeout(() => {
      target.value = ''
      if (input !== undefined) {
        input.value = ''
      }
    })
    return
  }

  if ([Format.Weight, Format.Length].includes(options.format)) {
    clearTimeout(_timeoutId)
    _timeoutId = setTimeout(() => {
      target.value = String(maskValue(value.slice(0, -1), options))
      if (input !== undefined) {
        input.value = onlyDigits(target.value)
      }
    })
  }
}
