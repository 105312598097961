export const ASC = 'asc'
export const DESC = 'desc'
const DEFAULT_NUM_OF_SORTERS = 1

function _compare(a, b, dir) {
  const reverse = dir === ASC ? 1 : -1
  const expr1 = a < b
  const expr2 = a > b

  return reverse * (expr1 ? -1 : expr2 ? 1 : 0)
}

function _sort(params, keys, collection) {
  collection.sort((a, b) => {
    return params.reduce((acc, param) => {
      const index = keys.indexOf(param.key)
      return acc || _compare(a.values[index], b.values[index], param.dir)
    }, 0)
  })
}

export default function Sorter({ numOfSorters } = {}) {
  let _params = []
  numOfSorters = numOfSorters || DEFAULT_NUM_OF_SORTERS

  function sort(key, keys, collection) {
    _params.forEach(sorter => {
      if (sorter.key === key) {
        sorter.dir = sorter.dir === ASC ? DESC : ASC
      }
    })

    if (_params.every(sorter => sorter.key !== key)) {
      if (_params.length === numOfSorters) {
        _params = []
      }
      _params.push({ key, dir: ASC })
    }

    _sort(_params, keys, collection)
  }

  function cancel() {
    _params = []
  }

  function params() {
    return _params.map(({ key, dir }, idx) => ({ key, dir, idx: idx + 1 }))
  }

  return {
    sort,
    cancel,
    params
  }
}
