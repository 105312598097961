import mount, { remount } from './mount/mount'
import Rails from '@rails/ujs'
import '@hotwired/turbo-rails'
import * as ActiveStorage from '@rails/activestorage'
import Navigation from '../navigation/navigation'
import ConfirmLeave from './confirm-leave/confirm-leave'
import EnterToTab from './confirm-leave/enter-to-tab/enter-to-tab'
import Tooltip from '../common/tooltip/tooltip'
import Confirm from '../common/confirm/confirm'
import Controllers from './stimulus'
import './config'

function handleReady() {
  EnterToTab.init()
}

function handleTurboRequestStart() {
  ConfirmLeave.destroy()
}

function handleTurboLoad() {
  Navigation.init()
  ConfirmLeave.init()
  Tooltip.init()
  Confirm.init()
  mount()
}

function handleTurboFrameLoad(e) {
  const parent = e.target

  if (parent === document.documentElement) {
    return
  }

  remount(parent)
}

function handleTurboFrameMissing(evt) {
  evt.preventDefault()
}

export default function main() {
  Rails.start()
  ActiveStorage.start()
  Controllers.start()

  document.addEventListener('DOMContentLoaded', handleReady, false)
  document.addEventListener('turbo:request-start', handleTurboRequestStart, false)
  document.addEventListener('turbo:load', handleTurboLoad, false)
  document.addEventListener('turbo:frame-load', handleTurboFrameLoad, false)
  document.addEventListener('turbo:frame-missing', handleTurboFrameMissing, false)
}
