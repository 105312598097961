import { Controller } from '@hotwired/stimulus'

export default class extends Controller<HTMLElement> {
  static targets = ['input']

  declare readonly inputTargets: Array<HTMLInputElement | HTMLSelectElement>

  onChange({
    target,
    params: { deactivateOn = [] }
  }: {
    target: HTMLSelectElement
    params: { deactivateOn: string[] }
  }): void {
    this.inputTargets.forEach(input => {
      if (deactivateOn.includes(target.value)) {
        input.value = ''
        input.disabled = true
      } else {
        input.disabled = false
      }
    })
  }
}
