export default function dispatchEvent<T>(
  element: Element | Document | Window,
  eventName: string,
  detail: T,
  bubbles: boolean = false
): void {
  element.dispatchEvent(new CustomEvent(eventName, { detail, bubbles }))
}

export function broadcastEvent<T>(
  element: Element | Document | Window,
  eventName: string,
  detail: T
): void {
  dispatchEvent<T>(element, eventName, detail)
}

export function emitEvent<T>(
  element: Element | Document | Window,
  eventName: string,
  detail: T
): void {
  dispatchEvent<T>(element, eventName, detail, true)
}
