import { isObject } from 'lodash'

export default function isValidJson(string) {
  try {
    const json = JSON.parse(string)
    return isObject(json)
  } catch (e) {
    return false
  }
}
