import { Controller } from '@hotwired/stimulus'
import getAlignment from '../../../frontend/src/common/utils/get-alignment'
import type { Detail, ToggleDetail } from '../types'
import { broadcastEvent } from '../../../frontend/src/common/dispatch-event/dispatch-event'

const CSS_PREFIX = 'select__container'

export default class extends Controller {
  static targets = ['list']

  declare readonly listTarget: HTMLElement
  declare readonly hasListTarget: boolean

  static values = {
    expanded: { type: Boolean, default: false }
  }

  declare expandedValue: boolean

  expandedValueChanged(): void {
    if (this.expandedValue) {
      this.expand()
    } else {
      this.collapse()
    }
  }

  handleToggle({ detail: { expanded } }: Detail<ToggleDetail>): void {
    this.expandedValue = expanded
  }

  align(): void {
    const align = getAlignment(this.element)

    if (align === 'right') {
      this.element.classList.add(`${CSS_PREFIX}--right`)
      this.element.classList.remove(`${CSS_PREFIX}--left`)
    } else {
      this.alignReset()
    }
  }

  alignReset(): void {
    this.element.classList.remove(`${CSS_PREFIX}--right`)
    this.element.classList.add(`${CSS_PREFIX}--left`)
  }

  expand(): void {
    this.element.classList.add(`${CSS_PREFIX}--expanded`)
    this.align()
    if (this.hasListTarget) {
      broadcastEvent(this.listTarget, 'expand', {})
    }
  }

  collapse(): void {
    this.element.classList.remove(`${CSS_PREFIX}--expanded`)
    this.alignReset()
  }
}
