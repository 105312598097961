import { Controller } from '@hotwired/stimulus'
import {
  scrollToView,
  resetScroll,
  getFocusIndex,
  updateSelected,
  getParams,
  getSelection,
  getAllSelection,
  byIndex,
  byRegex,
  isAllSelected
} from '../../select-utils'
import {
  emitEvent,
  broadcastEvent
} from '../../../../frontend/src/common/dispatch-event/dispatch-event'
import { selectAction } from '../../select_action'
import { find, includes } from 'lodash'
import type {
  ItemParams,
  Detail,
  ItemParamsDetail,
  ItemConfirmParamsDetail,
  FocusDetail,
  SearchDetail,
  DirectionDetail,
  ItemSelectedParamsDetail,
  CheckedDetail
} from '../../types'
import type { ActionFactory } from '../../../../frontend/src/types'

export default class extends Controller {
  static targets = ['item']

  declare readonly itemTargets: HTMLElement[]

  static values = {
    listSize: { type: Number, default: 0 },
    selected: Array,
    focusIndex: { type: Number, default: -1 },
    multi: Boolean
  }

  declare listSizeValue: number
  declare selectedValue: string[]
  declare focusIndexValue: number
  declare multiValue: boolean

  actionInstance: ActionFactory = { destroy: () => {} }

  connect(): void {
    this.actionInstance = selectAction(this.element)
  }

  disconnect(): void {
    this.actionInstance.destroy()
  }

  selectedValueChanged(): void {
    const selected = getSelection(this.itemTargets, this.selectedValue, this.multiValue)
    this.focusIndexValue = selected.length > 0 ? selected[0].index : -1

    emitEvent<ItemSelectedParamsDetail>(this.element, 'listUpdate', { selected })
  }

  scroll(index?: number): void {
    scrollToView({
      container: this.element as HTMLElement,
      height: 42,
      index: index ?? this.focusIndexValue
    })
  }

  broadcastEventToItems<T>(eventName: string, detail: T): void {
    this.itemTargets.forEach(target => {
      broadcastEvent<T>(target, eventName, detail)
    })
  }

  singleSelect(params: ItemParams): void {
    this.selectedValue = [params.value]
    this.broadcastEventToItems<ItemParamsDetail>('itemSelect', { params })
    emitEvent<ItemParamsDetail>(this.element, 'itemConfirmSelect', { params })
  }

  multiSelect(params: ItemParams): void {
    const checked = !includes(this.selectedValue, params.value)
    this.selectedValue = updateSelected(this.selectedValue, params.value, checked)

    this.broadcastEventToItems<ItemConfirmParamsDetail>('itemSelect', { params, checked })
    emitEvent<ItemConfirmParamsDetail>(this.element, 'itemConfirmSelect', {
      params,
      checked,
      noToggle: true,
      allChecked: isAllSelected(this.itemTargets, this.selectedValue)
    })
  }

  handleSelect({ params }: ItemParamsDetail): void {
    params.value = `${params.value}`

    if (this.multiValue) {
      this.multiSelect(params)
    } else {
      this.singleSelect(params)
    }
  }

  handleExternalUnselect({ detail: { params } }: Detail<ItemParamsDetail>): void {
    this.selectedValue = updateSelected(this.selectedValue, params.value, false)
    this.broadcastEventToItems<ItemParamsDetail>('unselect', { params })
  }

  handleToggleAll({ detail: { checked } }: Detail<CheckedDetail>): void {
    const selected = getAllSelection(this.itemTargets, checked)
    this.selectedValue = selected.map(item => item.value)
    this.broadcastEventToItems<CheckedDetail>('toggleAll', { checked })
    emitEvent<ItemSelectedParamsDetail>(this.element, 'confirmToggleAll', { selected })
  }

  handleFocus({ detail: { dir } }: Detail<DirectionDetail>): void {
    this.focusIndexValue = getFocusIndex({
      dir,
      listSize: this.listSizeValue,
      index: this.focusIndexValue
    })
    this.scroll(this.focusIndexValue)
    this.broadcastEventToItems<FocusDetail>('focus', { index: this.focusIndexValue })
  }

  handleSearch({ detail }: Detail<SearchDetail>): void {
    resetScroll(this.element as HTMLElement)
    this.broadcastEventToItems<SearchDetail>('search', detail)
    emitEvent<CheckedDetail>(this.element, 'toggleAllUpdate', {
      checked: isAllSelected(this.itemTargets, this.selectedValue)
    })
  }

  handleExpand(): void {
    this.scroll()
  }

  handleConfirmSelect({ detail: { regex } }: Detail<Partial<SearchDetail>>): void {
    const findByRegex = regex !== null && regex !== undefined
    const focusedItem = find(this.itemTargets, target =>
      findByRegex ? byRegex(target, regex) : byIndex(target, this.focusIndexValue)
    )

    if (focusedItem === undefined) {
      return
    }

    const params = getParams(focusedItem)
    this.selectedValue = [params.value]
    this.focusIndexValue = params.index

    this.broadcastEventToItems<ItemParamsDetail>('itemSelect', { params })
    emitEvent<ItemConfirmParamsDetail>(this.element, 'itemConfirmSelect', {
      params,
      noToggle: findByRegex
    })
  }

  handleEnable(): void {
    this.broadcastEventToItems('enable', {})
  }
}
