import { Controller } from '@hotwired/stimulus'
import { emitEvent } from '../../../../../frontend/src/common/dispatch-event/dispatch-event'
import type { TruckingJob } from '../types'

export default class extends Controller {
  static values = {
    ref: String,
    pickupShortcode: String,
    deliveryShortcode: String,
    disabled: Boolean
  }

  declare refValue: string
  declare pickupShortcodeValue: string
  declare deliveryShortcodeValue: string
  declare disabledValue: boolean

  handleClick(): void {
    if (this.disabledValue) {
      return
    }
    emitEvent<TruckingJob>(window, 'trucking-job:add', {
      ref: this.refValue,
      pickupShortcode: this.pickupShortcodeValue,
      deliveryShortcode: this.deliveryShortcodeValue
    })
    emitEvent(this.element, 'turbo-modal:hide', {})
  }
}
