import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['titleBar', 'caret', 'content']
  static values = { expanded: Boolean }

  initialize() {
    if (this.expandedValue) {
      this.expand()
    }
  }

  toggleContent() {
    if (this.expandedValue) {
      this.reduce()
    } else {
      this.expand()
    }

    this.expandedValue = !this.expandedValue
  }

  expand() {
    this.contentTarget.classList.remove('invisible')
    this.titleBarTarget.classList.add('expanded')

    this.caretTarget.classList.add('down')
  }

  reduce() {
    this.contentTarget.classList.add('invisible')
    this.titleBarTarget.classList.remove('expanded')

    this.caretTarget.classList.remove('down')
  }
}
