import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['rowForm', 'rowCurrent']

  declare readonly rowFormTarget: HTMLElement
  declare readonly rowCurrentTarget: HTMLElement

  static values = {
    edit: Boolean
  }

  declare editValue: boolean

  editValueChanged(editValue: boolean): void {
    if (editValue) {
      this.rowFormTarget.style.display = 'block'
      this.rowCurrentTarget.style.display = 'none'
    } else {
      this.rowFormTarget.style.display = 'none'
      this.rowCurrentTarget.style.display = 'flex'
    }
  }

  handleToggleEdit(): void {
    this.editValue = !this.editValue
  }
}
