import { entries, isArray, isBoolean, isObject } from 'lodash'
import { isValidJson } from './utils'

function _parseSource(source) {
  let list = []
  if (source && isValidJson(source)) {
    list = JSON.parse(source)
  } else if (isArray(source)) {
    list = [...source]
  } else if (isObject(source)) {
    list = source
  }

  if (!isArray(list)) {
    list = entries(list).reduce((acc, [key, array]) => [...acc, [key, -1, true], ...array], [])
  }

  return list
}

export default function parseList(source, blank) {
  let list = _parseSource(source)
  const first = list[0]
  const isFlat = list.length ? !isArray(first) : true
  const isFormatted = list.length ? first.label && first.value : false

  list = isFormatted
    ? list
    : list.map(item => {
        if (item.label && item.value) {
          return item
        }
        const itemObj = isFlat ? { label: item, value: item } : { label: item[0], value: item[1] }
        if (isArray(item) && item[2]) {
          if (isBoolean(item[2])) {
            itemObj.group = true
          }
          if (isObject(item[2])) {
            itemObj.props = item[2]
          }
        }
        return itemObj
      })

  return blank ? [{ label: isBoolean(blank) ? ' ' : blank, value: '' }, ...list] : list
}
