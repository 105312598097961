import ConditionalConfirm from './v3/conditional_confirm/conditional_confirm.js'
import ConfirmLeaveButton from './v3/confirm-leave/confirm-leave-button/confirm-leave-button'
import Duplicate from './v3/duplicate/duplicate'
import ManageWaybillsInTransferShipment from './v3/line-haul/manage-waybills-in-transfer-shipment/manage-waybills-in-transfer-shipment'
import Shipment from './v3/line-haul/shipment/shipment'
import ShortcodeNotes from './v3/line-haul/shortcode-notes/shortcode-notes'
import Shortcodes from './v3/line-haul/shortcodes/shortcodes'
import Transport from './v3/line-haul/transport/transport'
import PlusMinus from './v3/plus-minus/plus-minus'
import RejectPreset from './v3/screening/reject-preset/reject-preset'
import ToggleActions from './v3/screening/toggle-actions/toggle-actions'
import SelectAllToggler from './v3/select-all-toggler/select-all-toggler'
import TableSorter from './v3/table-sorter/table-sorter'
import TrSelector from './v3/tr-selector/tr-selector'
import ToggleEta from './v3/waybill/toggle-eta/toggle-eta'
import InputCase from './common/input-case/input-case'
import InputFilter from './common/input-filter/input-filter'

// TODO: these components are either to be replaced or rewritten to stimulus
const COMPONENTS_MAP = {
  'v3/conditional_confirm/conditional_confirm.js': ConditionalConfirm, // used in swap bodies, new and show actions, 2x
  'v3/confirm-leave/confirm-leave-button/confirm-leave-button.js': ConfirmLeaveButton, // used through "confirm_leave" alias in many places
  'v3/duplicate/duplicate.js': Duplicate,
  'v3/line-haul/manage-waybills-in-transfer-shipment/manage-waybills-in-transfer-shipment.js':
    ManageWaybillsInTransferShipment, // used only in transfer shipments under line hauls
  'v3/line-haul/shipment/shipment.js': Shipment, // used only in line hauls
  'v3/line-haul/shortcode-notes/shortcode-notes.js': ShortcodeNotes, // used only in line hauls
  'v3/line-haul/shortcodes/shortcodes.js': Shortcodes, // used only in line hauls
  'v3/line-haul/transport/transport.js': Transport, // used only in line hauls
  'v3/plus-minus/plus-minus.js': PlusMinus, // used only in new customs declarations form
  'v3/screening/reject-preset/reject-preset.js': RejectPreset, // used only in customs screening page
  'v3/screening/toggle-actions/toggle-actions.js': ToggleActions, // used only in customs screening page
  'v3/select-all-toggler/select-all-toggler.js': SelectAllToggler,
  'v3/table-sorter/table-sorter.js': TableSorter,
  'v3/tr-selector/tr-selector.js': TrSelector, // used only in trucking client inventories
  'v3/waybill/toggle-eta/toggle-eta.js': ToggleEta,
  'common/input-case/input-case.js': InputCase, // used through "case" alias in many places
  'common/input-filter/input-filter.js': InputFilter // used through "filter" alias in many places
}

export default COMPONENTS_MAP
