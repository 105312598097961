function _interpolate(html, params) {
  return html.replace(/#{(.*?)}/g, (match, token) => params[token] || '')
}

export default function parseHtml(html, params = {}) {
  const htmlString = _interpolate(html, params)
  const placeholder = document.createElement('div')

  placeholder.insertAdjacentHTML('afterbegin', htmlString)

  return placeholder.firstElementChild
}
