import http from '../../../common/http/http'

function _createLoadingIcon() {
  const element = document.createElement('i')
  element.classList.add('fas')
  element.classList.add('fa-spinner')
  element.classList.add('fa-pulse')
  return element
}

function _showLoader(element) {
  const wrapper = element.parentNode
  const loadingIcon = _createLoadingIcon()
  const arrowIcon = wrapper.querySelector('i.fas.fa-angle-down')
  arrowIcon.style.display = 'none'
  wrapper.appendChild(loadingIcon)
  element.disabled = true
}

function _hideLoader(element) {
  const wrapper = element.parentNode
  const loadingIcon = wrapper.querySelector('i.fas.fa-spinner')
  const arrowIcon = wrapper.querySelector('i.fas.fa-angle-down')
  arrowIcon.style.display = 'block'
  wrapper.removeChild(loadingIcon)
  element.disabled = false
}

export default function Shortcodes({ targets, props }) {
  const { base } = targets
  const { selected, type, shortcodeUrl } = props

  function _prepareOptions(options) {
    const index = options.indexOf(selected)

    base.options.length = 0
    options.forEach(value => {
      base.options.add(new Option(value, value))
    })

    if (index !== -1) {
      base.selectedIndex = index
    }
  }

  function init() {
    _showLoader(base)

    http({ url: shortcodeUrl, params: { type } })
      .then(options => {
        _prepareOptions(options)
      })
      .finally(() => {
        _hideLoader(base)
      })
  }

  return {
    init
  }
}
