const ROOT_CLS = 'via-portal'

export function portal(node) {
  init()

  function getRoot(body) {
    let root = body.querySelector(`.${ROOT_CLS}`)
    if (root) {
      return root
    }

    root = document.createElement('div')
    root.classList.add(ROOT_CLS)
    body.appendChild(root)

    return root
  }

  function init() {
    const body = document.querySelector('body')
    const root = getRoot(body)
    root.classList.add(ROOT_CLS)
    body.appendChild(root)
    root.appendChild(node)
  }

  function destroy() {
    const parent = node.parentNode

    if (parent) {
      parent.removeChild(node)

      if (parent.parentNode) {
        parent.parentNode.removeChild(parent)
      }
    }
  }

  return {
    init,
    destroy
  }
}
