import { on, off } from '../../common/utils/utils'

const SELECTED_ROW_CLASS = 'selected'

function createHiddenCheckbox({ name, value, checked }) {
  const input = document.createElement('input')
  input.type = 'checkbox'
  input.name = name
  input.checked = checked ?? false
  input.value = value ?? '1'
  input.style = 'display: none;'
  return input
}

export default function TrSelector({ targets, props }) {
  const { base } = targets
  const { name, value, form: formId, checked } = props

  let input

  function clickHandler(e) {
    input.checked = !input.checked
    base.classList.toggle(SELECTED_ROW_CLASS)
  }

  function changeHandler() {
    base.classList.toggle(SELECTED_ROW_CLASS)
  }

  function init() {
    on(base, 'click', clickHandler)
    const form = document.getElementById(formId)
    if (!form) {
      throw new Error(`Could not find a form with id '${formId}'`)
    }

    input = createHiddenCheckbox({ name, value, checked })
    form.append(input)

    on(input, 'change', changeHandler)

    base.classList.add('selectable')

    if (checked) {
      base.classList.add(SELECTED_ROW_CLASS)
    }
  }

  function destroy() {
    off(base, 'click', clickHandler)
    if (input) {
      off(input, 'change', changeHandler)
      input.remove()
    }
  }
  return {
    init,
    destroy
  }
}
