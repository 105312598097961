import { on, off, Next } from '../../common/utils/utils'

const SHOW_CLS = 'menu-show'

export default function Menu({ targets }) {
  const { toggle, menu } = targets
  const next = Next()

  function _open() {
    menu.classList.add(SHOW_CLS)
    next.run(() => {
      on(document, 'click', _close)
    })
  }

  function _close() {
    menu.classList.remove(SHOW_CLS)
    off(document, 'click', _close)
  }

  function handleClick() {
    if (menu.classList.contains(SHOW_CLS)) {
      _close()
    } else {
      _open()
    }
  }

  function init() {
    on(toggle, 'click', handleClick)
    on(menu, 'mouseleave', _close)
  }

  function destroy() {
    next.clear()
    off(toggle, 'click', handleClick)
    off(menu, 'mouseleave', _close)
    off(document, 'click', _close)
  }

  return {
    init,
    destroy
  }
}
