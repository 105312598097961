// Entry point for the build script in your package.json
import { Application } from '@hotwired/stimulus'

import jsControllers from './**/*_controller.js'
import tsControllers from './**/*_controller.ts'
import componentsJsControllers from '../../../components/**/*_controller.js'
import componentsTsControllers from '../../../components/**/*_controller.ts'

// Infer Stimulus controller names from its file paths
function genericControllerName(defaultName) {
  if (defaultName.startsWith('controllers--')) {
    // "controllers--available-deliveries--select" -> "available-deliveries--select"
    return defaultName.replace('controllers--', '')
  } else {
    // "line-haul--additional-parcels-or-waybills" -> "line-haul--additional-parcels-or-waybills"
    return defaultName
  }
}

function componentControllerName(defaultName) {
  // "..--..--..--components--select--container--list--item-checkbox-component--item-checkbox" ->
  // "select--container--list--item-checkbox-component--item-checkbox"
  const parts = defaultName.split('--')
  const index = parts.indexOf('components')
  return parts.slice(index + 1).join('--')
}

export default {
  start() {
    const genericControllers = [...jsControllers, ...tsControllers]
    const componentControllers = [...componentsJsControllers, ...componentsTsControllers]

    const application = Application.start()
    window.Stimulus = application

    genericControllers.forEach(controller => {
      application.register(genericControllerName(controller.name), controller.module.default)
    })

    componentControllers.forEach(controller => {
      application.register(componentControllerName(controller.name), controller.module.default)
    })
  }
}
