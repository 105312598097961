import { Controller } from '@hotwired/stimulus'
import type {
  Detail,
  ItemParamsDetail,
  ItemParams,
  FocusDetail,
  SearchDetail,
  CheckedDetail
} from '../../../types'
import { broadcastEvent } from '../../../../../frontend/src/common/dispatch-event/dispatch-event'
import toggleClass from '../../../../../frontend/src/common/toggle-class/toggle-class'

const CSS_PREFIX = 'select__container-list-item'

export default class extends Controller {
  static targets = ['button', 'checkbox']

  declare readonly buttonTarget: HTMLButtonElement
  declare readonly checkboxTarget: HTMLElement
  declare readonly hasCheckboxTarget: boolean

  static values = {
    label: String,
    value: String,
    index: { type: Number, default: 0 },
    selected: Boolean,
    focused: Boolean,
    hidden: Boolean
  }

  declare labelValue: string
  declare valueValue: string
  declare indexValue: number
  declare selectedValue: boolean
  declare focusedValue: boolean
  declare hiddenValue: boolean

  selectedValueChanged(selected: boolean): void {
    toggleClass({
      element: this.element,
      className: `${CSS_PREFIX}--selected`,
      predicate: selected
    })
  }

  focusedValueChanged(focused: boolean): void {
    toggleClass({
      element: this.element,
      className: `${CSS_PREFIX}--focused`,
      predicate: focused
    })
  }

  hiddenValueChanged(hidden: boolean): void {
    toggleClass({
      element: this.element,
      className: `${CSS_PREFIX}--hidden`,
      predicate: hidden
    })
  }

  handleSelect({
    detail: { params, checked }
  }: Detail<{ params: ItemParams; checked: boolean }>): void {
    if (this.hasCheckboxTarget && params.value !== this.valueValue) {
      return
    }

    this.selectedValue = this.hasCheckboxTarget ? checked : params.value === this.valueValue
    if (this.hasCheckboxTarget) {
      this.focusedValue = false
      broadcastEvent<CheckedDetail>(this.checkboxTarget, 'itemSelect', { checked })
    } else {
      this.focusedValue = this.selectedValue
    }
  }

  handleExternalUnselect({ detail: { params } }: Detail<ItemParamsDetail>): void {
    if (params.value !== this.valueValue) {
      return
    }
    this.selectedValue = false
    broadcastEvent(this.checkboxTarget, 'unselect', {})
  }

  handleToggleAll({ detail: { checked } }: Detail<CheckedDetail>): void {
    this.selectedValue = checked && !this.hiddenValue
    broadcastEvent<CheckedDetail>(this.checkboxTarget, 'itemSelect', {
      checked: this.selectedValue
    })
  }

  handleFocus({ detail: { index } }: Detail<FocusDetail>): void {
    this.focusedValue = index === this.indexValue
  }

  handleSearch({ detail: { regex } }: Detail<SearchDetail>): void {
    if (regex !== null) {
      this.hiddenValue = !regex.test(this.labelValue)
    } else {
      this.hiddenValue = false
    }
  }

  handleEnable(): void {
    if (this.hasCheckboxTarget) {
      broadcastEvent(this.checkboxTarget, 'enable', {})
    } else {
      this.buttonTarget.disabled = false
    }
  }
}
