import { Controller } from '@hotwired/stimulus'
import { debounce } from 'lodash'
import { Turbo } from '@hotwired/turbo-rails'

const MIN_INPUT_LENGTH_FOR_SEARCH = 3

export default class extends Controller {
  static targets = ['root', 'form', 'input', 'result', 'resultLink']

  initialize() {
    this.triggerFormSubmit = debounce(this.triggerFormSubmit, 500).bind(this)
  }

  connect() {
    document.addEventListener('keydown', this.handlePaste.bind(this))
  }

  disconnect() {
    document.removeEventListener('keydown', this.handlePaste.bind(this))
  }

  handlePaste(e) {
    const shouldContinue = e.key === 'v' && (e.metaKey || e.ctrlKey) && e.target === document.body
    if (shouldContinue) {
      this.inputTarget.value = ''
      this.inputTarget.focus()
    }
  }

  search() {
    this.sanitizeInput()

    if (this.inputTarget.value.length >= MIN_INPUT_LENGTH_FOR_SEARCH) {
      this.triggerFormSubmit()
    } else {
      this.resultTarget.innerHTML = ''
    }
  }

  triggerFormSubmit() {
    if (this.inputTarget.value.length >= MIN_INPUT_LENGTH_FOR_SEARCH) {
      this.formTarget.requestSubmit()
    }
  }

  input(e) {
    if (e.key === 'ArrowUp') {
      e.preventDefault()
      this.selectNextItem()
    } else if (e.key === 'ArrowDown') {
      e.preventDefault()
      this.selectPreviousItem()
    } else if (e.key === 'Enter') {
      e.preventDefault()
      this.visitSelected()
    }
  }

  sanitizeInput() {
    let value = this.inputTarget.value

    value = value.toUpperCase()
    value = value.replaceAll(' ', '')

    this.inputTarget.value = value
  }

  selectNextItem() {
    if (this.hasResultLinkTarget) {
      const selectedLink =
        this.resultLinkTargets.filter(resultLink => resultLink.classList.contains('selected'))[0] ||
        this.resultLinkTargets[0]
      const indexOfSelectedLink = this.resultLinkTargets.indexOf(selectedLink)

      const newSelectedIndex = this.wrapIndex(
        indexOfSelectedLink - 1,
        this.resultLinkTargets.length
      )

      this.resultLinkTargets.forEach(resultLink => {
        resultLink.classList.remove('selected')
      })

      this.resultLinkTargets[newSelectedIndex].classList.add('selected')
    }
  }

  selectPreviousItem() {
    if (this.hasResultLinkTarget) {
      const selectedLink =
        this.resultLinkTargets.filter(resultLink => resultLink.classList.contains('selected'))[0] ||
        this.resultLinkTargets[0]
      const indexOfSelectedLink = this.resultLinkTargets.indexOf(selectedLink)

      const newSelectedIndex = this.wrapIndex(
        indexOfSelectedLink + 1,
        this.resultLinkTargets.length
      )

      this.resultLinkTargets.forEach(resultLink => {
        resultLink.classList.remove('selected')
      })

      this.resultLinkTargets[newSelectedIndex].classList.add('selected')
    }
  }

  visitSelected() {
    if (this.hasResultLinkTarget) {
      const selectedLink = this.resultLinkTargets.filter(resultLink =>
        resultLink.classList.contains('selected')
      )[0]

      if (selectedLink) {
        Turbo.visit(selectedLink.href)
      }
    }
  }

  expandMobileView() {
    this.rootTarget.classList.add('expanded')
    this.inputTarget.focus()
  }

  collapseMobileView() {
    this.rootTarget.classList.remove('expanded')
    this.inputTarget.value = ''
  }

  wrapIndex(index, maximumIndex) {
    return ((index % maximumIndex) + maximumIndex) % maximumIndex
  }
}
