export const SCOPE_JS = 'js'

const _instances = {
  [SCOPE_JS]: {}
}

export function get({ scope, uid }) {
  return _instances[scope][uid] || null
}

export function set({ scope, uid, instance }) {
  _instances[scope][uid] = instance
}

export function remove({ scope, uid }) {
  delete _instances[scope][uid]
}

export function all(scope) {
  return Object.keys(_instances[scope])
}

export function clear() {
  _instances[SCOPE_JS] = {}
}
