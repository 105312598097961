import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    clipboard: String,
    selector: String
  }

  declare clipboardValue: string
  declare selectorValue: string

  static targets = ['root']

  declare readonly rootTarget: HTMLElement

  copyValue(e: Event): void {
    e.preventDefault()
    e.stopPropagation()

    const reference = this.selectorValue !== '' ? document.querySelector(this.selectorValue) : null
    const text = reference !== null ? (reference as HTMLInputElement).value : this.clipboardValue

    void navigator.clipboard.writeText(text).then(() => {
      this.rootTarget.classList.add('copied')
    })
  }

  mouseOut(): void {
    this.rootTarget.classList.remove('copied')
  }
}
