import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['modal']

  connect() {
    this.isGetRequest = false
  }

  hideModal() {
    this.element.parentElement.removeAttribute('src')
    this.modalTarget.remove()
  }

  submitStart(e) {
    this.isGetRequest = e.detail.formSubmission.fetchRequest.method === 'get'
  }

  submitEnd(e) {
    if (this.isGetRequest) {
      return
    }

    if (e.detail.success) {
      this.hideModal()
    }
  }

  closeWithKeyboard(f) {
    if (f.code === 'Escape') {
      this.hideModal()
    }
  }

  closeBackground(e) {
    if (e && this.modalTarget.contains(e.target)) {
      return
    }
    this.hideModal()
  }
}
