import on from './on'
import off from './off'

function _handle(childSelector, handle) {
  return e => {
    const target = e.target
    const hasClosest = typeof target.closest === 'function'
    const isTarget = childSelector && hasClosest && target.closest(childSelector) === target
    if (!childSelector || isTarget) {
      handle(e)
    }
  }
}

export default function delegate(element, childSelector, eventName, handle, useCapture) {
  const handler = _handle(childSelector, handle)
  on(element, eventName, handler, useCapture)
  return () => {
    off(element, eventName, handler, useCapture)
  }
}
