import { on, off } from '../../common/utils/utils'

export default function SelectAllToggler({ targets, props }) {
  const { base } = targets

  let form

  function clickHandler(e) {
    const target = e.target
    const inputs = form.querySelectorAll('input[type="checkbox"]')

    if (target.tagName === 'INPUT' && target.type === 'checkbox') {
      inputs.forEach(input => {
        input.checked = target.checked
      })
    } else {
      e.preventDefault()
      inputs.forEach(input => {
        if (!input.checked) {
          input.click()
        }
      })
    }
  }

  function init() {
    form = base.closest('form')
    if (!form) {
      throw new Error('SelectAllToggler can only be used inside a form')
    }

    on(base, 'click', clickHandler)
  }

  function destroy() {
    off(base, 'click', clickHandler)
  }

  return {
    init,
    destroy
  }
}
