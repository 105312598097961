import { maxBy, times, toArray } from 'lodash'

export const COLUMNS_SELECTOR = 'thead td, thead th'
const DEFAULT_BODY_SELECTOR = 'tbody'
const BODY_SELECTOR = '[data-sort-body]'

export function extractText(element) {
  return element.textContent.trim()
}

export function getValue(element) {
  const text = element.dataset.sortValue || extractText(element)

  return !isNaN(text) ? parseFloat(text) : text
}

export function getHeader(table) {
  return toArray(table.querySelectorAll(COLUMNS_SELECTOR)).map(column => ({
    column,
    key: extractText(column),
    normalize: typeof column.dataset.sortNormalize !== 'undefined'
  }))
}

export function getBody(table) {
  return table.querySelector(BODY_SELECTOR) || table.querySelector(DEFAULT_BODY_SELECTOR)
}

/*
  We can't use padStart from lodash because it truncates the zeros depending on the string length.
  In this way the sorting is more precise when column values are padded to reach uniform value size.
 */
export function padLeft(value, length) {
  return times(length, String).reduce(acc => `0${acc}`, value)
}

function _normalizeMap(header, collection) {
  return header.reduce((acc, { key, normalize }, index) => {
    const maxSize = normalize
      ? maxBy(collection, ({ values }) => ('' + values[index]).length)
      : null
    return maxSize ? { ...acc, [index]: maxSize.values[index].length } : acc
  }, {})
}

export function getRawCollection(header, body) {
  const rows = toArray(body.querySelectorAll('tr'))
  return rows.map(element => ({
    element,
    values: toArray(element.querySelectorAll('td')).map(getValue)
  }))
}

export function getNormalizedCollection(header, body) {
  const collection = getRawCollection(header, body)
  const normalizeMap = _normalizeMap(header, collection)
  return collection.map(({ element, values }) => {
    return {
      element,
      values: values.map((value, index) =>
        normalizeMap[index] ? padLeft(value, normalizeMap[index] - `${value}`.length) : value
      )
    }
  })
}
