import { on, off, isDescendant } from '../utils/utils'
import { getKey, KEY_ESC } from '../keys/keys'

export function exit(node, options = { manual: false, inner: null }) {
  if (!options.manual) {
    init()
  }

  // TODO to be remove after select rewrite
  if (options.inner) {
    options.inner.set('initExit', init)
    options.inner.set('destroyExit', destroy)
  }

  function handleKeyUp(e) {
    if (getKey(e) === KEY_ESC) {
      node.dispatchEvent(new CustomEvent('exit'))
    }
  }

  function handleClick(e) {
    const target = e.target
    if (!isDescendant(node, target)) {
      node.dispatchEvent(new CustomEvent('exit', { detail: { target } }))
    }
  }

  function init() {
    on(document, 'keyup', handleKeyUp)
    if (options.manual) {
      on(document, 'click', handleClick)
    }
  }

  function destroy() {
    off(document, 'keyup', handleKeyUp)
    off(document, 'click', handleClick)
  }

  return {
    init,
    destroy
  }
}
