import parseHtml from '../../common/utils/parse-html'

const ICON_CLS = 'icon'
const UP_CLS = 'fa-caret-up'
const DOWN_CLS = 'fa-caret-down'

function _getIcon(parent) {
  return parent.querySelector(`.${ICON_CLS}`)
}

function _renderIcon() {
  const iconHtml = `<i class="${ICON_CLS} fas fa-fw"></i>`
  return parseHtml(iconHtml)
}

export function toggleIcon(parent, dir) {
  let icon = _getIcon(parent)
  if (!icon) {
    icon = _renderIcon()
    parent.appendChild(icon)
  }

  const newCls = dir === 'up' ? UP_CLS : DOWN_CLS
  const oldCls = newCls === UP_CLS ? DOWN_CLS : UP_CLS

  icon.classList.add(newCls)
  icon.classList.remove(oldCls)
}

export function removeIcon(parent) {
  const icon = _getIcon(parent)
  if (!icon) {
    return
  }
  parent.removeChild(icon)
}
