import { on, off, getTargetHookSelector } from '../../../common/utils/utils'
import { toArray } from 'lodash'

export const EVENTS = {
  selectTop: 'select-top',
  selectTopClear: 'select-top:clear',
  refreshParcels: 'refresh-parcels'
}

const SELECTORS = {
  ehub: getTargetHookSelector('shipmentEhub'),
  shortcode: getTargetHookSelector('shipmentShortcode'),
  service: getTargetHookSelector('shipment-deliveries-service'),
  table: '#available_parcel_list tbody',
  rows: '#available_parcel_list tbody tr',
  checkboxes: '#available_parcel_list tbody input'
}

export const Elements = {
  ehub() {
    return (document.querySelector(SELECTORS.ehub) || {}).value
  },
  shortcode() {
    return (document.querySelector(SELECTORS.shortcode) || {}).value
  },
  service() {
    return (document.querySelector(SELECTORS.service) || {}).value
  },
  rows() {
    return toArray(document.querySelectorAll(SELECTORS.rows))
  },
  checkboxes() {
    return toArray(document.querySelectorAll(SELECTORS.checkboxes))
  }
}

export function dispatchEvent(event, detail = {}) {
  document.dispatchEvent(new CustomEvent(event, { detail }))
}

export function shared(node) {
  function handleSelectTopUpdate() {
    node.dispatchEvent(new CustomEvent('clear', node))
  }

  on(document, EVENTS.selectTopClear, handleSelectTopUpdate)

  return {
    destroy() {
      off(document, EVENTS.selectTopClear, handleSelectTopUpdate)
    }
  }
}
