export default function extendToFullscreen(selector) {
  const container = document.querySelector(selector)
  if (container.requestFullscreen) {
    container.requestFullscreen()
  } else if (container.webkitRequestFullscreen) {
    container.webkitRequestFullscreen()
  } else if (container.mozRequestFullScreen) {
    container.mozRequestFullScreen()
  } else if (container.msRequestFullscreen) {
    container.msRequestFullscreen()
  }
}
