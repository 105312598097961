import { Controller } from '@hotwired/stimulus'
import handleRedirect from './utils/handle-redirect'

export default class extends Controller {
  static values = {
    href: String
  }

  declare hrefValue: string

  handleClick(e: MouseEvent): void {
    handleRedirect(e, this.hrefValue)
  }
}
