import { on, off } from '../../../common/utils/utils'

export default function RejectPreset({ targets }) {
  const { base, textarea } = targets

  function handleClick() {
    const value = textarea.value
    const prepend = value ? `${value}\n` : ''
    targets.textarea.value = `${prepend}${base.textContent}`
    textarea.focus()
    textarea.dispatchEvent(new Event('input'))
  }

  function init() {
    on(base, 'click', handleClick)
  }

  function destroy() {
    off(base, 'click', handleClick)
  }
  return {
    init,
    destroy
  }
}
