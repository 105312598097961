import getUrlSearchParams from '../../frontend/src/common/utils/get-url-search-params'

export function buildQueryString(name: string, checked: boolean): string {
  const params: Record<string, string> = getUrlSearchParams()
  params[name] = checked ? 'true' : 'false'

  return Object.keys(params)
    .map(key => `${key}=${params[key]}`)
    .join('&')
}

export function redirect(queryString: string): void {
  window.location.href = `${window.location.pathname}?${queryString}`
}

export function toggleQueryString(name: string, checked: boolean): void {
  const queryString = buildQueryString(name, checked)

  redirect(queryString)
}
