import { Controller } from '@hotwired/stimulus'

export default class extends Controller<HTMLElement> {
  connect(): void {
    const form = this.element.closest('form')

    this.element.dataset.manualSubmit = 'true'
    if (form !== null) {
      form.dataset.turbo = 'true'
    }
  }
}
