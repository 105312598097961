type Target = HTMLAnchorElement | HTMLFrameElement

function getUrlAttr(target: Target, defaultBaseUrl?: string): string {
  let value = ''
  switch (target.tagName) {
    case 'A':
      value = (target as HTMLAnchorElement).href
      break
    case 'TURBO-FRAME':
      value = (target as HTMLFrameElement).src
      break
  }
  return value ?? defaultBaseUrl ?? ''
}

function setUrlAttr(target: Target, url: string): void {
  switch (target.tagName) {
    case 'A':
      ;(target as HTMLAnchorElement).href = url
      return
    case 'TURBO-FRAME':
      ;(target as HTMLFrameElement).src = url
  }
}

export default function updateUrl(target: Target, params: string[], defaultBaseUrl?: string): void {
  const currentUrl = getUrlAttr(target, defaultBaseUrl)
  const baseUrl = currentUrl !== '' ? currentUrl.split('?')[0] : ''
  const noParams = params.every(param => param === '')
  const newUrl = baseUrl + (noParams ? '' : '?' + params.join('&'))

  setUrlAttr(target, newUrl)
}
