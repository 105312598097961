import { Controller } from '@hotwired/stimulus'
import { onSelectChange } from '../select/select-utils'
import type { Detail, SelectChangeDetail } from '../select/types'
import { getUrl } from './go_to_utils'

export default class extends Controller {
  static values = {
    url: String,
    keys: Array,
    scope: Number
  }

  declare urlValue: string
  declare keysValue: string[]
  declare scopeValue: number

  offSelectChange = (): void => {}
  selected: Record<string, string[]> = {}

  connect(): void {
    this.offSelectChange = onSelectChange({
      scope: this.scopeValue,
      keys: this.keysValue,
      handle: this.handleSelect.bind(this)
    })
  }

  disconnect(): void {
    this.offSelectChange()
  }

  handleSelect({ detail: { params } }: Detail<SelectChangeDetail>): void {
    this.selected = { ...this.selected, ...params }
  }

  handleClick(): void {
    const url = getUrl(this.urlValue, this.selected)

    if (url !== null) {
      window.open(url, '_blank')
    }
  }
}
