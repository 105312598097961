import prefix from './prefix'

export default function off(element, name, handle, useCapture = false) {
  if (!element || !name || !handle) {
    return
  }
  prefix(name).forEach(eventName => {
    element.removeEventListener(eventName, handle, useCapture)
  })
}
