import { toArray } from 'lodash'

function _isDestroyOrId(element) {
  return /\[(id|_destroy)]$/.test(element.name)
}

function _omitHiddenInputs(row) {
  toArray(row.querySelectorAll('[type="hidden"]')).forEach(hidden => {
    if (_isDestroyOrId(hidden)) {
      hidden.parentNode.removeChild(hidden)
    }
  })
}

function _updateDatasetAttribute({ target, attr, index, isStimulusSelect }) {
  const value = target.dataset[attr]

  if (value) {
    if (attr === 'name') {
      target.dataset[attr] = value.replace(/\[\d+]/g, `[${index}]`)
    }
    if (attr === 'notifyKey') {
      target.dataset[attr] = value.replace(/^\d+./g, `${index}.`)
    }
    if (attr === 'urlParams') {
      target.dataset[attr] = value.replace(/"\d+./g, `"${index}.`)
    }
    if (isStimulusSelect && attr === 'selectComponent-SelectScopeValue') {
      target.dataset[attr] = value.replace(/\d+/g, `${index}`)
    }
    if (isStimulusSelect && attr === 'selectComponent-SelectFrameIdValue') {
      target.dataset[attr] = value.replace(/^\d+__/g, `${index}__`)
    }
  }
}

function _updateAttribute({ target, attr, index }) {
  const value = target.getAttribute(attr)

  if (value) {
    if (attr === 'for' || attr === 'id') {
      target.setAttribute(attr, value.replace(/_\d+_/g, `_${index}_`))
    }
    if (attr === 'name') {
      target.setAttribute(attr, value.replace(/\[\d+]/g, `[${index}]`))
    }
  }
}

function _updateTurboFrameId({ target, index }) {
  const value = target.getAttribute('id')

  if (value) {
    target.setAttribute('id', value.replace(/\d+__/g, `${index}__`))
  }
}

function _getDisabled(dataset, previousValue) {
  return dataset.forceEnabledOnClone === 'true'
    ? false
    : dataset.forceDisabledOnClone === 'true' || previousValue
}

function _getValue(dataset, previousValue) {
  return dataset.defaultOnClone
    ? dataset.defaultOnClone
    : dataset.keepValueOnClone === 'true'
      ? previousValue
      : ''
}

export default function clone(source) {
  const cloneRow = source.cloneNode(true)
  const index = Date.now()

  const labels = toArray(cloneRow.querySelectorAll('label'))
  const fields = [
    ...toArray(cloneRow.querySelectorAll('input')),
    ...toArray(cloneRow.querySelectorAll('select')),
    ...toArray(cloneRow.querySelectorAll('textarea')),
    ...toArray(cloneRow.querySelectorAll('[data-controller="select-component--select"]')),
    ...toArray(
      cloneRow.querySelectorAll('[data-controller="select-component--select"] turbo-frame')
    )
  ]

  labels.forEach(label => {
    _updateAttribute({ target: label, attr: 'for', index })
  })

  fields.forEach(field => {
    const dataset = field.dataset || {}
    const isStimulusSelectRoot =
      field.dataset && field.dataset.controller === 'select-component--select'
    const isStimulusSelectTurboFrame = field.tagName === 'TURBO-FRAME'

    _updateAttribute({ target: field, attr: 'name', index })
    _updateAttribute({ target: field, attr: 'id', index })
    if (isStimulusSelectRoot) {
      _updateDatasetAttribute({
        target: field,
        attr: 'selectComponent-SelectScopeValue',
        index,
        isStimulusSelect: true
      })
      _updateDatasetAttribute({
        target: field,
        attr: 'selectComponent-SelectFrameIdValue',
        index,
        isStimulusSelect: true
      })
    }
    if (isStimulusSelectTurboFrame) {
      _updateTurboFrameId({ target: field, index })
    }

    if (isStimulusSelectRoot) {
      delete field.dataset['selectComponent-SelectSelectedValue']
      // it will enable itself automatically if forceEnabled is set
      field.dataset['selectComponent-SelectForceEnabledValue'] =
        field.dataset['selectComponent-SelectForceEnabledOnCloneValue']
    } else {
      field.disabled = _getDisabled(dataset, field.disabled)
      field.value = _getValue(dataset, field.value)
    }
  })

  _omitHiddenInputs(cloneRow)

  return cloneRow
}
