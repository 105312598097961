import { Controller } from '@hotwired/stimulus'

export default class extends Controller<HTMLAnchorElement> {
  static values = {
    url: String
  }

  declare urlValue: string

  connect(): void {
    const printFrame = document.createElement('iframe')

    printFrame.style.display = 'none'
    printFrame.src = this.urlValue

    printFrame.onload = () => {
      if (printFrame.contentWindow !== null) {
        printFrame.contentWindow.print()
      }
    }

    document.body.appendChild(printFrame)
  }
}
