import { on, off, replaceValueAndKeepCursor, Next } from '../utils/utils'
import InputFlash from '../input-flash/input-flash'

const PRINTABLE_ASCII_REGEX = '\x20-\x7E'
const LATIN_REGEX =
  'A-Za-z\xAA\xBA\xC0-\xD6\xD8-\xF6\xF8-\u02B8\u02E0-\u02E4\u1D00-\u1D25\u1D2C-\u1D5C\n\u1D62-\u1D65\u1D6B-\u1D77\u1D79-\u1DBE\u1E00-\u1EFF\u2071\u207F\u2090-\u209C\n\u212A\u212B\u2132\u214E\u2160-\u2188\u2C60-\u2C7F\uA722-\uA787\uA78B-\uA7AE\n\uA7B0-\uA7B7\uA7F7-\uA7FF\uAB30-\uAB5A\uAB5C-\uAB64\uFB00-\uFB06\uFF21-\uFF3A\uFF41-\uFF5A'

function _getRegex(match) {
  const regexStr = match
    .replace(/\\p{printableascii}/i, PRINTABLE_ASCII_REGEX)
    .replace(/\\p{latin}/i, LATIN_REGEX)
  return RegExp(`${regexStr}`, 'g')
}

function _inputHandle({ target, regex, next, flash }) {
  next(() => {
    const value = target.value
    if (regex.test(value)) {
      flash()
    }
    return replaceValueAndKeepCursor(target, value.replace(regex, ''))
  })
}

export default function InputFilter({ targets, props }) {
  const { base } = targets
  const { match } = props
  const regex = _getRegex(match)
  const next = Next()
  const flashInstance = InputFlash(base)

  function applyInputHandle() {
    _inputHandle({ target: base, regex, next: next.run, flash: flashInstance.flash })
  }

  function init() {
    on(base, 'input', applyInputHandle)
  }

  function destroy() {
    next.clear()
    flashInstance.destroy()
    off(base, 'input', applyInputHandle)
  }

  return {
    init,
    destroy
  }
}
