import prefix from './prefix'

export default function on(element, name, handle, useCapture = false) {
  if (!element || !name || !handle) {
    return
  }
  prefix(name).forEach(eventName => {
    element.addEventListener(eventName, handle, useCapture)
  })
}
