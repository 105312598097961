import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'

export default class extends Controller {
  static targets = ['select']
  static values = {
    url: String,
    param: String
  }

  connect() {
    if (this.selectTarget.id === '') {
      this.selectTarget.id = Math.random().toString(36)
    }
  }

  change(event) {
    get(this.urlValue, {
      query: {
        [this.paramValue]: event.target.selectedOptions[0].value,
        target: this.selectTarget.id
      },
      responseKind: 'turbo-stream'
    })
  }
}
