import { Controller } from '@hotwired/stimulus'
import getAlignment from '../../../frontend/src/common/utils/get-alignment'
import type { Detail, OverflowUtils, ToggleDetail } from '../../select/types'
import type { ParamsToAddDetail, Params } from '../types'

const CSS_ROOT_PREFIX = 'tags__container'
const CSS_CONTAINER_PREFIX = 'tags__rest'
const CSS_LIST_PREFIX = `${CSS_CONTAINER_PREFIX}-list`

export default class extends Controller {
  static targets = ['list', 'count', 'tag', 'container']

  declare readonly listTarget: HTMLElement
  declare readonly countTarget: HTMLElement
  declare readonly tagTargets: HTMLElement[]
  declare readonly containerTarget: HTMLElement

  static values = {
    listSize: Number,
    expanded: Boolean,
    disabled: Boolean
  }

  declare listSizeValue: number
  declare expandedValue: boolean
  declare disabledValue: boolean

  setOverflow = (): void => {}
  removeOverflow = (): void => {}

  listSizeValueChanged(): void {
    if (this.listSizeValue > 0) {
      this.countTarget.innerText = `+ ${this.listSizeValue} more`
      this.element.classList.remove(`${CSS_ROOT_PREFIX}--hide`)
    } else {
      this.element.classList.add(`${CSS_ROOT_PREFIX}--hide`)
      this.countTarget.innerText = ''
      this.handleHide()
    }
  }

  align(): void {
    const align = getAlignment(this.listTarget)

    if (align === 'right') {
      this.listTarget.classList.add(`${CSS_LIST_PREFIX}--right`)
      this.listTarget.classList.remove(`${CSS_LIST_PREFIX}--left`)
    } else {
      this.alignReset()
    }
  }

  alignReset(): void {
    this.listTarget.classList.remove(`${CSS_LIST_PREFIX}--right`)
    this.listTarget.classList.add(`${CSS_LIST_PREFIX}--left`)
  }

  handleOverflowInit({ detail: { setOverflow, removeOverflow } }: Detail<OverflowUtils>): void {
    this.setOverflow = setOverflow
    this.removeOverflow = removeOverflow
  }

  handleShow(): void {
    if (this.expandedValue) {
      return
    }
    this.setOverflow()
    this.containerTarget.classList.add(`${CSS_CONTAINER_PREFIX}--open`)
    this.align()
  }

  handleHide(): void {
    if (this.expandedValue) {
      return
    }
    this.removeOverflow()
    this.containerTarget.classList.remove(`${CSS_CONTAINER_PREFIX}--open`)
    this.alignReset()
  }

  handleClick(e: Event): void {
    e.stopPropagation()
  }

  handleToggle({ detail: { expanded } }: Detail<ToggleDetail>): void {
    this.expandedValue = expanded
  }

  handleRemove({ detail: { isRest } }: Detail<{ isRest: boolean }>): void {
    const target = this.tagTargets[0]

    this.listSizeValue = this.tagTargets.length - (isRest ? 0 : 1)

    if (!isRest && target !== undefined && this.element.parentNode !== null) {
      delete target.dataset['tags-TagComponent-TagIsRestValue']
      this.element.parentNode.insertBefore(target, this.element)
    }
  }

  handleRemoveAll(): void {
    this.listSizeValue = 0
  }

  add(params: Params, templateTarget: HTMLElement, isRest: boolean): void {
    const target = templateTarget.cloneNode(true) as HTMLElement

    target.dataset['tags-TagComponent-TagLabelValue'] = params.label
    target.dataset['tags-TagComponent-TagValueValue'] = params.value
    target.dataset['tags-RestComponent-RestTarget'] = 'tag'
    target.dataset['tagsComponent-TagsTarget'] = 'tag'

    if (this.disabledValue) {
      target.dataset['tags-TagComponent-TagDisabledValue'] = ''
    }

    if (isRest) {
      target.dataset['tags-TagComponent-TagIsRestValue'] = ''
      this.listTarget.appendChild(target)
    } else if (this.element.parentNode !== null) {
      this.element.parentNode.insertBefore(target, this.element)
    }
  }

  handleAdd({ detail: { params, totalSize, templateTarget } }: Detail<ParamsToAddDetail>): void {
    const isRest = totalSize > 2

    this.add(params, templateTarget, isRest)

    if (isRest) {
      this.listSizeValue = this.tagTargets.length
    }
  }

  handleAddAll({
    detail: { selected, templateTarget }
  }: Detail<{ selected: Params[]; templateTarget: HTMLElement }>): void {
    this.listSizeValue = selected.length - 2

    selected.forEach((params, index) => {
      this.add(params, templateTarget, index > 1)
    })
  }

  handleEnable(): void {
    this.disabledValue = false
  }
}
