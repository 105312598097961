import { Controller } from '@hotwired/stimulus'
import { EVENTS, Elements } from '../../frontend/src/v3/line-haul/shared/shared'
import { debounce } from 'lodash'
import { off, on } from '../../frontend/src/common/utils/utils'

export default class extends Controller<HTMLInputElement> {
  lazyInputHandle = debounce(this.handleInput.bind(this), 200)

  handleSelectTopClearRef: () => void = () => {}

  connect(): void {
    this.handleSelectTopClearRef = this.handleSelectTopClear.bind(this)
    on(document, EVENTS.selectTopClear, this.handleSelectTopClearRef)
  }

  disconnect(): void {
    off(document, EVENTS.selectTopClear, this.handleSelectTopClearRef)
  }

  handleInput(): void {
    const limit = parseInt(this.element.value, 10)

    if (limit === 0 || isNaN(limit)) {
      return
    }

    Elements.checkboxes().forEach((checkbox, index) => {
      ;(checkbox as HTMLInputElement).checked = index < limit
    })

    document.dispatchEvent(new CustomEvent(EVENTS.selectTop, { detail: limit }))
  }

  handleSelectTopClear(): void {
    this.element.value = ''
  }
}
