import { Controller } from '@hotwired/stimulus'
import {
  broadcastEvent,
  emitEvent
} from '../../../frontend/src/common/dispatch-event/dispatch-event'

export default class extends Controller {
  static values = {
    index: String
  }

  declare indexValue: string

  handleNo(): void {
    broadcastEvent(window, `transit${this.indexValue}:revert`, {})
    emitEvent(this.element, 'turbo-modal:hide', {})
  }
}
