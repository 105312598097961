import EnableEligibleTransportSelect from './enable-eligible-transport-select/enable-eligible-transport-select'
import EligibleTransportsDeliveryWindowCalculator from './eligible-transports-delivery-window-calculator/eligible-transports-delivery-window-calculator'

export default function Transport({ targets, props }) {
  const { base, equipment, forwardersLink, plannedPickupAt, plannedDeliveryAt } = targets
  const { info, parentSelector } = props
  let enableEligibleTransportSelectInstance
  let eligibleTransportsDeliveryWindowCalculatorInstance
  const rates = {}
  const deliveryWindows = {}

  function _parseInfo() {
    return JSON.parse(info).forEach(([id, rate, deliveryWindow]) => {
      rates[id] = rate
      deliveryWindows[id] = deliveryWindow
    })
  }

  function init() {
    _parseInfo()
    enableEligibleTransportSelectInstance = EnableEligibleTransportSelect({
      targets: {
        base: equipment,
        forwardersLink,
        parent: parentSelector ? base.closest(parentSelector) : base
      }
    })
    eligibleTransportsDeliveryWindowCalculatorInstance = EligibleTransportsDeliveryWindowCalculator(
      {
        targets: {
          equipment,
          plannedPickupAt,
          plannedDeliveryAt
        },
        props: {
          deliveryWindows
        }
      }
    )

    enableEligibleTransportSelectInstance.init()
    eligibleTransportsDeliveryWindowCalculatorInstance.init()
  }

  function destroy() {
    enableEligibleTransportSelectInstance.destroy()
    eligibleTransportsDeliveryWindowCalculatorInstance.destroy()
  }

  return {
    init,
    destroy
  }
}
