import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['caret', 'items']

  toggleExpanded(event) {
    event.preventDefault()

    if (this.hasItemsTarget) {
      if (this.caretTarget.classList.contains('expanded')) {
        this.caretTarget.classList.remove('expanded')
        this.itemsTarget.classList.remove('expanded')
      } else {
        this.caretTarget.classList.add('expanded')
        this.itemsTarget.classList.add('expanded')
      }
    }
  }
}
