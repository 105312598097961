import { Controller } from '@hotwired/stimulus'
import type { Detail, ToggleDetail, LoadingDetail } from '../types'
import { emitEvent } from '../../../frontend/src/common/dispatch-event/dispatch-event'
import toggleClass from '../../../frontend/src/common/toggle-class/toggle-class'

const CSS_PREFIX = 'select__input'

export default class extends Controller {
  static targets = ['icon', 'loading']

  declare readonly iconTarget: HTMLElement
  declare readonly loadingTarget: HTMLElement

  static values = {
    disabled: Boolean,
    expanded: { type: Boolean, default: false },
    loading: { type: Boolean, default: false }
  }

  declare disabledValue: boolean
  declare expandedValue: boolean
  declare loadingValue: boolean

  expandedValueChanged(): void {
    if (this.expandedValue) {
      this.expand()
    } else {
      this.collapse()
    }
  }

  loadingValueChanged(): void {
    if (this.loadingValue) {
      this.element.classList.add(`${CSS_PREFIX}--with-loading`)
      this.loadingTarget.classList.add(`${CSS_PREFIX}-loading--show`)
    } else {
      this.element.classList.remove(`${CSS_PREFIX}--with-loading`)
      this.loadingTarget.classList.remove(`${CSS_PREFIX}-loading--show`)
    }
  }

  disabledValueChanged(disabled: boolean): void {
    toggleClass({
      element: this.element,
      className: `${CSS_PREFIX}--disabled`,
      predicate: disabled
    })
  }

  handleClick(): void {
    if (this.disabledValue) {
      return
    }
    emitEvent(this.element, 'initToggle', {})
  }

  handleLoading({ detail: { loading } }: Detail<LoadingDetail>): void {
    this.loadingValue = loading
  }

  handleToggle({ detail: { expanded } }: Detail<ToggleDetail>): void {
    this.expandedValue = expanded
  }

  expand(): void {
    this.iconTarget.classList.add('fa-angle-up')
    this.iconTarget.classList.remove('fa-angle-down')
  }

  collapse(): void {
    this.iconTarget.classList.add('fa-angle-down')
    this.iconTarget.classList.remove('fa-angle-up')
  }

  handleEnable(): void {
    this.disabledValue = false
  }
}
