export const FIELDS = [
  'input[type=text]',
  'input[type=number]',
  'input[type=url]',
  'input[type=file]',
  'input[type=checkbox]',
  'input[type=radio]',
  'input[type=range]',
  'input[type=date]',
  'input[type=datetime-local]',
  'input[type=time]',
  'input[type=email]',
  'input[type=password]',
  'select'
]

export const BUTTONS = ['button', 'input[type=button]', 'input[type=submit]']
