import { isArray } from 'lodash'
import isValidJson from './is-valid-json'

export default function parseArray(array) {
  if (isArray(array)) {
    return [...array]
  } else if (array && isValidJson(array)) {
    return JSON.parse(array)
  } else {
    return null
  }
}
