/* eslint-disable */
class HTTPClient {
  httpGetAsync(url, callback) {
    let xmlHttp = new XMLHttpRequest()
    xmlHttp.onreadystatechange = function () {
      if (xmlHttp.readyState == 4 && xmlHttp.status == 200) {
        callback(xmlHttp.responseText)
      } else {
        return
      }
    }
    xmlHttp.open('GET', url, true)
    xmlHttp.setRequestHeader('Accept', 'text/javascript')
    xmlHttp.send(null)
  }
}

class PrealertTable {
  constructor(container) {
    this.container = container
    let elements = this.container.getElementsByClassName('pagination')

    for (let i = 0; i < elements.length; i++) {
      this.initialize_ajax_pagination(elements[i])
    }
    this.initialize_editables(this.container)
    this.handleEnterKey(this.container)
    this.handleDownKey(this.container)
    this.initialize_ajax_filter(this.container)
  }

  initialize_ajax_pagination(container) {
    let elements = container.getElementsByTagName('a')

    for (let i = 0; i < elements.length; i++) {
      elements[i].addEventListener('click', function (event) {
        event.preventDefault()
        event.stopPropagation()
        PrealertTable.prototype.loadPage(container.parentElement, elements[i].href)
      })
    }
  }

  initialize_ajax_filter(container) {
    let form = document.getElementById('prealert_filter')
    let filter_input = document.getElementById('error_message_filter')
    if (typeof filter_input !== 'undefined') {
      filter_input.addEventListener('change', function (event) {
        PrealertTable.prototype.loadPage(
          container,
          form.action + '?error_message_filter=' + filter_input.value
        )
      })
    }
  }

  initialize_editables(container) {
    let elements = container.getElementsByTagName('td')

    for (let i = 0; i < elements.length; i++) {
      elements[i].addEventListener('click', function (event) {
        PrealertTable.prototype.handleClick(event, container)
      })
    }
  }

  loadPage(container, url) {
    HTTPClient.prototype.httpGetAsync(url, function (responseText) {
      var title = document.getElementsByTagName('title')[0].innerHTML

      window.history.replaceState('filter', title, url)

      container.innerHTML = responseText

      let elements = container.getElementsByClassName('pagination')

      for (let i = 0; i < elements.length; i++) {
        PrealertTable.prototype.initialize_ajax_pagination(elements[i])
      }
      PrealertTable.prototype.initialize_editables(container)
      PrealertTable.prototype.initialize_ajax_filter(container)
    })
  }

  handleDownKey(container) {
    document.addEventListener('keydown', function (event) {
      if (event.keyCode === 40) {
        if (container.getElementsByClassName('active_row').length > 0) {
          event.preventDefault()
          event.stopPropagation()
          let active_row = container.getElementsByClassName('active_row')[0]
          PrealertTable.prototype.disableInputs(active_row)
          active_row.classList.remove('active_row')
        }
      }
    })
  }

  handleEnterKey(container) {
    document.addEventListener('keydown', function (event) {
      if (event.keyCode === 13) {
        if (container.getElementsByClassName('active_row').length > 0) {
          event.preventDefault()
          event.stopPropagation()
          let form = document.getElementById('edit_line_item_form')
          let active_row = container.getElementsByClassName('active_row')[0]
          let errors_container = document.getElementById('prealert_error_messages_container')
          let xhr = new XMLHttpRequest()

          active_row.classList.add('loading')

          xhr.onload = function () {
            active_row.removeAttribute('data-errors')

            let elements = active_row.getElementsByTagName('td')

            for (let i = 0; i < elements.length; i++) {
              elements[i].removeAttribute('data-errors')
            }
            if (xhr.responseText == '{}') {
              PrealertTable.prototype.loadPage(
                document.getElementById('line_items_table'),
                window.location.href
              )
              if (document.querySelectorAll('[data-errors]').length === 0) {
                PrealertTable.prototype.loadPage(
                  document.getElementById('line_items_table'),
                  document.location.pathname
                )
              }
            } else {
              let errors = JSON.parse(xhr.responseText)
              for (var prop in errors) {
                if (Object.prototype.hasOwnProperty.call(errors, prop)) {
                  let element = active_row.getElementsByClassName(prop)[0]
                  if (prop !== 'line') {
                    element.dataset.errors = errors[prop]
                  } else {
                    element.dataset.errors = errors[prop]
                    active_row.dataset.errors = errors[prop]
                  }
                }
              }
              PrealertTable.prototype.loadPage(
                document.getElementById('line_items_table'),
                window.location.href
              )
            }
            active_row.classList.remove('loading')
          }

          xhr.onerror = function () {
            active_row.classList.remove('loading')
            alert('There has been an error processing this request.')
          }
          let token = document.getElementsByName('csrf-token')[0].content
          xhr.open(form.method, form.action, true)
          xhr.setRequestHeader('ACCEPT', 'application/json')
          xhr.setRequestHeader('X-CSRF-TOKEN', token)
          xhr.send(new FormData(form))
          return false
        }
      }
    })
  }

  disableInputs(container) {
    let inputs = container.getElementsByTagName('input')

    for (let i = 0; i < inputs.length; i++) {
      inputs[i].disabled = true
    }

    let areas = container.getElementsByTagName('textarea')

    for (let i = 0; i < areas.length; i++) {
      areas[i].disabled = true
    }
  }

  handleClick(event, container) {
    let target_row = event.target.closest('tr')

    if (document.getElementsByClassName('active_row').length > 0) {
      let active_row = document.getElementsByClassName('active_row')[0]
      let form = document.getElementById('edit_line_item_form')
      let errors_container = document.getElementById('prealert_error_messages_container')

      if (!active_row.contains(event.target)) {
        let xhr = new XMLHttpRequest()

        active_row.classList.add('loading')

        xhr.onload = function () {
          active_row.removeAttribute('data-errors')
          let elements = active_row.getElementsByTagName('td')

          for (let i = 0; i < elements.length; i++) {
            elements[i].removeAttribute('data-errors')
          }

          if (xhr.responseText == '{}') {
            let target_id = target_row.id

            PrealertTable.prototype.loadPage(
              document.getElementById('line_items_table'),
              window.location.href
            )

            if (document.querySelectorAll('[data-errors]').length === 0) {
              PrealertTable.prototype.loadPage(
                document.getElementById('line_items_table'),
                document.location.pathname
              )
            } else {
              PrealertTable.prototype.makeEditable(event, document.getElementById(target_id))
            }
          } else {
            let errors = JSON.parse(xhr.responseText)

            for (var prop in errors) {
              if (Object.prototype.hasOwnProperty.call(errors, prop)) {
                let element = active_row.getElementsByClassName(prop)[0]

                if (prop !== 'line') {
                  element.dataset.errors = errors[prop]
                } else {
                  element.dataset.errors = errors[prop]
                  active_row.dataset.errors = errors[prop]
                }
              }
            }
            PrealertTable.prototype.loadPage(
              document.getElementById('line_items_table'),
              window.location.href
            )
          }
          active_row.classList.remove('loading')
        }
        xhr.onerror = function () {
          active_row.classList.remove('loading')
          alert('There has been an error processing this request.')
        }
        let token = document.getElementsByName('csrf-token')[0].content
        xhr.open(form.method, form.action, true)
        xhr.setRequestHeader('ACCEPT', 'application/json')
        xhr.setRequestHeader('X-CSRF-TOKEN', token)
        xhr.send(new FormData(form))
        return false
      }
    } else {
      PrealertTable.prototype.makeEditable(event, target_row)
    }
  }

  makeEditable(event, container) {
    container.classList.add('active_row')

    let form = document.getElementById('edit_line_item_form')

    form.action = form.action.replace(/line_items\/\d+/, `line_items/${container.id}`)
    let inputs = container.getElementsByTagName('input')

    for (let i = 0; i < inputs.length; i++) {
      inputs[i].disabled = false
    }
    let areas = container.getElementsByTagName('textarea')

    for (let i = 0; i < areas.length; i++) {
      areas[i].disabled = false
    }

    let input = event.target.closest('td')
    input.focus()
  }
}

document.addEventListener('turbo:load', function () {
  var prealert_table = document.getElementById('line_items_table')

  if (prealert_table) {
    new PrealertTable(prealert_table)
  }
})
