// There is a known issue, where we cannot stop browser back navigation from happening

import { on, off } from '../../common/utils/utils'

const MESSAGE = 'Are you sure you want to leave this page? Your inputs will not be saved.'
export const FORM_CLS = 'confirm-leave'

let _initiated = false
let _confirmLeave = false

function _noForm() {
  return !document.querySelector(`.${FORM_CLS}`)
}

function handleBeforeUnload(e) {
  if (!_confirmLeave) {
    e.preventDefault()
    e.returnValue = ''
  } else {
    restore()
  }
}

function handleBeforeVisit(e) {
  if (!_confirmLeave) {
    if (!confirm(MESSAGE)) {
      e.preventDefault()
    }
  } else {
    restore()
  }
}

function init() {
  if (_initiated) {
    destroy()
  }

  if (_noForm()) {
    return
  }

  _initiated = true

  on(window, 'beforeunload', handleBeforeUnload)
  on(document, 'turbo:before-visit', handleBeforeVisit)
}

function get() {
  if (!_initiated) {
    return
  }

  return _confirmLeave
}

function set() {
  if (!_initiated) {
    return
  }

  _confirmLeave = true
}

function restore() {
  if (!_initiated) {
    return
  }

  _confirmLeave = false
}

function destroy() {
  if (!_initiated) {
    return
  }

  _initiated = false
  restore()
  off(window, 'beforeunload', handleBeforeUnload)
  off(document, 'turbo:before-visit', handleBeforeVisit)
}

const ConfirmLeave = {
  init,
  get,
  set,
  restore,
  destroy
}

export default ConfirmLeave
