import Title from './title'
import { on, off } from '../../common/utils/utils'

const BASE_CLS = 'tooltip'
const AUTO_HIDE_CLS = `${BASE_CLS}--auto-hide`
const AUTO_HIDE_SLOW_CLS = `${AUTO_HIDE_CLS}-slow`
const SHOW_CLS = `${BASE_CLS}--show`
const CONTENT_CLS = `${BASE_CLS}-content`

export default function TooltipElement({
  reference,
  text,
  autoHide = false,
  onMouseLeave,
  onHide
}) {
  let tooltip = null
  let title = null

  function get() {
    return tooltip
  }

  function render() {
    if (tooltip) {
      return
    }

    const content = document.createElement('div')
    tooltip = document.createElement('div')
    if (!text) {
      title = Title(reference)
    }

    tooltip.classList.add(BASE_CLS)
    if (autoHide) {
      tooltip.classList.add(AUTO_HIDE_CLS)
      if (autoHide === 'slow') {
        tooltip.classList.add(AUTO_HIDE_SLOW_CLS)
      }
    }
    content.classList.add(CONTENT_CLS)
    content.innerHTML = text || title.get()
    tooltip.appendChild(content)
    on(tooltip, 'mouseleave', onMouseLeave)

    document.body.appendChild(tooltip)
  }

  function handleTransitionEnd() {
    if (tooltip.classList.contains(SHOW_CLS)) {
      tooltip.classList.remove(SHOW_CLS)
    } else {
      onHide()
    }
  }

  function show() {
    if (!tooltip) {
      return
    }

    tooltip.classList.add(SHOW_CLS)

    if (autoHide) {
      on(tooltip, 'TransitionEnd', handleTransitionEnd)
    }
  }

  function remove() {
    if (!tooltip) {
      return
    }

    if (!text) {
      title.restore()
    }
    off(tooltip, 'mouseleave', onMouseLeave)
    off(tooltip, 'TransitionEnd', handleTransitionEnd)

    try {
      tooltip.parentNode.removeChild(tooltip)
    } catch (e) {}

    tooltip = null
  }

  render()

  return {
    get,
    show,
    remove
  }
}
