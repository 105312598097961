import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['formWithPassword', 'formWithoutPassword']

  toggle() {
    this.formWithPasswordTarget.classList.toggle('hidden')
    this.formWithoutPasswordTarget.classList.toggle('hidden')
  }
}
