import { Controller } from '@hotwired/stimulus'
import toggleClass from '../../../frontend/src/common/toggle-class/toggle-class'
import type { State } from '../../transit_component/types'

const CSS_PREFIX = 'transit__state'
const STATE_MAP: {
  [key in State]: {
    icons: string[]
    title: string
  }
} = {
  empty: {
    icons: ['fa-circle', 'red'],
    title: 'Not created yet'
  },
  saved: {
    icons: ['fa-check-circle', 'green'],
    title: 'Saved'
  },
  changed: {
    icons: ['fa-pencil'],
    title: 'Unsaved changes'
  },
  loading: {
    icons: ['fa-spinner', 'fa-pulse'],
    title: 'Please wait...'
  },
  error: {
    icons: ['fa-exclamation-triangle', 'red'],
    title: 'Error'
  }
}

export default class extends Controller {
  static targets = ['icon']

  declare readonly iconTarget: HTMLElement

  static values = {
    state: String,
    error: String
  }

  declare stateValue: string
  declare errorValue: string

  stateValueChanged(newValue: State, previousValue: State | ''): void {
    if (previousValue !== '') {
      toggleClass({
        element: this.element,
        className: `${CSS_PREFIX}--${previousValue}`,
        predicate: false
      })
    }

    toggleClass({
      element: this.element,
      className: `${CSS_PREFIX}--${newValue}`,
      predicate: true
    })

    if (previousValue !== '') {
      STATE_MAP[previousValue].icons.forEach(cls => {
        toggleClass({
          element: this.iconTarget,
          className: cls,
          predicate: false
        })
      })
    }

    STATE_MAP[newValue].icons.forEach(cls => {
      toggleClass({
        element: this.iconTarget,
        className: cls,
        predicate: true
      })
    })

    this.iconTarget.setAttribute(
      'title',
      this.errorValue !== '' ? `Error: ${this.errorValue}` : STATE_MAP[newValue].title
    )
  }

  handleStateChange({ detail }: { detail: { state: State } }): void {
    this.stateValue = detail.state
  }
}
