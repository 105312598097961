import { on, off } from '../../../common/utils/utils'
import { toArray } from 'lodash'

function _toggleInputDisable(inputs, value) {
  inputs.forEach(input => {
    input.disabled = value
  })
}

export default function ManageWaybillsInTransferShipment({ targets }) {
  const toggleButton = targets.base
  const cancelButton = targets.cancel
  const transitsTable = targets.transits
  const transitInputs = transitsTable && toArray(transitsTable.querySelectorAll('input'))
  const waybillsTable = targets.waybills
  const waybillInputs = waybillsTable && toArray(waybillsTable.querySelectorAll('input'))
  const noTables = !transitsTable || !waybillsTable

  if (noTables) {
    toggleButton.disabled = true
  }

  function _toggleTableDisable(manage = false) {
    if (manage) {
      _toggleInputDisable(transitInputs, true)
      _toggleInputDisable(waybillInputs, false)
    } else {
      _toggleInputDisable(transitInputs, false)
      _toggleInputDisable(waybillInputs, true)
    }
  }

  function activateManage() {
    if (noTables) {
      return
    }

    toggleButton.style.display = 'none'
    transitsTable.style.display = 'none'

    cancelButton.style.display = 'inline'
    waybillsTable.style.display = 'table'
    _toggleTableDisable(true)
  }

  function deactivateManage() {
    if (noTables) {
      return
    }

    toggleButton.style.display = 'inline'
    transitsTable.style.display = 'table'

    cancelButton.style.display = 'none'
    waybillsTable.style.display = 'none'
    _toggleTableDisable(false)
  }

  function init() {
    deactivateManage()
    on(toggleButton, 'click', activateManage)
    on(cancelButton, 'click', deactivateManage)
  }

  function destroy() {
    off(toggleButton, 'click', activateManage)
    off(cancelButton, 'click', deactivateManage)
  }

  return {
    init,
    destroy
  }
}
