import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'checkBox',
    'topXParcels',
    'selectAllBox',
    'saveButton',
    'eodAutoSelectButton',
    'autoSelectButton'
  ]

  static values = { parcelCount: Number }

  initialize() {
    const noAutoSelectableParcels = this.checkBoxTargets.every(
      checkBox => checkBox.dataset.autoSelectable === 'false'
    )
    const noEodAutoSelectableParcels = this.checkBoxTargets.every(
      checkBox => checkBox.dataset.eodAutoSelectable === 'false'
    )
    if (noAutoSelectableParcels) {
      this.disable(
        this.autoSelectButtonTarget,
        'No handed over parcels match the time frame for this destination'
      )
    }

    if (noEodAutoSelectableParcels) {
      this.disable(this.eodAutoSelectButtonTarget, 'No pending pallet for this destination found')
    }
  }

  disable(button, message) {
    button.classList.add('disabled')
    button.dataset.action = ''
    button.title = message
  }

  connect() {
    this.selectFirstXElements()
  }

  parcelCountValueChanged() {
    this.updateSaveButton()
  }

  selectAll() {
    for (const checkBox of this.checkBoxTargets) {
      checkBox.checked = true
    }
    this.selectAllBoxTarget.checked = true
    this.selectAllBoxTarget.dataset.action = 'click->available-deliveries--select#unselectAll'
    this.topXParcelsTarget.value = this.checkBoxTargets.length
    this.parcelCountValue = this.checkBoxTargets.length
  }

  unselectAll() {
    for (const checkBox of this.checkBoxTargets) {
      checkBox.checked = false
    }
    this.selectAllBoxTarget.checked = false
    this.selectAllBoxTarget.dataset.action = 'click->available-deliveries--select#selectAll'
    this.topXParcelsTarget.value = null
    this.parcelCountValue = 0
  }

  selectFirstXElements() {
    const topXParcels = parseInt(this.topXParcelsTarget.value)

    if (isNaN(topXParcels) || topXParcels <= 0) {
      this.unselectAll()
    } else if (topXParcels >= this.checkBoxTargets.length) {
      this.selectAll()
    } else {
      this.checkBoxTargets.forEach((checkBox, i) => {
        if (i < topXParcels) {
          checkBox.checked = true
        }
      })
      this.topXParcelsTarget.value = topXParcels
      this.parcelCountValue = topXParcels
    }
  }

  clickCheckBox(event) {
    // counter act clickRow action
    event.currentTarget.checked = !event.currentTarget.checked
    this.updateParcelCount()
  }

  clickRow(event) {
    const checkBox = event.currentTarget.getElementsByClassName('check-box')[0]
    checkBox.checked = !checkBox.checked
    this.updateParcelCount()
  }

  eodAutoSelectParcels() {
    this.checkBoxTargets.forEach((checkBox, i) => {
      if (checkBox.dataset.eodAutoSelectable === 'true') {
        checkBox.checked = true
      }
    })
    this.updateParcelCount()
  }

  autoSelectParcels() {
    this.checkBoxTargets.forEach((checkBox, i) => {
      if (checkBox.dataset.autoSelectable === 'true') {
        checkBox.checked = true
      }
    })
    this.updateParcelCount()
  }

  updateParcelCount() {
    const parcelCount = this.checkBoxTargets.reduce((count, checkBox) => {
      if (checkBox.checked === true) {
        return count + 1
      }
      return count
    }, 0)
    this.topXParcelsTarget.value = null
    this.parcelCountValue = parcelCount
  }

  updateSaveButton() {
    if (isNaN(this.parcelCountValue) || this.parcelCountValue === 0) {
      this.saveButtonTarget.value = 'No parcels selected'
      this.saveButtonTarget.disabled = true
    } else if (this.parcelCountValue === 1) {
      this.saveButtonTarget.value = 'Add 1 selected parcel'
      this.saveButtonTarget.disabled = false
    } else {
      this.saveButtonTarget.value = `Add ${this.parcelCountValue} selected parcels`
      this.saveButtonTarget.disabled = false
    }
  }
}
