import { Controller } from '@hotwired/stimulus'
import type { OpenDetail, ResolveMethod } from './types'

export default class extends Controller {
  static targets = ['title', 'challenge', 'challengeInput', 'message', 'confirmButton']

  declare readonly titleTarget: HTMLElement
  declare readonly challengeTarget: HTMLElement
  declare readonly challengeInputTarget: HTMLInputElement
  declare readonly messageTarget: HTMLElement
  declare readonly confirmButtonTarget: HTMLButtonElement

  static values = {
    title: String,
    message: String,
    challenge: String,
    manualSubmit: Boolean,
    defaultTitle: String,
    defaultMessage: String
  }

  declare titleValue: string
  declare messageValue: string
  declare challengeValue: string
  declare manualSubmitValue: boolean
  declare defaultTitleValue: string
  declare defaultMessageValue: string

  resolveMethod: ResolveMethod = () => {}
  form: HTMLFormElement | null = null

  reset(): void {
    this.element.classList.add('hidden')
    this.titleValue = ''
    this.messageValue = ''
    this.challengeValue = ''
    this.manualSubmitValue = false

    if (this.form !== null) {
      this.form.dataset.turbo = 'true'
      this.form = null
    }
  }

  isChallengeMet(target: HTMLInputElement): boolean {
    return this.challengeValue.toLowerCase() === target.value.toLowerCase()
  }

  titleValueChanged(titleValue: string): void {
    if (titleValue !== '') {
      this.titleTarget.innerText = titleValue
    } else {
      this.titleTarget.innerText = this.defaultTitleValue
    }
  }

  messageValueChanged(messageValue: string): void {
    if (messageValue !== '') {
      this.messageTarget.innerHTML = messageValue
    } else {
      this.messageTarget.innerText = this.defaultMessageValue
    }
  }

  challengeValueChanged(challengeValue: string): void {
    if (challengeValue !== '') {
      this.challengeInputTarget.setAttribute('placeholder', `To confirm type "${challengeValue}"`)
      this.challengeTarget.classList.remove('hidden')
    } else {
      this.challengeTarget.classList.add('hidden')
      this.challengeInputTarget.setAttribute('placeholder', '')
      this.challengeInputTarget.value = ''
      this.confirmButtonTarget.disabled = this.titleValue === ''
    }
  }

  handleOpen({
    detail: { title, message, challenge, manualSubmit, target }
  }: {
    detail: OpenDetail
  }): void {
    this.element.classList.remove('hidden')

    if (title !== undefined) {
      this.titleValue = title
    }

    if (message !== undefined) {
      this.messageValue = message
    }

    if (challenge !== undefined) {
      this.challengeValue = challenge
    }

    this.manualSubmitValue = manualSubmit
    this.form = target.closest('form')
    this.confirmButtonTarget.disabled = challenge !== undefined
  }

  handleResolve({ detail: { resolve } }: { detail: { resolve: ResolveMethod } }): void {
    this.resolveMethod = resolve
  }

  handleCancel(): void {
    this.resolveMethod(false)
    this.reset()
  }

  handleConfirm(): void {
    if (!this.isChallengeMet(this.challengeInputTarget)) {
      return
    }

    if (this.manualSubmitValue && this.form !== null) {
      this.form.dataset.turbo = 'false'
      this.resolveMethod(false)
      this.form.requestSubmit()
    } else {
      this.resolveMethod(true)
    }
    this.reset()
  }

  handleInput({ target }: { target: HTMLInputElement }): void {
    this.confirmButtonTarget.disabled = !this.isChallengeMet(target)
  }
}
