import { off, on } from '../../../common/utils/utils'
import ConfirmLeave from '../confirm-leave'

export default function ConfirmLeaveButton({ targets }) {
  const { base } = targets

  function init() {
    on(base, 'click', ConfirmLeave.set)
  }

  function destroy() {
    ConfirmLeave.restore()
    off(base, 'click', ConfirmLeave.set)
  }

  return {
    init,
    destroy
  }
}
