import getParcelsCount from './get-parcels-count'
import Transports from './transports/transports'
import { Elements } from '../shared/shared'

export default function ParcelCounter({ targets }) {
  const { parcels, transports, totalCount, selectedCount } = targets
  const transportsInstance = Transports({ targets: { parcels, transports } })

  update(true)

  function update(checkIfEditing = false) {
    const checkboxes = Elements.checkboxes()

    if (!checkboxes) {
      return
    }

    const { total, selected } = getParcelsCount(checkboxes)
    totalCount.textContent = total
    selectedCount.textContent = selected
    transportsInstance.update(checkIfEditing)
  }

  return {
    update
  }
}
