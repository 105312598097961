import { on, off } from '../../common/utils/utils'

const BUTTON_CLS = 'duplicate-row--remove-button'

export default function RemoveButton(button) {
  let onRemoveCb = () => {}

  _init()

  function _init() {
    button.classList.add(BUTTON_CLS)
    show()
    on(button, 'click', _handleRemove)
  }

  function _handleRemove() {
    destroy()
    onRemoveCb()
  }

  function show() {
    button.style.display = 'block'
  }

  function hide() {
    button.style.display = 'none'
  }

  function onRemove(cb) {
    onRemoveCb = cb
  }

  function destroy() {
    off(button, 'click', _handleRemove)
  }

  return {
    onRemove,
    show,
    hide,
    destroy
  }
}
