import { isEmpty, toArray, kebabCase } from 'lodash'
import { get, set, remove, all, SCOPE_JS } from './instances'

const DEFAULT_NAMESPACE = 'v3'
const KEY = 'component'

function _generateId() {
  let seed = Date.now()
  return 'xxxxxxxx'.replace(/[x]/g, () => {
    const r = (seed + Math.random() * 16) % 16 | 0
    seed = Math.floor(seed / 16)
    return r.toString(16)
  })
}

function _getSelector(ext) {
  return `[data-${KEY}$=".${ext}"]`
}

function _getAliasSelector(alias) {
  return `[data-${kebabCase(alias)}]`
}

function _parseValue(value) {
  if (!value && typeof value !== 'undefined') {
    return true
  } else if (value === 'true') {
    return true
  } else if (value === 'false') {
    return false
  }
  return value
}

function _getTargets({ base, parent }) {
  const parentTarget = parent && base.closest(parent)
  const defaultTargets = parentTarget ? { parent: parentTarget } : {}
  return toArray((parentTarget || base).querySelectorAll('[data-target]')).reduce(
    (acc, element) => {
      const key = element.dataset.target
      const iterate = _parseValue(element.dataset.iterate)
      if (iterate && acc[key]) {
        acc[key] = [...acc[key], element]
        return acc
      } else {
        return { ...acc, [key]: iterate ? [element] : element }
      }
    },
    { ...defaultTargets, base }
  )
}

function _getOptions({ ext, base, namespace }) {
  const props = Object.keys(base.dataset).reduce(
    (acc, key) => ({ ...acc, [key]: _parseValue(base.dataset[key]) }),
    {
      namespace: namespace || DEFAULT_NAMESPACE
    }
  )
  const targets = ext === SCOPE_JS ? _getTargets({ base, parent: props.parent }) : { base }

  return { props, targets }
}

function _sortByKey(a, b) {
  const aName = a.dataset[KEY]
  const bName = b.dataset[KEY]
  if (aName < bName) {
    return -1
  } else if (aName > bName) {
    return 1
  } else {
    return 0
  }
}

function _convertFromAlias(element, aliases) {
  const keys = Object.keys(element.dataset)
  const aliasKey = Object.keys(aliases).filter(alias => keys.indexOf(alias) !== -1)[0]
  const alias = aliasKey ? aliases[aliasKey] || {} : {}

  element.dataset[KEY] = alias.component
  element.dataset.namespace = alias.namespace || DEFAULT_NAMESPACE
  if (alias.propKey) {
    element.dataset[alias.propKey] = element.dataset[aliasKey]
  }
  delete element.dataset[aliasKey]

  return element
}

function _getElements({ ext, parent = null, aliases = {} }) {
  parent = parent || document
  const byDefaultSelector = toArray(parent.querySelectorAll(_getSelector(ext)))
  const byAliases = !isEmpty(aliases)
    ? Object.keys(aliases)
        .reduce(
          (acc, alias) => [...acc, ...toArray(parent.querySelectorAll(_getAliasSelector(alias)))],
          []
        )
        .map(element => _convertFromAlias(element, aliases))
    : []
  return [...byDefaultSelector, ...byAliases].sort(_sortByKey)
}

export function _mount({ init, options, ext }) {
  const { targets, props } = options
  const { base } = targets
  const { component, namespace } = props
  const regexp = new RegExp(`.${ext}$`)
  const folder = component.replace(regexp, '')
  const subs = folder.split('/')
  const name = subs[subs.length - 1]
  const path = `${folder}/${name}.${ext}`
  const uid = _generateId()

  base.dataset.uid = uid

  if (!namespace) {
    props.namespace = DEFAULT_NAMESPACE
  }
  props.uid = uid // TODO to be removed, no longer needed

  const instance = init(path, options)
  set({ scope: ext, uid, instance })
  return { uid, path }
}

export function mountAll({ ext, parent = null, init, namespace, aliases }) {
  if (!ext) {
    throw new Error('Can not mount components, please provide ext')
  }

  return _getElements({ ext, parent, aliases })
    .map(base => _getOptions({ ext, base, namespace }))
    .map(options => _mount({ init, options, ext }))
}

export function _unmount({ destroy, uid = null, base = null, ext } = {}) {
  uid = uid || (base && base.dataset.uid)

  if (!uid || !ext) {
    throw new Error(
      'Can not unmount the component, please provide either base element or uid, but also ext'
    )
  }

  base = base || document.querySelector(`[data-uid="${uid}"]`)
  const instance = get({ scope: ext, uid })
  if (base) {
    base.removeAttribute('data-uid')
  }
  if (instance) {
    destroy(instance)
    remove({ scope: ext, uid })
  }
}

export function unmountAll({ ext, parent = null, destroy }) {
  if (!ext) {
    throw new Error('Can not unmount components, please provide ext')
  }

  if (parent) {
    _getElements({ ext, parent }).forEach(base => _unmount({ destroy, base, ext }))
  } else {
    all(ext).forEach(uid => _unmount({ destroy, uid, ext }))
  }
}
