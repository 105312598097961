import TooltipElement from './tooltip-element'
import { createPopper } from '@popperjs/core/lib/popper-lite'
import { flip, preventOverflow } from '@popperjs/core/lib/modifiers'
import { on, off } from '../utils/utils'
import { noop } from 'lodash'

const DEFAULT_CONFIG = {
  placement: 'bottom-start',
  modifiers: [flip, preventOverflow]
}

function getConfig(config = {}) {
  return { ...DEFAULT_CONFIG, ...config }
}

export default function Popper({ reference, text = null, placement, selfDestroy = false }) {
  let tooltipInstance = null
  let popperInstance = null
  const config = placement ? { placement } : null
  const onHide = selfDestroy ? destroy : noop
  const onMouseLeave = selfDestroy ? noop : destroy

  function init() {
    if (popperInstance) {
      return
    }

    on(reference, 'mouseleave', onMouseLeave)
    tooltipInstance = TooltipElement({
      reference,
      text,
      autoHide: selfDestroy,
      onMouseLeave,
      onHide
    })
    popperInstance = createPopper(reference, tooltipInstance.get(), getConfig(config))
    tooltipInstance.show()
  }

  function destroy() {
    if (!popperInstance) {
      return
    }

    off(reference, 'mouseleave', onMouseLeave)
    popperInstance.destroy()
    tooltipInstance.remove()

    tooltipInstance = null
    popperInstance = null
  }

  init()

  return {
    init,
    destroy
  }
}
