import { Controller } from '@hotwired/stimulus'
import { on, off } from '../../common/utils/utils'

export default class extends Controller {
  selectTarget: HTMLSelectElement | null = null
  handleToggleFocus: () => void = () => {}

  connect(): void {
    this.selectTarget = this.element.querySelector('select')
    this.handleToggleFocus = this.toggleFocus.bind(this)
    on(this.selectTarget, 'focus', this.handleToggleFocus)
    on(this.selectTarget, 'blur', this.handleToggleFocus)
  }

  disconnect(): void {
    off(this.selectTarget, 'focus', this.handleToggleFocus)
    off(this.selectTarget, 'blur', this.handleToggleFocus)
  }

  toggleFocus(): void {
    if (this.element.classList.contains('focus')) {
      this.element.classList.remove('focus')
    } else {
      this.element.classList.add('focus')
    }
  }
}
