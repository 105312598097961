export default function Title(reference) {
  let title = ''

  function hide() {
    if (!reference) {
      return
    }

    title = reference.title
    reference.title = ''
  }

  function get() {
    return title
  }

  function restore() {
    if (!reference) {
      return
    }

    reference.title = title
    title = ''
  }

  hide()

  return {
    hide,
    get,
    restore
  }
}
