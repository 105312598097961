import { delegate, isVisible } from '../../../common/utils/utils'
import { FORM_CLS } from '../confirm-leave'
import { FIELDS, BUTTONS } from './selectors'
import { indexOf, toArray } from 'lodash'

let _initiated = false
let _off = () => {}

function _focusNext(target) {
  const selectors = [...FIELDS, ...BUTTONS, 'textarea'].join(',')
  const focusable = toArray(target.closest(`.${FORM_CLS}`).querySelectorAll(selectors)).filter(
    isVisible
  )
  const currentIndex = indexOf(focusable, target)
  const nextIndex = currentIndex === focusable.length - 1 ? 0 : currentIndex + 1
  const next = focusable[nextIndex]

  if (next) {
    next.focus()
  }
}

function _getFieldsSelector() {
  return FIELDS.map(selector => `.${FORM_CLS} ${selector}`).join(',')
}

function handleKeyDown(e) {
  const key = e.which || e.keyCode || 0

  if (key !== 13) {
    return
  }

  e.preventDefault()
  _focusNext(e.target)
}

function init() {
  if (_initiated) {
    destroy()
  }

  _initiated = true
  _off = delegate(document, _getFieldsSelector(), 'keydown', handleKeyDown)
}

function destroy() {
  if (!_initiated) {
    return
  }

  _initiated = false
  _off()
}

const EnterToTab = {
  init,
  destroy
}

export default EnterToTab
