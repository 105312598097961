import { Controller } from '@hotwired/stimulus'
import http from '../../common/http/http'
import { EVENTS, Elements, dispatchEvent } from './shared/shared'

const DEFAULT_RESULT = { parcels: [], shortcode: '' }

type Parcel = Record<string, any>
interface Result {
  parcels: Parcel[]
  shortcode: string
}

export default class extends Controller {
  static targets = ['container', 'ehub', 'shortcode']

  declare readonly containerTarget: HTMLElement
  declare readonly ehubTarget: HTMLElement
  declare readonly shortcodeTarget: HTMLElement

  static values = {
    url: String
  }

  declare urlValue: string

  service: string = ''
  ehub: string = ''
  shortcode: string = ''
  loading: boolean = false
  refreshResult: Result = DEFAULT_RESULT

  connect(): void {
    this.check()
  }

  getCurrentParcelsCount(): number {
    return Elements.rows().filter(row => {
      const dataset = (row as HTMLElement).dataset
      return dataset.processingEhub === this.ehub && dataset.shortcode === this.shortcode
    }).length
  }

  check(): void {
    this.service = Elements.service()
    this.ehub = Elements.ehub()
    this.shortcode = Elements.shortcode()

    this.ehubTarget.innerText = this.ehub
    this.shortcodeTarget.innerText = this.shortcode

    if (this.ehub === '' || this.shortcode === '' || this.loading) {
      return
    }

    this.loading = true

    void http({
      url: this.urlValue,
      params: {
        service: this.service,
        shortcode: this.shortcode,
        processing_ehub: this.ehub
      }
    })
      .then((response: { shortcode_ehub: Result }) => {
        const result = response.shortcode_ehub
        const newParcelsCount = result.parcels.length
        if (newParcelsCount !== 0 && this.getCurrentParcelsCount() !== newParcelsCount) {
          this.refreshResult = result
          this.containerTarget.classList.remove('hidden')
        }
      })
      .finally(() => {
        this.loading = false
      })
  }

  handleRefresh(): void {
    dispatchEvent(EVENTS.refreshParcels, { result: this.refreshResult })
    this.refreshResult = DEFAULT_RESULT
    this.containerTarget.classList.add('hidden')
  }
}
