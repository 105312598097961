import { uniq, toArray } from 'lodash'
import getParcelsCount, { CHECKBOX_SELECTOR } from '../get-parcels-count'
import { Elements } from '../../shared/shared'
import dayjs from 'dayjs'

const FORM_SELECTOR = '.form_group'
const EQUIPMENT_SELECTOR = 'select[id*="line_haul_eligible_transport_id"]'
const PARCELS_COUNT_SELECTOR = 'input[id*="parcels_count"]'
const SENDER_INSTRUCTIONS_SELECTOR = 'textarea[id*="sender_instructions"]'
const DATE_FORMAT = 'DD-MM-YYYY'

export default function Transports({ targets }) {
  const { transports } = targets

  function update(checkIfEditing = false) {
    const rows = Elements.rows()
    const checkboxes = Elements.checkboxes()

    if (!rows) {
      return
    }

    _updateCount(checkboxes)
    _updateSenderInstructions(checkIfEditing, rows)
  }

  function _updateCount(checkboxes) {
    if (!transports) {
      return
    }

    const allInputs = toArray(transports.querySelectorAll(PARCELS_COUNT_SELECTOR))
    const lastInput = allInputs.pop()
    const lastInputValue = parseInt(lastInput.value || 0, 10)
    const total = allInputs.reduce((sum, input) => sum + parseInt(input.value || 0), lastInputValue)
    const { selected } = getParcelsCount(checkboxes)
    const difference = selected - total

    lastInput.value = Math.max(lastInputValue + difference, 0)
  }

  function _updateSenderInstructions(checkIfEditing = false, rows) {
    if (!transports) {
      return
    }

    const transportForms = toArray(transports.querySelectorAll(FORM_SELECTOR))
    const parcelsInstructions = rows
      .map(row => {
        const checkbox = row.querySelector(CHECKBOX_SELECTOR)
        const senderInstructions = row.dataset.senderInstructions
        return checkbox.checked && senderInstructions !== 'null' ? senderInstructions : null
      })
      .filter(instructions => !!instructions)
    const instructions = uniq(parcelsInstructions).join('\n')

    transportForms.forEach(form => {
      const input = form.querySelector(SENDER_INSTRUCTIONS_SELECTOR)
      const equipment = form.querySelector(EQUIPMENT_SELECTOR)
      if (!checkIfEditing || (checkIfEditing && !equipment.value)) {
        input.value = instructions ? `${instructions} (${dayjs().format(DATE_FORMAT)})` : ''
      }
    })
  }

  return {
    update
  }
}
