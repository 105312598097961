import { off, on } from '../utils/utils'

export const CLS = 'input-flash'

export default function InputFlash(target) {
  let initiated = true

  function flash() {
    if (!initiated) {
      return
    }
    target.classList.add(CLS)
  }

  function transitionEndHandle() {
    target.classList.remove(CLS)
  }

  on(target, 'TransitionEnd', transitionEndHandle)

  function destroy() {
    initiated = false
    off(target, 'TransitionEnd', transitionEndHandle)
  }

  return {
    flash,
    destroy
  }
}
