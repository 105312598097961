import { on, off } from '../../../common/utils/utils'
import { includes } from 'lodash'

const ETA_ENABLED_FOR = ['rail', 'road', 'sea']

export default function ToggleEta({ targets, props = {} }) {
  const { base, eta } = targets
  const { method } = props

  function handleChange(e) {
    if (includes(ETA_ENABLED_FOR, e.currentTarget.value)) {
      eta.disabled = false
    } else {
      eta.disabled = true
      eta.value = null
    }
  }

  function init() {
    if (method !== 'post') {
      return
    }

    on(base, 'change', handleChange)
  }

  function destroy() {
    off(base, 'change', handleChange)
  }

  return {
    init,
    destroy
  }
}
