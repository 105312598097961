import Next from '../utils/next'
import { find } from 'lodash'

let _instances = 0
const _active = {}

export function parentBox(node, { namespace } = {}) {
  const id = Date.now() + _instances
  const next = Next()
  let active

  _instances += 1
  next.run(() => {
    init()
    active = _getActive()
  })

  function init() {
    node.dispatchEvent(
      new CustomEvent('parent-box-init', {
        detail: { setOverflow, removeOverflow }
      })
    )
  }

  function _getActive() {
    const parent =
      node.closest('.box') || node.closest('.modal-content') || node.closest('.boxComponent')
    let activeId = find(Object.keys(_active), key => _active[key].parent === parent)
    if (activeId) {
      return _active[activeId]
    }

    activeId = Date.now() + _instances
    _active[activeId] = { parent }

    return _active[activeId]
  }

  function setOverflow() {
    if (!active || (active && !active.parent)) {
      return
    }
    if (active.id && active.namespace !== namespace) {
      return
    }

    active.parent.style.overflow = 'visible'
    active.id = id
    active.namespace = namespace
  }

  function removeOverflow() {
    if (!active || (active && !active.parent)) {
      return
    }
    if (active.id !== id) {
      return
    }

    active.parent.style.removeProperty('overflow')
    active.id = null
    active.namespace = null
  }

  return {
    destroy() {
      next.clear()
      removeOverflow()
      if (active) {
        active.parent = null
      }
    }
  }
}
