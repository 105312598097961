import { on, off } from '../../../../common/utils/utils'
import dayjs from 'dayjs'

const DATE_FORMAT = 'YYYY-MM-DD HH:mm'
const DEFAULT_TRANSIT_DAYS = 0
const DEFAULT_DELIVERY_DAYS = [0, 1, 2, 3, 4, 5, 6]

export default function EligibleTransportsDeliveryWindowCalculator({ targets, props }) {
  const { equipment, plannedPickupAt, plannedDeliveryAt } = targets
  const { deliveryWindows } = props

  function _findNextAvailableDay(deliveryDate, deliveryDays) {
    let i = 0
    let nextDeliveryDate = new Date(deliveryDate.getTime())

    do {
      if (i === 10) break
      deliveryDate.setDate(deliveryDate.getDate() - (i - 1))
      nextDeliveryDate = new Date(deliveryDate.setDate(deliveryDate.getDate() + i))
      i++
    } while (!deliveryDays.includes(nextDeliveryDate.getDay()))

    return nextDeliveryDate
  }

  function calculateDeliveryWindow() {
    if (!equipment.value || !plannedPickupAt.value) {
      return
    }

    const plannedPickup = new Date(plannedPickupAt.value)
    const eligibleTransportInformation = deliveryWindows[equipment.value]
    const transitDays = eligibleTransportInformation.transit_days || DEFAULT_TRANSIT_DAYS
    const deliveryDays = eligibleTransportInformation.delivery_days || DEFAULT_DELIVERY_DAYS
    let nextDeliveryDate = new Date(plannedPickup.setDate(plannedPickup.getDate() + transitDays))

    if (deliveryDays.length > 0 && !deliveryDays.includes(nextDeliveryDate.getDay())) {
      nextDeliveryDate = _findNextAvailableDay(nextDeliveryDate, deliveryDays)
    }

    if (eligibleTransportInformation.delivery_time) {
      const [hours, minutes] = eligibleTransportInformation.delivery_time.split(':')

      nextDeliveryDate.setMinutes(minutes)
      nextDeliveryDate.setHours(hours)
    }

    plannedDeliveryAt.value = dayjs(nextDeliveryDate).format(DATE_FORMAT)
  }

  function init() {
    on(equipment, 'change', calculateDeliveryWindow)
    on(plannedPickupAt, 'change', calculateDeliveryWindow)
  }

  function destroy() {
    off(equipment, 'change', calculateDeliveryWindow)
    off(plannedPickupAt, 'change', calculateDeliveryWindow)
  }

  return {
    init,
    destroy
  }
}
