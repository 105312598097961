import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'
import type { AnyObject } from '../select/types'
import toggleClass from '../../frontend/src/common/toggle-class/toggle-class'
import { values } from 'lodash'

enum Status {
  loading = 'loading',
  done = 'done',
  error = 'error'
}
const CSS_PREFIX = 'waiting-for-download'

export default class extends Controller {
  static targets = ['loading', 'done', 'error']

  declare readonly loadingTarget: HTMLElement
  declare readonly doneTarget: HTMLElement
  declare readonly errorTarget: HTMLElement

  static values = {
    url: String,
    status: { type: String, default: Status.loading }
  }

  declare urlValue: string
  declare statusValue: Status

  intervalId = setInterval(this.checkDownload.bind(this), 2000)
  elements: {
    [key in Status]?: HTMLElement
  } = {}

  initialize(): void {
    this.elements = {
      loading: this.loadingTarget,
      done: this.doneTarget,
      error: this.errorTarget
    }
  }

  disconnect(): void {
    clearInterval(this.intervalId)
  }

  toggleByStatus(): void {
    values(this.elements).forEach(element => {
      toggleClass({
        element,
        className: `${CSS_PREFIX}--show`,
        predicate: this.elements[this.statusValue] === element
      })
    })
  }

  statusValueChanged(): void {
    this.toggleByStatus()
  }

  checkDownload(): void {
    const url = this.urlValue !== '' ? this.urlValue : window.location.href
    get(url, { responseKind: 'json' })
      .then(async ({ ok, json }: { ok: boolean; json: Promise<AnyObject> }) => {
        if (!ok) {
          this.setError()
        } else {
          return await json
        }
      })
      .then(({ ready, url }: { ready: boolean; url: string }) => {
        if (ready) {
          window.location.href = url
          this.statusValue = Status.done
          clearInterval(this.intervalId)
        }
      })
      .catch(() => {
        this.setError()
      })
  }

  setError(): void {
    this.statusValue = Status.error
    clearInterval(this.intervalId)
  }
}
