import type { TruckingJob } from '../types'
import { snakeCase, uniq } from 'lodash'

export default function serializeParams(
  truckingJobs: TruckingJob[] = [],
  paramKey: keyof TruckingJob
): string {
  const params = uniq(truckingJobs.map(truckingJob => truckingJob[paramKey]))
  return params
    .map(param => encodeURIComponent(`${snakeCase(paramKey)}s[]`) + `=${param}`)
    .join('&')
}
