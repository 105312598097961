import delegate from '../utils/event-listeners/delegate'
import Popper from './popper'

const TOOLTIP_SELECTOR = '[title]'

let _off = () => {}
let _instance = null

function render(e) {
  if (_instance) {
    restore()
  }
  const placement = e.target.dataset.placement

  _instance = Popper({ reference: e.target, placement })
}

function restore() {
  if (!_instance) {
    return
  }

  _instance.destroy()
  _instance = null
}

function init() {
  destroy()
  _off = delegate(document, TOOLTIP_SELECTOR, 'mouseenter', render, true)
}

function destroy() {
  restore()
  _off()
}

const Tooltip = {
  init,
  restore,
  destroy
}

export default Tooltip
