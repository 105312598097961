import { Controller } from '@hotwired/stimulus'

export default class extends Controller<HTMLElement> {
  connect(): void {
    this.element.addEventListener('click', this.hide.bind(this))
  }

  disconnect(): void {
    this.element.removeEventListener('click', this.hide.bind(this))
  }

  hide(): void {
    this.element.hidden = true
    this.element.classList.add('hidden')
  }
}
