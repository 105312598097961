import { Controller } from '@hotwired/stimulus'
import { patch } from '@rails/request.js'

export default class extends Controller {
  static targets = ['checkbox']

  declare readonly checkboxTarget: HTMLInputElement

  static values = {
    url: String,
    param: String
  }

  declare urlValue: string
  declare paramValue: string

  handleChange(): void {
    this.checkboxTarget.disabled = true

    patch(this.urlValue, {
      body: JSON.stringify({
        [this.paramValue]: this.checkboxTarget.checked ? 1 : 0
      }),
      responseKind: 'turbo-stream'
    }).finally(() => {
      this.checkboxTarget.disabled = false
    })
  }
}
