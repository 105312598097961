import { on, off } from '../../../common/utils/utils'
import { SHIPMENT_CHANGE_EVENT } from '../shipment/shipment'

export default function ShortcodeNotes({ targets }) {
  const { base, notes } = targets

  function toggle(e) {
    const detail = e.detail || {}

    notes.forEach(note => {
      note.style.display = note.dataset.value === detail.shortcode ? 'block' : 'none'
    })
  }

  function init() {
    toggle({})
    on(base, SHIPMENT_CHANGE_EVENT, toggle)
  }

  function destroy() {
    off(base, SHIPMENT_CHANGE_EVENT, toggle)
  }

  return {
    init,
    destroy
  }
}
