import { Controller } from '@hotwired/stimulus'
import type { Format, Options } from './types'
import handleMaskDelete from './utils/handle-mask-delete'
import handleMaskInput from './utils/handle-mask-input'
import { setCaretPosition } from '../../frontend/src/common/utils/utils'

export default class extends Controller {
  static targets = ['input', 'mask']

  declare readonly inputTarget: HTMLInputElement
  declare readonly maskTarget: HTMLInputElement

  static values = {
    format: String,
    centDelimiter: { type: String, default: '.' },
    currencySymbol: { type: String, default: 'EUR' },
    decimals: { type: Number, default: 2 }
  }

  declare formatValue: Format
  declare centDelimiterValue: string
  declare currencySymbolValue: string
  declare decimalsValue: number

  connect(): void {
    this.maskInput(this.inputTarget.value)
  }

  options(): Options {
    return {
      format: this.formatValue,
      centDelimiter: this.centDelimiterValue,
      currencySymbol: this.currencySymbolValue,
      decimals: this.decimalsValue
    }
  }

  maskInput(value: string): void {
    handleMaskInput(this.maskTarget, this.inputTarget, value, this.options())
  }

  handleKeyDown(e: KeyboardEvent): void {
    handleMaskDelete(e, this.options(), this.inputTarget)
  }

  handleInput(): void {
    this.maskInput(this.maskTarget.value)
  }

  handleCaretPosition(e: InputEvent): void {
    setCaretPosition(this.maskTarget, this.maskTarget.value.length)
  }

  handleUpdate({ detail: { value } }: { detail: { value: string } }): void {
    this.maskTarget.value = value
    this.maskInput(this.maskTarget.value)
  }
}
