import { Controller } from '@hotwired/stimulus'

/**
 * This is a controller that manages the state of a group of checkboxes and a button.
 * It disables the button if none of the checkboxes are selected, and enables it if at least one checkbox is selected.
 * Here's an example of how to use this controller:
 *
 * ```html
 *<div data-controller="checkbox-state">
 *  <input type="checkbox" data-action="click->checkbox-state#toggleAll">
 *  <input type="checkbox" data-checkbox-state-target="checkbox">
 *  <input type="checkbox" data-checkbox-state-target="checkbox">
 *  <button data-checkbox-state-target="button" disabled>Submit</button>
 *</div>
 * ```
 * In this example, the button is initially disabled because none of the checkboxes are selected. When the user selects at least one checkbox, the button becomes enabled.
 */
export default class extends Controller {
  static targets = ['button', 'checkbox']

  declare buttonTarget: HTMLButtonElement
  declare checkboxTargets: HTMLInputElement[]

  connect(): void {
    this.checkboxTargets.forEach(checkbox => {
      checkbox.addEventListener('change', this.updateTargetState.bind(this))
    })
  }

  disconnect(): void {
    this.checkboxTargets.forEach(checkbox => {
      checkbox.removeEventListener('change', this.updateTargetState.bind(this), false)
    })
  }

  toggleAll(event: Event): void {
    if (event.target == null) {
      return
    }

    for (const checkBox of this.checkboxTargets.filter(checkbox => !checkbox.disabled)) {
      checkBox.checked = (event.target as HTMLInputElement).checked
    }
    this.updateTargetState(event)
  }

  updateTargetState(_event: Event): void {
    const isAnyCheckboxSelected =
      this.checkboxTargets.filter(checkbox => checkbox.checked).length > 0

    this.buttonTarget.disabled = !isAnyCheckboxSelected
  }
}
