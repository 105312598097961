import Menu from './menu/menu'

const SELECTORS = {
  mainMenu: '.navigation',
  mainMenuToggle: '.navigation-toggle',
  topMenu: '.top-menu',
  topMenuToggle: '.top-menu-toggle'
}

let elements = {}
let instances = {}

function _isInitiated() {
  return !!Object.keys(elements).length
}

function _initMenu({ toggle, menu }) {
  const instance = Menu({ targets: { toggle, menu } })
  instance.init()
  return instance
}

function init() {
  if (_isInitiated()) {
    destroy()
  }

  elements.mainMenu = document.querySelector(SELECTORS.mainMenu)
  elements.mainMenuToggle = document.querySelector(SELECTORS.mainMenuToggle)
  elements.topMenu = document.querySelector(SELECTORS.topMenu)
  elements.topMenuToggle = document.querySelector(SELECTORS.topMenuToggle)

  instances.mainMenu = _initMenu({
    toggle: elements.mainMenuToggle,
    menu: elements.mainMenu
  })
  instances.topMenu = _initMenu({
    toggle: elements.topMenuToggle,
    menu: elements.topMenu
  })
}

function destroy() {
  if (!_isInitiated()) {
    return
  }

  instances.mainMenu.destroy()
  instances.topMenu.destroy()
  elements = {}
  instances = {}
}

const Navigation = {
  init,
  destroy
}

export default Navigation
