export default function getUrlSearchParams() {
  if (!window.location.search || window.location.search === '?') {
    return {}
  }

  return window.location.search
    .substring(1)
    .split('&')
    .map(pair => pair.split('='))
    .reduce((acc, [key, value]) => ({ ...acc, [key]: decodeURIComponent(value) }), {})
}
