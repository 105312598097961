import { isObject } from 'lodash'
import isValidJson from './is-valid-json'

export default function parseObject(object) {
  if (isObject(object)) {
    return { ...object }
  } else if (object && isValidJson(object)) {
    return JSON.parse(object)
  } else {
    return null
  }
}
